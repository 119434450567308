@import '../styles/variables';

.currentLanguage {
  padding: 0 0 0 0.25rem;
  display: flex;
  align-items: center;
  align-content: center;
  font-size: 0.875rem;
  margin-left: auto;
  position: relative;

  > span {
    //text-transform: uppercase;
    margin-right: 4px;
  }
  &.warning {
    &:after {
      content: '';
      position: absolute;
      top: -3px;
      right: -3px;
      width: 9px;
      height: 9px;
      //background-color: $danger;
      background-color: red; // src/lib/styles/badge.scss
      display: block;
      border-radius: 100px;
    }
  }
}

.flag-select {
  > button,
  > button + ul {
    border-radius: $border-radius;
    border-color: $gray-400;

    &:focus {
      outline: 0;
      border-color: $gray-300;
    }
  }
}
