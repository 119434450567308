.match-overview-section .below-container-menu {
  padding-top: 20px;
}

/* TODO cleanup */

.match-overview-card {
  margin: 0px 0px 16px 0px;
  border-radius: 7px;
  background: #f2f2f2;
  font-size: 16px;
  overflow: hidden;
  height: 60px;
}

.match-overview-card .match-subinfo-wrapper {
  position: absolute;
  height: 100%;
  width: auto;
  right: 0;
  top: 0;
}

.match-overview-card .match-subinfo {
  width: 54px;
  float: left;
}

.match-overview-card .match-subinfo.icon-subinfo {
  width: 54px;
  float: right;
}

.match-overview-card .match-subinfo-wrapper .match-subinfo:nth-child(1),
.match-overview-card.won .match-subinfo-wrapper .match-subinfo:nth-child(1),
.match-overview-card.draw .match-subinfo-wrapper .match-subinfo:nth-child(1),
.match-overview-card.lost .match-subinfo-wrapper .match-subinfo:nth-child(1) {
  background: none;
}

.match-overview-card .match-subinfo:nth-child(1) {
  background: #424242;
  color: #fff;
}

.match-overview-card.draw .match-subinfo:nth-child(1) {
  background: #424242;
}

.match-overview-card.won .match-subinfo:nth-child(1) {
  background: #0fcf85;
}

.match-overview-card.lost .match-subinfo:nth-child(1) {
  background: #cf0f0f;
}

.match-overview-card .match-subinfo .match-subinfo-inner {
  padding: 0 16px;
}

.match-overview-card .match-subinfo .match-subinfo-number {
  font-size: 20px;
  font-weight: 700;
}

.match-overview-card .match-subinfo .match-subinfo-text {
  font-size: 10px;
  text-transform: uppercase;
}

.match-overview-card .match-info {
  width: calc(100% - 54px - 54px);
  float: left;
}

.match-overview-card .match-info .match-team {
  width: calc(50% - 35px);
  padding: 0 2px 0 0;
}

.match-overview-card .match-info .match-team.right {
  padding: 0 0 0 2px;
}

.match-overview-card .match-info .match-team .match-team-inner {
  float: right;
  width: auto;
  padding: 0 2px 0 16px;
}

.match-overview-card .match-info .match-team.right .match-team-inner {
  padding: 0 16px 0 2px;
  float: left;
}

.match-overview-card .match-info .match-team-inner .match-team-name {
  float: left;
}

.match-overview-card
  .match-info
  .match-team.right
  .match-team-inner
  .match-team-name {
  float: right;
}

.match-overview-card .match-info .match-team-inner .match-team-logo {
  float: left;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: url('../img/fcb.png');
  margin-left: 16px;
  background-size: cover !important;
}

.match-overview-card
  .match-info
  .match-team.right
  .match-team-inner
  .match-team-logo {
  background: url('../img/fdbd.png');
}

.match-overview-card
  .match-info
  .match-team.right
  .match-team-inner
  .match-team-logo {
  float: right;
  margin-left: 0;
  margin-right: 16px;
}

.match-overview-card .match-info .match-numbers {
  float: left;
  width: 70px;
  font-weight: 700;
}

.match-overview-card.live .match-info {
  width: 50%;
}

.match-overview-card-wrapper.expanded .match-overview-card {
  margin: 0 0 0 0;
  border: 1px solid #424242;
}

.match-overview-card-buttons {
  margin: 0 0 24px 0;
  height: 48px;
}

.match-overview-card-buttons .match-overview-card-button {
  position: absolute;
  width: 48px;
  height: 48px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background: #5650a3;
  top: 0;
}

.match-overview-card-buttons .match-overview-card-button::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 12px;
  left: 12px;
}

.match-overview-card-buttons .match-overview-card-button:nth-child(1) {
  left: calc(50% - 88px);
}

.match-overview-card-buttons .match-overview-card-button.lineup::after {
  background: url('../img/icons/soccer_field_white.svg');
}

.match-overview-card-buttons .match-overview-card-button:nth-child(2) {
  left: calc(50% - 24px);
}

.match-overview-card-buttons .match-overview-card-button.observe::after {
  background: url('../img/icons/label_white.svg');
}

.match-overview-card-buttons .match-overview-card-button:nth-child(3) {
  left: calc(50% + 40px);
}

.match-overview-card-buttons .match-overview-card-button.dashboard::after {
  background: url('../img/icons/statistics_white.svg');
}

.match-overview-card-label {
  background: #424242;
  padding: 0 8px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 6px;
  z-index: 2;
  color: #fff;
  text-transform: uppercase;
  border-radius: 7px;
}

.match-overview-card-wrapper.active
  .match-overview-card
  .match-subinfo:nth-child(1) {
  background: #28e89e;
}

.match-overview-card-wrapper.active .match-overview-card {
  border: 1px solid #28e89e;
}

.match-overview-card-wrapper.active .match-overview-card-label {
  background: #28e89e;
}

.match-overview-card .match-subinfo.icon-subinfo::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 19px;
  left: 19px;
  background: url('../img/icons/soccer_field.svg');
}

.match-overview-card.won .match-subinfo.icon-subinfo::after,
.match-overview-card.lost .match-subinfo.icon-subinfo::after,
.match-overview-card.draw .match-subinfo.icon-subinfo::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 19px;
  left: 19px;
  background: url('../img/icons/statistics.svg');
  background-repeat: no-repeat;
}

.match-overview-card .match-subinfo.icon-subinfo.play::after {
  background: url('../img/icons/play.svg');
  background-repeat: no-repeat;
}

.match-overview-card .match-subinfo.icon-subinfo.field::after {
  background: url('../img/icons/soccer_field.svg');
  background-repeat: no-repeat;
  transform: rotate(90deg);
}

.match-overview-card .match-subinfo.icon-subinfo.stats::after {
  background: url('../img/icons/statistics.svg');
  background-repeat: no-repeat;
}

.match-overview-card .match-subinfo.icon-subinfo.upload::after {
  background: url('../img/icons/upload.svg');
  background-repeat: no-repeat;
}

.match-overview-card .match-subinfo.icon-subinfo.processing::after {
  background: url('../img/icons/process.svg');
  background-repeat: no-repeat;
}

.match-overview-card .match-subinfo.icon-subinfo.download::after {
  background: url('../img/icons/download.svg');
  background-repeat: no-repeat;
}

.match-overview-card .match-subinfo.icon-subinfo.observe::after {
  background: url('../img/icons/label.svg');
  background-repeat: no-repeat;
}

.match-overview-card .match-subinfo.icon-subinfo.play::after {
  background: url('../img/icons/play.svg');
  background-repeat: no-repeat;
}

.click-for-more {
  padding: 50px 0;
}

.click-for-more-inner {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.click-for-more-icon {
  background: url('../img/icons/tap.svg');
  width: 24px;
  height: 24px;
  float: left;
  background-size: cover;
}

.click-for-more-text {
  float: left;
}
