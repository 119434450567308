.input-action-button{
  position: relative;

  input[type='text']{
    width:100%;
    padding: 0.5em 0.5em;
    height: auto;
  }
  button{
    position: absolute;
    right:0.25em;
    top: 50%;
    transform: translateY(-50%);
  }
}