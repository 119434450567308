@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables';
@import 'lib/styles/layout.scss';

.chat-list {
  padding-left: 0;
  padding-right: 0;
}

.chat-item {
  width: 100%;
  padding: $spacing-md $spacing-md $spacing-sm;
  border-top: 1px solid $bg-normal;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: $font-size-small;
  position: relative;
  min-height: 34px;

  &.active {
    background-color: $bg-light-active;
  }

  &:first-child {
    border-top: none;
  }

  &-header,
  &-content {
    margin-bottom: $spacing-sm;
  }

  &-header {
    display: flex;
    align-items: center;

    .profile-picture,
    .username {
      margin-right: $spacing-md;
    }
    .profile-picture {
      width: 24px;
      height: 24px;
      border-radius: 24px;
    }
    .username {
      flex-grow: 1;
      font-weight: bold;
    }
    .time-ago-relative {
      color: $font-color-lighter;
    }
  }

  &-actions {
    display: flex;
    justify-content: stretch;
    align-items: center;

    i {
      width: 12px;
      height: 12px;
      margin-right: $spacing-sm;
    }

    .toggle-comments {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &-children {
    padding: $spacing-md 0 0 $spacing-md;
  }

  & &-form {
    padding: $spacing-md 0 $spacing-sm;
    border-top: 1px solid $bg-normal;
  }
}
