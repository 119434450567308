@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables';
@import 'lib/styles/layout.scss';
@import 'lib/Sidebar/index.scss';

$item-height: 20px;
$item-icon-border-radius: $item-height / 2;
$toggle-width: 20px;

.observation-tree {
  @extend .sidebar-list;

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  overflow-y: scroll;
  padding: 18px 6px 18px 12px;

  @include media-breakpoint-down(md) {
    overflow-y: visible;
  }
}

.observation-node {
  width: 100%;
  padding: $spacing-xs 0;
  border-top: 1px solid $bg-normal;

  &-leaf {
    padding: 0;
    padding-top: 6px;
    border-top: none;
  }

  &-level-1 > &-item > &-pre-label > &-badge,
  &-level-2 > &-item > &-pre-label > &-badge {
    background-color: $bg-normal;
  }

  &-icon {
    order: 2;
    margin-left: 0;
    border-radius: $item-icon-border-radius;
    height: $item-height;
    width: $item-height;
    background: url(../../../../img/icons/profile-picture-placeholder.svg);
    background-size: cover;
    flex-shrink: 0;
    margin-right: 6px;

    html:not(.skillreflect) & {
      display: none;
    }

    &-trainer {
      background-image: url(../../../../img/icons/trainer.png);
    }
    &-peer {
      background-image: url(../../../../img/icons/peer.png);
    }
    &-trainee {
      background-image: url(../../../../img/icons/trainee.png);
    }
  }

  &-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-bottom: $spacing-xs;
    font-size: $font-size-small;

    position: relative;
    height: 30px;

    &.current {
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: $border-radius-default;
    }

    .observation-node-leaf & {
      padding: 2px 0;
      margin-bottom: unset;
    }

    & > div:not(:empty) {
      margin-right: $spacing-sm;
    }
  }

  .collapse-icon,
  .i-edit {
    transform: scale(0.6);
    cursor: pointer;
  }

  &-toggle {
    width: $toggle-width;
    flex-shrink: 0;
    order: 1;
    .observation-node-leaf & {
      width: 0;
    }

    .skillreflect & {
      display: none;
    }
  }

  .collapse-icon,
  &-pre-label,
  &-post-label,
  &-text,
  &-time {
    cursor: pointer;
  }

  &-badge {
    display: inline-block;
    padding: 0.1rem 0.5rem;
    border-radius: $border-radius-default;
    background-color: $colors-branding-secondary;
    color: white;
  }

  &-text {
    order: 3;
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &-pre-label {
    order: 5;
    white-space: nowrap;
    span {
      display: block;
      transform: scale(2);
      .rating-positive & {
        color: $green;
      }
      .rating-negative & {
        color: $orange;
      }
      .rating-neutral & {
        color: $gray-800;
      }
      .rating-unknown & {
        color: $gray-600;
      }      
    }

    html:not(.skillreflect) & {
      order: 2;
    }
  }

  &-post-label {
    order: 4;
    .observation-node-badge {
      background-color: #ccc;
      color: black;
      font-weight: bold;
    }
  }

  &-time {
    order: 6;
    color: $font-color-light;
  }


  &-actions {
    order: 7;
  }

  &-children {
    padding-left: calc(#{$toggle-width} + #{$spacing-md});
  }
}
