@import "lib/styles/variables.scss";
@import '~bootstrap/scss/mixins';


.teams-overview{
  position: relative;
  z-index: 0;
}
.sub-team-wrapper {
  padding-left:2rem;

  transform: translate3d(0,0,-10px);
  @include media-breakpoint-up(md){
    padding-right: 2rem;
  }
  cursor: pointer;
  position: relative;

  &:not(.modal-body &){
    z-index: -1;
  }


  &:before{
    content:'';
    position: absolute;
    left:1rem;
    top:10%;
    height:40%;
    width:0.75rem;
    border-left: solid 2px $gray-300;
    border-bottom: solid 2px $gray-300;
  }

  &.is-mergemode{
    padding-left: 4rem;
    &:before{
      left:3rem;
    }
  }

  .overview-item {
    margin-bottom:4px;
  }
  &+div:not(&){
    margin-top: 14px;
  }
}

.parent-team-wrapper{
  margin-bottom: -1.2rem;
  position:relative;

  .overview-item {
    box-shadow: $overview-item-shadow;
  }
}
.original-team-wrapper{
  .overview-item {
    box-shadow: $overview-item-shadow;
  }
}
.modal-body .parent-team-wrapper{
  z-index: 1;
}

.teams-overview-item{
  position: relative;

  .input-checkbox{
    input:not([disabled]) label{
      background-color: white;
    }
    input[disabled]{
      display: none;
    }
    label{
      margin:0;
      &:before{
        margin: 0;
      }
    }
  }

  &.is-selected{
    .overview-item{
      border: solid 1px $colors-branding-secondary;
    }
  }
  @include media-breakpoint-down(sm){
    .overview-item-details{
      .team-shortcode{
        display: none;
      }
    }
  }
}

.edit-players{
  .player-square-inner{
    //padding: 1rem;
    border-radius: $border-radius;
    background-color: white;
  }
  .add-player{
    border: dashed 2px $gray-600;
    cursor:pointer;
    display: flex;
    height: 100%;
    width:100%;
    border-radius: $border-radius;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .drag-icon{
    display: none;
  }
}

.sharing-icon {
  &.in-use {
    filter: contrast(160%);
    //filter: sepia(100%);
    border-radius: 8px;
  }
}

.page-description{
  max-width: 475px;
  padding:2rem 0;
}

.opponent-overview .page-header--sticky.is-pinned{
  .view-filters{
    display: none;
  }
  .btn.btn-secondary{
    display: none;
  }
}