.login-form .icon {
  margin: 9px 8px 9px 0;
}

.input-with-icon {
  width: calc(100% - 34px);
  float: left;
}

.input-with-icon input {
  width: 100%;
}

.with-icon-offset {
  margin-left: 34px;
}

.button.with-icon-offset {
  width: unset;
}

.user-logged-in-thumb {
  display: block;
  width: 100px;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 16px;
}

.invitation-row-details {
  .day-amount {
    color: #B1B1B1;

    &::before {
      content: url('../../../img/icons/email_sent.svg');
      width: 20px;
      height: 20px;
      margin-right: 4px;
      display: inline-block;
      position: relative;
    }
  }
}

//.overview-item-title {
//  .invitation {
//    &::before {
//      content: url('../../../img/icons/email_sent.svg');
//      width: 20px;
//      height: 20px;
//      margin-right: 4px;
//      display: inline-block;
//      position: relative;
//    }
//  }
//}