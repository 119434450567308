@import './bootstrap_variables';

@import 'bootstrap/functions';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';
@import 'bootstrap/utilities';

@import 'bootstrap/reboot';
@import 'bootstrap/grid';
@import 'bootstrap/buttons';
@import 'bootstrap/button-group';
@import 'bootstrap/dropdown';
@import 'bootstrap/forms';
@import 'bootstrap/input-group';
@import 'bootstrap/nav';
@import 'bootstrap/transitions';
//@import 'bootstrap/list-group';
@import 'bootstrap/progress';
@import 'bootstrap/card';
@import 'bootstrap/carousel';
//@import 'bootstrap/tables';

@import '~rc-slider/assets/index.css';

@import './root';

@import './layout';
@import './icons';
@import './badge';
@import './buttons';
@import './label';
@import './forms';
@import './typography';
@import './modal';
@import './aside';
@import './app_header';
@import './card';
@import './progress';
@import './navigation';
@import './third_party';
@import './view-overlay';
@import './marketing';
@import './menu-panel';

.carousel-control {
  &-prev,
  &-next {
    opacity: 1;
    color: $font-color-default;
    text-decoration: none;
    font-size: 36px;
  }
  &-prev {
    justify-content: flex-start;
  }
  &-next {
    justify-content: flex-end;
  }
  &-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23424242' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
  }
  &-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23424242' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
  }
}

.table {
  margin: $spacing-md 0;

  .actions {
    text-align: right;

    .btn + .btn {
      margin-left: $spacing-sm;
    }
  }
}

// HOTFIX

.invoer-title {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}
