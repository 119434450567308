@import '~bootstrap/scss/mixins/breakpoints';
@import 'lib/styles/variables';

.user-list-item {
  height: 58px;
  background: #f2f2f2;
  border-radius: 0 7px 7px 0;
  padding-left: 82px;
  position: relative;
  margin: 16px 0 16px 29px;
  width: calc(100% - 29px);
}

.user-list-thumb {
  border-radius: 50%;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 58px;
  width: 58px;
  background: url('../../../img/icons/profile-picture-placeholder.svg');
  background-size: cover;
  margin-left: -29px;
}

.user-list-item .flex-content {
  padding-left: 53px;
}

.user-list-role,
.user-list-action {
  padding-right: 25px;
  position: relative;
}

.user-list-item.invited .user-list-action {
  color: #b1b1b1;
}

.user-list-roles-actions
  .user-list-roles
  .user-list-role:not(:last-child)::after {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  height: 14px;
  background: #000;
  right: 12px;
  top: 6px;
}

.user-list-item.invited::before {
  content: '';
  display: block;
  width: 58px;
  height: 58px;
  position: absolute;
  right: 0;
  top: 0;
  background: #ff3232;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.user-list-item.invited .user-list-action.with-stripe::after {
  background: #b1b1b1;
}

.user-list-item .user-list-role {
  height: 24px;
  margin: 17px 0 17px 0;
}

.user-list-item .user-list-action.user-list-icon.change {
  background: none;
  width: 58px;
  height: 58px;
  display: block;
}

.user-list-item .user-list-action.user-list-icon.change::after {
  content: '';
  position: absolute;
  background: url('../../../img/icons/change.svg');
  width: 22px;
  height: 22px;
  top: 17px;
  right: 17px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
}

.user-list-item .user-list-action.user-list-icon.delete {
  background: #ff3232;
  width: 58px;
  height: 58px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
}

.user-list-item .user-list-action.user-list-icon.delete::after {
  content: '';
  position: absolute;
  background: url('../../../img/icons/delete_white.svg');
  width: 28px;
  height: 28px;
  top: 15px;
  right: 15px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
}

.centered-content-wrapper {
  position: absolute;
  width: calc(100% - 82px);
  text-align: center;
}

.centered-content {
  margin: 0 auto;
  display: inline-block;
  height: 100%;
  padding: 19px 0;
}

.centered-content .centered-item {
  display: inline;
  position: relative;
}

.centered-item.email-item::before {
  content: url('../../../img/icons/email_sent.svg');
  width: 24px;
  height: 18px;
  display: block;
  float: left;
}

.centered-item {
  margin: 0 8px;
}

.centered-item .day-amount {
  color: #b1b1b1;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 27px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #28e89e;
}

input:focus + .slider {
  box-shadow: 0 0 1px #28e89e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.slider-name {
  padding-left: 10px;
  padding-right: 20px;
}

.new-user-name {
  padding-bottom: 10px;
  padding-top: 20px;
}

.last-row {
  padding-top: 20px;
}

.role-segments {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  @include media-breakpoint-down(sm) {
    justify-content: flex-start;
  }
}

.role-segment {
  margin-left: 8px;
  padding: 8px 16px;
  border-radius: 7px;
  border: 1px solid #424242;
  transition-duration: 0.2s;
}

.role-segment.active {
  background: #424242;
  color: #ffffff;
}

.role-segment:hover {
  background: rgba(150, 150, 150, 0.2);
  transition-duration: 0.2s;
  color: #000;
}
.role-segment.active:hover {
  background: rgba(90, 90, 90, 0.9);
  transition-duration: 0.2s;
  color: #fff;
}

// TODO: maybe we remove all of the above rows...

.user-management-team-overview {
  .overview-item-details {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .jersey {
    margin-left: 0;
    margin-right: 2 * $spacing-default;
    @include media-breakpoint-down(sm) {
      margin-left: 2 * $spacing-default;
      margin-right: 0;
    }
    height: 50%;
  }
}
