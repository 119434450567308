@import 'lib/styles/variables.scss';

.notifications-banner {
  width: 100%;
  padding: $spacing-sm;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsla(204, 88%, 60%, 0.95);
  color: white;

  &-icon,
  .btn {
    margin: 0 $spacing-md;
    flex-basis: 14px;
    flex-shrink: 0;
  }

  .btn, a:link, a:hover, a:active, a:visited {
    padding: 0;
    color: white;
    text-decoration: underline;
    font-weight: bold;
  }
}
