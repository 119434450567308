@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';

.notifications-menu {  
  display: flex;
  padding: 0 $spacing-xl;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
  color: white;

  &-icon {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &-panel {
    @include media-breakpoint-up(md) {
      right: 200px;
      width: 500px; // same as resource group menu
      padding: $spacing-xl ($spacing-lg * 3);
    }

    &-container {
      max-height: 70vh;
      overflow-y: scroll;


      &::-webkit-scrollbar {
        border-radius: 10px;
        width: 6px;
        content: '';
      }

      &::-webkit-scrollbar-thumb {
        background-color: hsla(0, 100%, 100%, 0.25);
      }
    }
  }

  &-title {
    font-weight: bold;
  }

  &-notification {
    padding: $spacing-md 0;

    a {
      font-weight: bold;
    }

    &-date {
      opacity: 0.5;
      text-align: right;
    }
  }
}