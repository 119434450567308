@import '../styles/variables';
@import '~bootstrap/scss/mixins/breakpoints';

$imageHeight: 175px;

.upgrade-popup {
  width: 100%;
}
.upgrade-popup__top {
  overflow: hidden;
  display: block;
  height: $imageHeight;
}

.popup--upgrade-popup .popup-wrapper button.close {
  top: 20px;
  right: 20px;
  i {
    width: 18px;
    height: 18px;
  }
}

.upgrade-popup__bottom {
  padding: 1em 3em;
  @include media-breakpoint-down(sm) {
    padding: 12px;
  }
  ul {
    padding: 0.5em 0 0 20px;
  }
  ul > li {
    list-style: outside
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24' fill='none' stroke='%2328e89e' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M20 6L9 17l-5-5'/%3E%3C/svg%3E");
  }
  .btn-group.btn-group-select {
    background-color: $gray-800;
    border-radius: $border-radius;
    width: 100%;
  }
  .btn-group.btn-group-select .btn {
    color: white;
  }
  .btn-group.btn-group-select .btn.active {
    background-color: $gray-700;
  }
  .pill {
    font-size: 11px;
    color: white;
    background-color: $colors-branding-primary;
    font-weight: bold;
    border-radius: 12px;
    padding: 3px 8px;
    white-space: nowrap;
    margin-left: 6px;
  }
  .select--yearly {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .btn-muted {
    font-size: 13px;
    color: $gray-500;
    border-color: transparent !important;
    flex-shrink: 0;
    margin-top: 0.5rem;
  }
  .upgradebutton {
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: 18px;
    padding: 0.3125em 0.75em;
  }
  .btn.btn-link {
    width: 100%;
    margin-top: 0.5rem;
    text-align: center;
    justify-content: center;
  }
}

.upgrade-popup__actions {
  display: flex;
  width: 100%;
  margin-top: 2em;
  flex-wrap: wrap;
  justify-content: center;
}

.upgrade-popup__graphic {
  display: block;
  width: 100%;
  height: $imageHeight;
  overflow: hidden;
  position: relative;
  pointer-events: none;
  user-select: none;
  h5 {
    position: absolute;
    top: 15px;
    text-align: center;
    max-width: 280px;
    color: white;
    left: 50%;
    font-size: 18px;
    line-height: 1.1;
    width: 100%;
    transform: translateX(-50%);
  }
  img {
    object-fit: cover;
    object-position: bottom center;
    width: 100%;
    height: 100%;
  }
}

.popup--upgrade-popup .popup-wrapper {
  max-width: 450px;
}
.popup--upgrade-popup.is-shown {
  .popup-overlay {
    background-color: rgba(0, 0, 0, 0.75);
  }
}
.popup--upgrade-popup.dark {
  .popup-wrapper .pop-inner {
    background-color: $gray-900;
  }
}
.productbutton {
  width: auto;
  display: flex;
  padding: 0.5em 1em;
  align-items: center;
  align-content: center;
  margin-top: 0.5em;
  position: relative;
  color: $gray-400;
  border-radius: $border-radius;
  border: solid 2px $gray-600;
  transition: all 0.5s ease;
  box-shadow: 4px 0px 10px transparent;

  cursor: pointer;
  &.is-active {
    border-color: $colors-branding-secondary;
    color: white;
  }

  .radio {
    width: 1em;
    height: 1em;
    background-color: $gray-600;
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-shrink: 0;

    margin-right: 1em;

    &:before {
      content: '';
      width: 0.5em;
      height: 0.5em;
      background-color: $gray-500;
      border-radius: 1000px;
    }
  }
  &.is-active .radio {
    background-color: $colors-branding-secondary;
    &:before {
      background-color: $success;
    }
  }
  .muted {
    opacity: 0.75;
    font-size: 75%;
  }

  .price {
    margin-left: auto;
    line-height: 1;
  }
  .mini {
    position: relative;
    font-size: 10px;
    opacity: 0.75;
    line-height: 1;
  }
}

.upgrade-popup__pricing {
  position: relative;
  z-index: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
