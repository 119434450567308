@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';

.growth-ambition {
  &-learning-line-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > .btn {
      margin: 0 0 15px 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  &-create {
    .nav {
      display: flex;
      justify-content: space-between;
      margin-bottom: $spacing-xl;
      font-size: 18px;

      &-item {
        flex-grow: 1;
      }

      &-link {
        color: inherit;
      }
    }

    &.disabled,
    .disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .form-actions {
      margin: $spacing-md 0;
    }

    &-select-period,
    &-confirm {
      margin-top: $spacing-xl;
      margin-bottom: $spacing-xl;
    }

    &-confirm {
      .selected-indicators {
        min-height: 200px; // acts as a placeholder

        .label {
          i {
            cursor: pointer;
          }
        }
      }
    }
  }

  &-show {
    &-goal {
      position: relative;

      .collapse-toggle {
        position: absolute;
        right: 0;
        top: 0;
        text-decoration: none;
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &-add-fragment {
        margin: $spacing-md 0;
        font-weight: bold;

        @include media-breakpoint-down(sm) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      &-number {
        font-weight: bold;
        margin-right: $spacing-md;

        @include media-breakpoint-down(sm) {
          display: block;
          margin-bottom: $spacing-md;
        }
      }
    }

    .progress {
      height: $spacing-md;
      margin-bottom: 0;
      border-radius: 0;
      background-color: $bg-lighter;

      &-bar {
        background-color: $gray-600;
      }
    }

    @include media-breakpoint-down(sm) {
      .panel {
        padding-left: 0;
        padding-right: 0;
        border-width: 0 0 1px 0;
        border-radius: 0;
      }

      .card {
        background-color: transparent;

        &-body {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &-evaluate {
    .card {
      margin-bottom: $spacing-lg;
    }

    .growth-ambition-title {
      display: flex;
      align-items: center;
      margin: $spacing-xl 0 $spacing-lg;
      padding: $spacing-md $spacing-lg;
      background-color: $gray-700;
      color: $font-color-contrasting;
      border-radius: $border-radius-default;
      font-size: $font-size-lg;

      &.primary {
        background-color: $primary;
      }

      .collapse-icon {
        margin-left: auto;
      }
    }

    .indicator-title,
    .indicator-fragment,
    .indicator-player-check,
    .indicator-final-check {
      margin: $spacing-md;
    }
    .indicator-player-check,
    .indicator-final-check {
      display: flex;
      justify-content: space-between;
    }

    .indicator-fragment {
      .btn {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: auto;
        }
      }
    }

    &-profile-icon {
      width: $font-size-lg;
      height: $font-size-lg;
      margin-right: $spacing-md;
    }

    &-learning-line {
      > .card-body {
        padding-top: 0;
        padding-bottom: 0;
      }

      .learning-line {
        &-body {
          display: flex;
        }

        &-evaluation-title,
        &-fragment,
        &-player-check,
        &-final-check {
          margin: $spacing-md;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-fragment,
        &-player-check,
        &-final-check {
          width: 6rem;
          flex-shrink: 0;
          display: none;
          text-align: center;
        }
        &-evaluation-title {
          flex-grow: 1;
          justify-content: flex-start;
          font-weight: bold;
          color: $black;
          font-size: $font-size-lg;
        }

        &-final-check {
          width: 10rem;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &-learning-line {
        background-color: transparent;

        > .card-body {
          padding: 0;
        }

        .learning-line-evaluation-title {
          margin-bottom: $spacing-xl;
        }
      }
    }

    @include media-breakpoint-up(md) {
      &-learning-line {
        .learning-line {
          &-evaluation-title {
            margin-left: 0;
          }
          &-fragment,
          &-player-check,
          &-final-check {
            display: flex;
          }
        }
      }

      &-indicator {
        display: flex;
        flex-direction: row;
        align-items: center;

        label {
          display: none;
        }
      }

      .indicator-title {
        flex-grow: 1;
      }
      .indicator-fragment,
      .indicator-player-check,
      .indicator-final-check {
        width: 6rem;
        flex-shrink: 0;
        text-align: center;
      }
      .indicator-player-check,
      .indicator-final-check {
        display: block;
      }
      .indicator-final-check {
        width: 10rem;
      }
    }
  }
}

.growth-ambition-evaluate-check,
.growth-ambition-evaluate-cross,
.growth-ambition-evaluate-play {
  line-height: 21px;
  padding: 3px 0 0;

  i {
    width: 16px;
    height: 16px;
  }
}
.growth-ambition-evaluate-play {
  margin-left: 6px;
  margin-right: 6px;
}

.wizard-steps,
.wizard-step {
  display: flex;
  align-items: center;
}
.wizard-steps {
  margin-bottom: $spacing-xl;
}
.wizard-step {
  font-size: 1.375rem;
  font-weight: 700;
  color: $font-color-lighter;

  & + & {
    margin-left: $spacing-xl;
  }

  &.active {
    color: $font-color-default;
  }
}
