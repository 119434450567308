@import '../styles/variables';

.live-recording__testplayer {
  &::-webkit-media-controls {
    display: none;
  }
}

.live-recording__testwrapper {
  position: relative;
  padding-bottom: percentage(9/16);
  video {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.layout--liverecord .video-player-container {
  max-height: 95vh;
  height: 100%;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.live-recording__wrapper {
  background-color: black;
  width: 100%;
  height: auto;
  display: flex;
  flex: 1 0 50%;
  position: relative;
}
.live-recording__videowrapper {
  display: block;
  width: 100%;
  height: 100%;

  video {
    height: auto;
    width: auto;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.layout--liverecord .button-wrapper {
  flex: 0 1 auto;

  margin-bottom: auto;
  & + div {
    display: none;
  }
}

.btn-finish-recording {
  margin-left: auto;
  margin-top: 12px;
  position: absolute;
  bottom: 12px;
  right: 12px;
}
.btn-start-test {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.btn-start-recording {
  &.disabled {
    filter: grayscale(100%);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.btn-finish-recording {
  .rec-orb {
    animation: blink 2s infinite;
  }
}

.rec-orb {
  float: right;
  width: 1em;
  height: 1em;
  display: inline-block;
  background-color: red;
  border-radius: 40px;
  margin-left: 0.5em;
}

.soundmeter__wrapper {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  height: 175px;

  @media screen and (max-width: 450px) {
    height: 120px;
  }

  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.soundmeter__meter {
  position: relative;
  width: 100%;
  height: 100%;
  border: solid 1px white;
  margin-bottom: 0.5rem;
}
.soundmeter__value {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-size: 100% auto;

  border-top: solid 1px white;
  transition: max-height 0.2s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 150px;
    background-image: linear-gradient(
      180deg,
      hsl(0deg, 100%, 47%) 0%,
      hsl(20deg, 100%, 46%) 11%,
      hsl(30deg, 100%, 45%) 22%,
      hsl(39deg, 100%, 43%) 33%,
      hsl(48deg, 100%, 41%) 44%,
      hsl(56deg, 62%, 48%) 56%,
      hsl(68deg, 55%, 57%) 67%,
      hsl(84deg, 62%, 67%) 78%,
      hsl(104deg, 74%, 78%) 89%,
      hsl(130deg, 100%, 85%) 100%
    );
    display: block;
    width: 100%;
  }
}

.soundmeter__source {
  position: absolute;
  right: 100%;
  bottom: 0;
  font-size: 11px;
  color: white;
  white-space: nowrap;
  text-shadow: 0 0 2px black;
}

.live-recording-error {
  animation: Error 0.5s forwards;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  padding: 7px;
  border-radius: $border-radius;
  border: solid 1px $warning;
  margin-bottom: 7px;
  background-color: transparentize($warning, 0.95);
}

@keyframes Error {
  0% {
    background-color: $warning;
  }
  100% {
    background-color: transparentize($warning, 0.95);
  }
}
