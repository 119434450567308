

.sponsorstill {
  padding-top: 10%;
  padding-bottom: 25%;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  width:100%;
  height:100%;

}

.sponsorstill h1 {
  font-size: 3em;
  text-align: center;
  color: red;
  position: relative;
}
.sponsor-row {
  display: flex;
  position: relative;
  padding: 0 5%;
  margin-top: 1em;
  justify-content: center;

}
.sponsor-row img {
  max-width: 225px;
  width: auto;
  padding: 12px;
}
.sponsor-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  background-size: cover;
}
