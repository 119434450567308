html:root {
  --danger-color: #cf0f0f;
  --danger-color-h: 0;
  --danger-color-s: 86%;
  --danger-color-l: 44%;
  --warning-color: #d0980f;
  --warning-color-h: 43;
  --warning-color-s: 87%;
  --warning-color-l: 44%;
  --success-color: #0fcf85;
  --success-color-h: 157;
  --success-color-s: 86%;
  --success-color-l: 44%;
  --primary-color: #28e89e;
  --primary-color-h: 157;
  --primary-color-s: 81%;
  --primary-color-l: 53%;
  --secondary-color: #5650a3;
  --secondary-color-h: 244;
  --secondary-color-s: 34%;
  --secondary-color-l: 48%;
}

html.skillreflect:root {
  --danger-color: #cf0f0f;
  --danger-color-h: 0;
  --danger-color-s: 86%;
  --danger-color-l: 44%;
  --warning-color: #d0980f;
  --warning-color-h: 43;
  --warning-color-s: 87%;
  --warning-color-l: 44%;
  --success-color: #0fcf85;
  --success-color-h: 157;
  --success-color-s: 86%;
  --success-color-l: 44%;
  --primary-color: #ffa12e;
  --primary-color-h: 33;
  --primary-color-s: 100%;
  --primary-color-l: 59%;
  --secondary-color: #ff401c;
  --secondary-color-h: 9;
  --secondary-color-s: 100%;
  --secondary-color-l: 55%;
}
