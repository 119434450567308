@import './bootstrap_variables';

$primary: var(--primary-color);
$secondary: $teal;
$info: $blue;
$warning: $orange;

// Application variables

$colors-branding-primary: var(--secondary-color);
$colors-branding-secondary: var(--primary-color);

$bg-lighter: #fff;
$bg-light-active: darken($bg-lighter, 2%);
$bg-light: darken($bg-lighter, 5%);
$bg-normal: darken($bg-lighter, 20%);
$bg-dark: $gray-700;
$bg-danger: #ff3232;
$bg-success: hsl(157, 68%, 44%);
$bg-active: $colors-branding-secondary;
$border-radius-default: 7px;

$spacing-default: 12px;
$spacing-md: $spacing-default;
$spacing-sm: $spacing-default / 2;
$spacing-xs: $spacing-default / 4;
$spacing-lg: $spacing-default * 1.5;
$spacing-xl: $spacing-default * 3;

$content-margin: 5%;

$font-size-small: 12px;
$font-size-lg: 18px;
$font-color-default: hsl(0, 0%, 26%);
$font-color-light: lighten($font-color-default, 20%);
$font-color-lighter: lighten($font-color-default, 40%);
$font-color-contrasting: white;
$vertical-align: baseline;

$icon-size: 22px;
$logo-size: 30px;

$innervw: var(--innerVW);
$innervh: var(--innerVH);

$overview-item-shadow: 0px 0.2px 3.1px rgb(0 0 0 / 7%),
  0px 0.5px 7px rgb(0 0 0 / 6%), 0px 0.8px 12.2px rgb(0 0 0 / 6%),
  0px 1.3px 19.4px rgb(0 0 0 / 5%), 0px 1.9px 29.9px rgb(0 0 0 / 4%),
  0px 3px 46.7px rgb(0 0 0 / 4%), 0px 5px 77.6px rgb(0 0 0 / 3%),
  0px 10px 155px rgb(0 0 0 / 2%);

@mixin getAspectRatioForColumn($x, $y, $column, $totalColumns) {
  // Get Aspect ration for column content.
  // eg. video in first col-7, tags in second col-5.
  // | col-7 = 16/9 | col-5 = x/9 |
  $total: 100 * $x/ ((($totalColumns - $column) / $totalColumns));
  $output: ($total * $column/$totalColumns)/100;
  aspect-ratio: $output/$y;
}
