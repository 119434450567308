@import './overview-item';
@import 'lib/styles/variables';
@import 'lib/styles/layout';
@import 'lib/styles/animations';

.observation-input-selection {
  display: flex;
  overflow-x: auto;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  &-item {
    display: block;
    flex: 1 0 auto;
    height: 210px;
    margin: 0 0 0 $spacing-lg;
    border: 0;
    background-color: hsla(0, 0%, 0%, 0.5);
    color: white;
    border-radius: $border-radius-default;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 0 0 $spacing-md;

      &:first-child {
        margin: 0 0 $spacing-md !important;
      }
    }

    &:first-child {
      margin: 0;
    }
  }
}

.observation-input-selection--sr {
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .observation-input-selection-item {
    display: inline-block;
    flex: 1 0 auto;
    height: auto;
    margin: 0 0 0 $spacing-lg;
    border: 0;
    background-color: hsla(255, 255%, 255%, 0.5);
    color: $font-color-default;
    border-radius: $border-radius-default;
    border-bottom: solid 2px rgba(200, 200, 200, 0.5);
    margin: 6px 0;
    padding: 8px 12px;

    text-align: left;

    &:hover {
      color: $primary;
    }
  }
}

.addmatch-scheduling {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: $border-radius - 2;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;

  .scheduling-icon {
    width: 40px;
    transition: opacity 0.2s ease-in-out;
    opacity: 0.75;
  }
  &.active .scheduling-icon {
    opacity: 1;
  }
}

.scheduling-helptext {
  animation: fadeIn 0.5s forwards;
  font-size: 14px;
}

.schedule-row {
  display: block;
  min-height: 67px;
  padding: 10px 12px;
  position: relative;
  border-radius: $border-radius;
  background-color: #fff;
  border: solid 1px #ccc;

  .form-check-label {
    cursor: pointer;
    //font-weight: bold;
  }
  .form-check {
    padding-right: 40px;
    @include media-breakpoint-down(sm) {
      padding-left: 25px;
      .form-check-input {
        left: 20px;
      }
    }
  }
  &.disabled {
    background-color: #f2f2f2;
    //color: $gray-300;
    color: #6c757d;
    border-color: $gray-300;
  }
}

/* layout */

//.sportingEvent-overview {
//  background-color: white;
//  .page-header-content {
//    padding-left: calc(50vw - 570px);
//    padding-right: calc(50vw - 570px);
//
//    margin-top: 0;
//    padding-top: 12px;
//    padding-bottom: 12px;
//
//    @media screen and (max-width: 1280px) {
//      padding: 12px 5%;
//    }
//  }
//}
.sportingEvent-overview__category {
  //max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2em;

  position: relative;

  & + & {
    padding: 2em 0;
    border-top: dotted 4px #efefef;
  }

  :not(.overview-item--islocked) .overview-item {
    box-shadow: $overview-item-shadow;
  }
}

.sportingEvent-overview__wrapper {
  position: relative;
}

.overview--loading {
  position: fixed;
  right: 5%;
  bottom: 2px;
  z-index: 10;
  transform: translate(0, -100%);
}
.overview__loader {
  display: flex;
  justify-content: center;
}

.import-observations-list {
  height: 200px;
  min-height: 200px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 12px 0;
  @include media-breakpoint-up(lg) {
    height: auto;
    @include getAspectRatioForColumn(16, 9, 5, 12);
  }
}

html.livestreaming {
  .app-header {
    display: none !important;
  }
  body {
    background-color: rgba(0, 0, 0, 0) !important;
    margin: 0px auto;
    overflow: hidden;
  }

  #app {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .page--homepage {
    background-color: rgba(0, 0, 0, 0);
  }
}
