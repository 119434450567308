.upload-icon {
  width: 50px;
  height:50px;
  margin-bottom: 12px;
}
.upload-texts {
  margin-top: 20px;
  font-size: 13px;
  padding-bottom: 2rem;
}
.upload-icon::before {
  display: block;
  position: relative;
}
.upload-match-overview-wrapper {
  margin-top: 60px;
}
.upload-match-overview-wrapper .match-overview-card {
  background: #fff !important;
}
.upload-match-overview-card {
  margin-bottom: 0px !important;
}
.upload-instruction-wrapper {
  width: 100%;
  position: absolute;
}

.upload-instruction {
  display: inline-block;
}
.upload-field {
  border: 2px dashed #424242;
  border-radius: 7px;
  min-height: 140px;
}
.upload-instruction::before {
  position: relative;
  content: url('../img/icons/tap.svg');
  width: 24px;
  height: 24px;
  display: block;
  float: left;
  margin-right: 12px;
}

.uploaded-files .uploaded-file {
  z-index: 2;
  height: 140px;
  width: 140px;
  border: 2px dashed #979797;
  border-radius: 7px;
  float: left;
  background: #f2f2f2;
  margin: -3px -3px -3px 0;
  box-shadow: 5px 5px 10px rgba(116, 86, 86, 0.2);
}
.uploaded-files .uploaded-file:first-child {
  margin-left: -3px;
}


.upload-text {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1;
  white-space: nowrap;
}
.uploaded-file .action-icon {
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 10px;
}
.uploaded-file .action-icon.drag::after {
  content: url('../img/icons/drag.svg');
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  left: 0;
}
.uploaded-file .action-icon.delete::after {
  content: url('../img/icons/delete_white.svg');
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
}
.uploaded-file .action-icon.delete {
  background: #ff3232;
  border-radius: 7px;
  right: 0;
  z-index: 2;
}

.upload-progress {
  border-radius: 7px;
  background: #fff;
  height: 24px;
  position: relative;
  width: 100%;
  margin-top: 20px;
}
.upload-progress-inner {
  border-radius: 7px;
  position: absolute;
  width: 30%;
  height: 100%;
  background: var(--secondary-color);
}

.video-process-progress {
  border-radius: 7px;
  background: #fff;
  height: 24px;
  position: relative;
  width: 100%;
  margin-top: 20px;
}
.video-process-progress-inner {
  border-radius: 7px;
  position: absolute;
  width: 30%;
  height: 100%;
  background: var(--secondary-color);
}

.progress-icon {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}
.progress-icon#pause {
  background: url('../img/icons/pause.svg');
}
.progress-icon#stop {
  background: url('../img/icons/stop.svg');
}
.progress-icon#resume {
  background: url('../img/icons/play.svg');
}
.float-right .progress-text {
  margin-left: 10px;
}
.warning-icon {
  width: 18px;
  height: 18px;
  background: url('../img/icons/warning.svg');
  position: relative;
  margin-right: 10px;
}
.upload-warning-inner {
  display: inline-block;
  line-height: 18px;
}
