/* Alle keuzes in dit custom.css document zijn gemaakt aan de hand van de Sketch file, zoals de font-size, dikte, kleuren, etc */
/*
GENERAL:
Paars: #5650A3
Groen: #28E89E
Grijs: #F2F2F2
H1: 22px, 400
H2: 16px, 400
p: 16px, 300
*/

body,
html {
  font-size: 16px;
  font-weight: 400;
  color: #424242;
}

/* GENERAL */
.grey {
  color: #424242 !important;
}

.white {
  color: #fff !important;
}

.white-background {
  background-color: #fff !important;
}

h1 {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
}

.button {
  border: none;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input,
.button {
  padding: 12px 16px;
  border-radius: 7px;
  background: #f2f2f2;
  font-size: 16px;
  font-weight: 400;
}

input::placeholder {
  color: #828282;
}

input[type='text'] {
  border: 1px solid transparent;
}
input[type='text']:focus {
  border: 1px solid #e1e1e1;
}

.button {
  padding: 9px 16px;
  background: var(--primary-color);
  color: #fff;
  display: inline-block;
  transition-duration: 0.2s;
  -webkit-appearance: none;
}

.button.extramargin,
button.extramargin {
  margin-top: 20px;
}

.button:hover {
  cursor: pointer;
  background: #11ca83;
  transition-duration: 0.2s;
}

.button.nostyling,
button.nostyling {
  background: none;
  color: #424242;
  padding-left: 0;
}

button:disabled,
button[disabled] {
  background: #badace;
  color: #fff;
  pointer-events: none;
}

a:hover {
  text-decoration: underline;
}

/* LOGIN FORMULIER */

.login-section {
  width: 100%;
  height: 100%;
  position: relative;
  background: var(--secondary-color);
}

.login-section .row {
  margin-bottom: 20px;
}

.login-section::after {
  content: '';
  display: block;
  position: absolute;
  background-color: #fff;
  opacity: 0.2;
  -webkit-clip-path: polygon(49% 0, 94% 0, 28% 100%, 0 100%, 0 14%);
  clip-path: polygon(49% 0, 94% 0, 28% 100%, 0 100%, 0 14%);
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0;
}

.login-section input[type='password'] {
  border: 1px solid #e1e1e1;
  color: #4a4a4a;
  background-color: #f2f2f2;
}

.login-form {
  background: #fff;
  padding: 80px 50px;
  border-radius: 7px;
  box-shadow: 5px 5px 10px rgba(116, 86, 86, 0.2);
}

.login-form::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 7px;
  background: var(--primary-color);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  top: 0;
  left: 0;
}

.login-form .icon {
  width: 24px;
  height: 24px;
  float: left;
  margin: 8px 8px 8px 0;
}

.login-form .icon.user-icon {
  content: url('../img/icons/user.svg');
}

.login-form .icon.key-icon {
  content: url('../img/icons/key.svg');
}

.with-icon {
  width: calc(100% - 34px);
}

.with-icon-offset {
  margin-left: 34px;
  width: calc(100% - 34px);
}

.branding.logo img {
  width: 60%;
}

/* ICONEN */
.icon {
}

/* NAVBAR */
/* .top-navbar {
  background: #5650a3;
  color: #fff;
  width: 100%;
  position: relative;
  height: 140px;
  z-index: 1;
}

.user-navbar {
  position: absolute;
  background: #424242;
  overflow: hidden;
  padding: 12px;
  color: #fff;
  width: 100%;
  top: 44px;
  z-index: 2000;
  /*right: calc(10%);*/
/* border-radius: 7px;
}

.user-navbar .nav-child {
  white-space: nowrap;
  line-height: 1;
  padding-left: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-navbar li {
  float: none;
  padding: 8px;
}

.sub-navbar {
  background: #424242;
  overflow: hidden;
  padding: 20px 0;
  color: #fff;
  width: 100%;
  position: relative;
}

.main-logo img {
  height: 24px;
} */

ul > li {
  list-style-type: none;
}

nav ul {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin: 0 -10px;
}

nav ul li {
  float: left;
  padding: 2px 10px;
  color: #fff;
}

nav ul li a.nav-link {
  padding: 0;
}

nav ul li a.nav-link.disabled {
  pointer-events: none;
  opacity: 0.5;
  color: #fff;
}

nav ul li a.nav-link.active {
  font-weight: 700;
}

nav ul li.active {
  font-weight: 700;
}

nav ul li.disabled,
.container-menu nav ul li.disabled {
  color: #828282;
}

.container.below-container-menu {
  height: calc(100% - 70px);
}
.container-menu {
  height: 70px;
}
.container-menu nav ul li {
  color: #000;
}

#user-thumb {
  padding-left: 20px;
}

#user-thumb::after {
  display: block;
  content: '';
  width: 18px;
  height: 18px;
  background-image: url('../img/icons/chevron_down.svg');
  float: right;
  margin: 3px 0 3px 10px;
}

#user-thumb.toggled::after {
  transform: rotate(180deg);
}

#user-thumb img {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

#mobile-nav {
  /*display: block;*/
  width: 18px;
  height: 18px;
  background-image: url('../img/icons/menu.svg');
  float: right;
  margin: 3px 0 3px 10px;
}

.main {
  height: auto;
}

/* INVOER SECTIE */
.invoer-wrapper {
  display: -ms-flexbox;
  display: flex;
  flex-flow: column;
  -webkit-flex-flow: column;
  height: 100%;
}

.invoer-title {
  background: #424242;
  padding: 0px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: 70px;
  flex-shrink: 0;
}

.invoer-title::before {
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 3px;
  background: #fff;
  left: calc(50% - 5px);
  top: 36px;
}

.team-title {
  width: 50%;
  font-size: 22px;
  font-weight: 700;
}

.team-title .team-name {
  margin: 0 10px;
}

.team-title .team-thumb {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin: 0 10px;
}

.team-title .team-score {
  margin: 0 10px;
}

.team-title .team-score.float-right {
  margin-right: 20px;
}

.team-title .team-score.float-left {
  margin-left: 20px;
}

.invoer-time {
  position: absolute;
  padding: 6px;
  color: #fff;
  width: 80px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  bottom: -32px;
  left: calc(50% - 40px);
  background: #424242;
  z-index: 1;
}

.field-image {
  vertical-align: top;
}

.invoer-veld {
}

.invoer-veld::before {
  display: block;
  content: '';
  position: absolute;
  left: -6px;
  top: 0;
  height: 100%;
  width: 6px;
  background: #0fcf85;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.invoer-veld::after {
  display: block;
  content: '';
  position: absolute;
  right: -6px;
  top: 0;
  height: 100%;
  width: 6px;
  background: #cf0f0f;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.6);
}

.invoer-veld .invoer-overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  pointer-events: none;
}

.invoer-veld .invoer-overlay.left {
  top: 0;
  left: 0;
}

.invoer-veld .invoer-overlay.right {
  top: 0;
  right: 0;
}

.invoer-overlay-text {
  position: absolute;
  left: 50%;
}

.invoer-overlay-text::after,
.pre-game-overlay-text::after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('../img/icons/tap.svg');
  position: absolute;
  left: -32px;
  top: -2px;
}

.invoer-overlay.left .invoer-overlay-text,
.pre-game-overlay-half.left .pre-game-overlay-text {
  position: absolute;
  left: 50%;
}

.invoer-overlay.right .invoer-overlay-text,
.pre-game-overlay-half.right .pre-game-overlay-text {
  position: absolute;
  left: 60px;
}

.pre-game-overlay-text,
.invoer-overlay-text {
  display: inline-block;
  position: relative;
}

.pre-game-overlay-bottom {
  position: absolute;
  bottom: 120px;
  width: 100%;
  text-align: center;
}

.pre-game-overlay {
  z-index: 50;
  position: absolute;
  pointer-events: none;
}

.pre-game-overlay .pre-game-overlay-half {
  width: 50%;
  height: 100%;
  float: left;
  padding: 12px;
}

.pre-game-overlay::before {
  display: block;
  content: '';
  height: calc(75% - 80px - 40px);
  position: absolute;
  left: calc(50% - 1px);
  top: 40px;
  border-left: 2px dashed #000;
}

.pre-game-overlay::after {
  display: block;
  content: '';
  height: calc(25% - 40px);
  position: absolute;
  left: calc(50% - 1px);
  bottom: 10px;
  border-left: 2px dashed #000;
}

.invoer-buttons {
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
}

.invoer-button {
  width: calc(20% - 16px);
  margin-left: 8px;
  background: #fff;
  position: absolute;
  height: 100%;
}

.invoer-button-inner {
  width: 100%;
  padding: 12px;
  background: rgba(238, 238, 238, 0.57);
}

.invoer-button:nth-child(1) {
  left: 0;
}

.invoer-button:nth-child(2) {
  left: 20%;
}

.invoer-button:nth-child(3) {
  left: 40%;
}

.invoer-button:nth-child(4) {
  left: 60%;
}

.invoer-button:nth-child(5) {
  left: 80%;
}

.invoer-button-text {
  line-height: 1;
  width: 100%;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 8px 0;
}

.invoer-button.disabled {
  opacity: 0.3;
}

.invoer-button.active {
  background: #424242;
  color: #fff;
}

.invoer-button-icon img {
  height: 33px;
}

ul.invoer-select {
  width: 100%;
  padding: 0;
  margin-bottom: 0;
}

ul.invoer-select li::after {
  display: block;
  content: '';
  clear: both;
}

ul.invoer-select li {
  padding: 8px 0;
}

ul.invoer-select li .select-thumb {
  width: 28px;
  height: 28px;
  padding: 4px;
  text-align: center;
  margin: -2px 6px 0 0;
  font-weight: 400;
  text-transform: uppercase;
  float: left;
  position: relative;
  font-size: 14px;
  background-size: cover;
  border-radius: 50%;
}

ul.invoer-select li .select-thumb::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #d8d8d8;
  z-index: -1;
}
ul.invoer-select li .select-thumb{
  cursor: pointer;
}

ul.invoer-select li .select-thumb.has-player-img::before {
  z-index: 0;
  background-size: cover !important;
  background: red;
}

ul.invoer-select#shot-select li .select-thumb::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  z-index: 0;
  background: url('../img/icons/ball.svg');
}

ul.invoer-select#chance-select li:nth-child(1) .select-thumb::before {
  background: #cf0f0f;
}

ul.invoer-select#chance-select li:nth-child(2) .select-thumb::before {
  background: #cf990f;
}

ul.invoer-select#chance-select li:nth-child(3) .select-thumb::before {
  background: #0fcf85;
}

.select-thumb#goal-home::before,
.select-thumb#goal-away::before {
  background: url('../img/icons/ball.svg');
}
.select-thumb#toggle-play-direction::before {
  background: url('../img/icons/flip.svg');
}
.select-thumb#fault-see::before {
  background: url('../img/icons/eye.svg');
}
.select-thumb#yellow-card::before {
  background: url('../img/icons/yellow.svg');
}
.select-thumb#red-card::before {
  background: url('../img/icons/red.svg');
}
.select-thumb#throw-punish::before,
.select-thumb#throw-free::before,
.select-thumb#custom-tag-1::before,
.select-thumb#custom-tag-2::before {
  background: url('../img/icons/referee.svg');
}

.select-shirt{
  float: left;
  width:26px;
  font-size: 11px;
  height:24px;
  background-image: url("data:image/svg+xml,%3Csvg width='79' height='75' viewBox='0 0 79 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M29.3658 0C26.363 0.791556 14.103 5.50575 12.1532 7.23118C11.6053 7.71667 3.20632 27.6944 0.00702386 32.094C-0.368799 32.6936 14.4505 38.2365 17.2034 39.3169V75H61.7966V39.3169C64.5494 38.2366 79.3689 32.6936 78.993 32.094C75.7938 27.6937 67.3953 7.716 66.8467 7.23118C64.896 5.50575 52.6363 0.791529 49.6342 0C47.433 8.30155 31.566 8.29686 29.3669 0L29.3658 0Z' fill='%2330373E'/%3E%3C/svg%3E%0A");
  background-size: cover;
  color: #fff;
  margin-right: 6px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -1px;
  align-content: center;

  cursor: pointer;
}

ul.invoer-select li .select-name {
  max-width: calc(100% - 40px);
  padding-right: 40px;
  float: left;
  position: relative;
}

ul.invoer-select li.selected .select-name::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background: url('../img/icons/check.svg');
}

.turn-center {
  width: 50%;
  margin: auto;
}

.turn-center::before {
  content: '';
  display: block;
  position: absolute;
  margin-left: -44px;
  height: 40px;
  width: 40px;
  background: url('../img/icons/rotate.svg');
  -webkit-animation: rotate 2s infinite; /* Safari 4+ */
  -moz-animation: rotate 2s infinite; /* Fx 5+ */
  -o-animation: rotate 2s infinite; /* Opera 12+ */
  animation: rotate 2s infinite; /* IE 10+, Fx 29+ */
}

.toast {
  background: #28e89e;
  height: 70px;
  width: 100vw;
  z-index: 2000;
  top: 0;
  position: fixed;
}

.toast.failed {
    background: #ff0000;
}

.toast-text {
  display: inline-block;
  margin: auto;
  padding-left: 40px;
  color: #fff;
}

.toast-text::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: url(../img/icons/check_white.svg);
}

.toast.failed .toast-text::before {
 background: url(../img/icons/warning_white.svg)
}

.network-toast {
  position: fixed;
  background: #f5a623;
  height: 70px;
  width: 100vw;
  z-index: 4;
  top: 0;
}

.network-toast.failed {
  background: #ff0000;
}

.network-toast-text {
  display: inline-block;
  margin: auto;
  padding-left: 40px;
  color: #fff;
}

.network-toast-text::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  background: url(../img/icons/warning_white.svg);
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@-moz-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@-o-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
  .main {
    /*height: calc(100% - 70px); !* Totale hoogte - navbar *!*/
    height: calc(100% - 70px);
    /*height: 100%;*/
  }

  .top-navbar,
  .player-menu {
    height: 70px;
  }
  .invoer-title {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .toast {
    height: 70px;
  }
}

@media screen and (max-width: 950px) {
  .main {
    height: 100%; /* Totale hoogte - navbar maar de navbar is hier hidden */
  }
  .invoer-title {
    background: none;
    position: absolute;
    width: 100%;
  }

  .invoer-time {
    bottom: -16px;
    background: none;
  }
}

@media screen and (max-width: 812px) {
  .invoer-section .full-lg {
    width: 86%;
  }

  .invoer-button-text {
    display: none;
  }

  .invoer-button-inner {
    background: none;
  }
}

@media screen and (max-width: 767px) {
  .login-section::after {
    -webkit-clip-path: polygon(
      50% 0%,
      100% 0,
      100% 21%,
      23% 100%,
      0 100%,
      0 23%
    );
    clip-path: polygon(50% 0%, 100% 0, 100% 21%, 23% 100%, 0 100%, 0 23%);
  }

  .invoer-section .full-lg {
    width: 86%;
  }

  .invoer-button-text {
    display: none;
  }

  .invoer-button-inner {
    background: none;
  }
}

@media screen and (max-width: 570px) {
  .login-section::after {
    -webkit-clip-path: polygon(
      50% 0%,
      100% 0,
      100% 69%,
      23% 100%,
      0 100%,
      0 20%
    );
    clip-path: polygon(50% 0%, 100% 0, 100% 69%, 23% 100%, 0 100%, 0 20%);
  }
}

@media screen and (max-width: 570px) and (orientation: portrait) {
  .hidden-xs-portrait {
    display: none;
  }
}

@media screen and (orientation: portrait) {
  .landscape-show {
    display: none !important;
  }
  /*.portrait-show {*/
  /*display: block !important;*/
  /*}*/
}

@media screen and (orientation: landscape) {
  /*.landscape-show {*/
  /*display: block;*/
  /*}*/
  .portrait-show {
    display: none !important;
  }
}

.field-leader .player-thumb {
  width: 28px;
  height: 28px;
  padding: 3px;
  text-align: center;
  margin: -2px 15px 0 0;
  font-weight: 400;
  text-transform: uppercase;
  float: left;
  position: relative;
  font-size: 14px;
  background-size: cover;
  border-radius: 50%;
}

.field-leader .player-thumb::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #d8d8d8;
  z-index: -1;
}

.field-leader .player-thumb {
  color: #000;
}

.field-leader {
  position: absolute;
  bottom: 12px;

  color: #fff;
  z-index: 1;
}

.field-leader.right {
  left: calc(50% + 17px);
}

.field-leader.left {
  right: calc(50% + 17px);
}

.field-leader .player-name {
  float: left;
}

.invoer-section,
.player-section {
  height: 100%;
}

.margin-scrollable {
  margin-top: 70px;
}

.clickable:hover {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .margin-scrollable {
    margin-top: 0;
  }
}

@media screen and (max-width: 950px) {
  .margin-scrollable {
    margin-top: 35px;
  }
}

@media screen and (max-width: 812px) {
  .team-title,
  .team-score h1,
  .team-name {
    font-size: 18px;
  }
  .margin-scrollable {
    margin-top: 7vw;
  }
  .pre-game-overlay-bottom {
    bottom: 60px;
  }
  .pre-game-overlay::before {
    display: block;
    content: '';
    height: calc(75% - 80px - 40px);
    position: absolute;
    left: calc(50% - 1px);
    top: 90px;
    border-left: 2px dashed #000;
  }

  .pre-game-overlay::after {
    display: block;
    content: '';
    height: calc(25% - 40px);
    position: absolute;
    left: calc(50% - 1px);
    bottom: 10px;
    border-left: 2px dashed #000;
  }
}

@media screen and (max-width: 767px) {
  .margin-scrollable {
    margin-top: 7vw;
  }
}

@import './dashboard.css';
@import './match.css';
@import './upload.css';
@import './components.css';
@import './loader.css';
@import './video.css';
@import './user-settings.css';