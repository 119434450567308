@import './variables';

.view-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 101;
  overflow-y: auto;
  background-color: $bg-light;
}
