@import 'lib/styles/variables.scss';


.exercise-overview {
  .exercise {
    &:hover {
      background-color: lighten($info, 40%) !important;
      cursor: pointer;
    }
  }
}
