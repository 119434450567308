@import '~bootstrap/scss/mixins';
@import './layout.scss';
@import 'lib/styles/variables.scss';

.play-match-main {
  @include vh100-media-up;

  &.video-player-container {
    @include media-breakpoint-down(md) {
      margin-left: 0;
      width: auto;
    }
  }
}

.video-scoreboard {
  align-self: center;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: $spacing-default 0;
  background-color: hsl(0, 0%, 34%) !important;
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 22px;
  vertical-align: middle;

  @media (max-width: 767px) {
    font-size: 14px;
  }

  &-team {
    display: flex;

    > * {
      margin: 0 $spacing-sm;
    }

    &-home {
      justify-content: flex-end;
      margin: 0 ($spacing-default * 4) 0 ($spacing-default * 2);

      @media (max-width: 640px) {
        margin: 0 $spacing-default 0 ($spacing-default / 2);
      }
    }

    &-away {
      flex-direction: row-reverse;
      margin: 0 ($spacing-default * 2) 0 ($spacing-default * 4);

      @media (max-width: 640px) {
        margin: 0 ($spacing-default / 2) 0 $spacing-default;
      }
    }

    &-icon {
      display: inline-block;
      width: 25px;
      height: 25px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: 50%;
    }

    &-score {
      flex-grow: 1;
    }
  }
}

#video-player-wrapper {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  min-height: 210px;
  position: relative;
  background-color: #000;

  /* Comment next line to make sure video player + content below player take 100vh */
  @include media-breakpoint-down(xs) {
    padding-top: 56.65%;
  }

  > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    > div.jwplayer {
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.video-not-found{
  position: absolute;
  left:5%;
  top:5%;
 width:50%;
  max-width: 360px;
}

.multicamToggle {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;

  img {
    pointer-events: none;
  }

  &.active img {
  }
}

.video-player-container {
  display: flex;
  flex-direction: column;
  position: sticky; // MAKE IT STICKY
  top: 0; // MAKE IT STICKY
  z-index: 1;
  flex-grow: 1;
  // @include vh100-media-up;

  @include media-breakpoint-down(md) {
    margin-left: -$spacing-lg; // negate layout-with-sidebar padding
    //margin-left: -5vw;
    //margin-top: -18px;
    position: relative;
    width: 100vw;
  }

  &.in-modal {
    height: unset;

    #video-player-wrapper {
      padding-top: 56.65%;
    }

    .video-scoreboard {
      z-index: 1;
    }
  }

  .video-pagination {
    width: 100%;
    display: flex;
    justify-content: space-around;
    z-index: 1;
    margin-top: -1px;
    padding: $spacing-sm;
    background-color: $bg-light;

    .nav-btn {
      background-color: $bg-dark;
      opacity: 1 !important;
      color: $font-color-contrasting;
      user-select: none;

      &.disabled {
        pointer-events: none;
        opacity: 0.5 !important;
      }
    }

    .current {
      width: 20%;
      text-align: center;
    }
  }
}

.videoAngles {
  background-size: cover;
  //border: solid 1px $colors-branding-primary;

  order: -1;
  width: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 100;
  position: absolute;
  transition: width 0.2s ease-out;
  height: 100%;
  width: 15%;
  min-width: 120px;
  max-width: 250px;

  max-height: calc(100% - 140px);

  > * {
    pointer-events: auto;
  }

  left: 22px;
  top: 22px;
  @include media-breakpoint-down(md) {
    top: 12px;
    left: 12px;
    justify-content: flex-start;
    width: auto;
    min-width: 180px;
    max-height: calc(100% - 40px);
  }

  .angle {
    span {
      color: white;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.75);
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.3px;
      text-transform: capitalize;
      white-space: nowrap;
      background-color: $font-color-default;
      padding: 7px 12px;
    }
  }
}
.videoAngles {
  container-type: inline-size;
}

// w150 h420
@container (width < 181px) {
  .angle {
    display: flex;
    border-radius: $border-radius;
    overflow: hidden;
    img {
      width: 32px;
      max-width: 100%;
    }
    span {
      letter-spacing: 0.3px;
      text-transform: capitalize;
      background-color: $font-color-default;
      padding: 7px 12px 7px 7px;
      flex-grow: 1;
      font-size: 9px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &:hover {
      span {
        background-color: $gray-600;
      }
    }
    &.active {
      span {
        background-color: $colors-branding-secondary;
      }
    }
  }
  .angle + .angle {
    margin-top: 5px;
  }
}

@container (width > 180px) {
  .angle {
    background-size: cover;
    width: 100%;
    position: relative;
    border-radius: 7px;
    overflow: hidden;

    transition: all 0.2s ease-in;

    cursor: pointer;
    border: solid 2px transparent;
    &:after {
      content: '';
      box-shadow: inset 0px 0px 40px rgba(0, 0, 0, 0.5);
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }

    img {
      width: auto;
      max-width: 100%;
    }
    span {
      position: absolute;
      transition: all 0.2s ease-in;

      bottom: 7px;
      left: 7px;
      border-radius: $border-radius;
      z-index: 2;
    }
    &:hover {
      border: solid 2px $gray-600;

      span {
        background-color: $gray-600;
      }
    }
    &.active {
      border: solid 2px $colors-branding-secondary;

      span {
        background-color: $colors-branding-secondary;
      }

      &:after {
        opacity: 0;
      }
    }
  }
  .angle + .angle {
    margin-top: 12px;
  }
}

#video-player-wrapper .videoAngles {
}

.page--play-match,
.page--observe-match {
  .page-header-inset {
    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }
  .video-player-actions {
    padding: 12px 5%;
  }
}

.video-player-container.play-match-main {
  @include media-breakpoint-down(md) {
    margin-right: -5vw;
    margin-left: -5vw;
    > div:not(.video-scoreboard):not(.video-pagination):not(#video-player-wrapper) {
      margin-left: 5%;
      margin-right: 5%;
    }
  }
}

.zoomcontrols {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px;
  z-index: 99;
  color: white;

  * {
    font-size: 12px;
  }

  display: flex;
  flex-direction: column;

  table {
    font-size: 9px;

    th {
      padding: 4px;
      padding-right: 12px;
    }

    td {
      text-align: right;
      max-width: 60px;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: unset;
      overflow: hidden;
      white-space: nowrap;
    }

    tr:nth-child(even) {
      th,
      td {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .btn-group button {
    background-color: white;
  }
}

.zoombutton {
  border: 0;
  padding: 4px;
  height: auto;
  width: 35px;
  height: 35px;
  border-radius: $border-radius;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 11px;
  font-weight: bold;
  background-color: $font-color-default;
  color: white;

  & + & {
    margin-left: 6px;
  }

  outline: 0;

  &.active {
    background-color: $colors-branding-secondary;
  }

  &.disabled {
    opacity: 0.6;
  }
}

.drag__wrapper,
.drag__zone {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  //border: solid 2px red;

  .closeButton {
    position: absolute;
    top: 12px;
    right: 2px;
  }
}

.drag__zone {
  pointer-events: all;
}

.jw-controls {
  z-index: 99;
}
