@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables';

html.embedding {
  background-color: transparent;
  body {
    background-color: transparent;

    .video-player-container {
      @include media-breakpoint-down(md) {
        margin-left: -($spacing-lg + $spacing-xl);
      }
    }
  }
}

.modal-video-sidebar {
  display: grid;
  grid-template-columns: 1fr auto;
  overflow: hidden;
  grid-column-gap: 12px;
  width: 100%;
  justify-content: end;

  .main-videoplayer-container {
    height: 100%;
    overflow-x: hidden;
    width: auto;
  }
  .video-player-container {
    width: auto !important;
    align-items: center;
    background-color: black;
    overflow: hidden;
    height: fit-content;
  }
  .resizeTarget {
    width: 100%;
    max-width: 100%;
  }
  .sidebar {
    min-width: 250px;
    max-width: unset;
    overflow-y: auto;
  }
  .sidebar-list {
    overflow-y: visible;
  }
  @media screen and (orientation: landscape) {
    .sidebar-list {
      overflow-y: auto;
    }
  }

  @media screen and (orientation: portrait) {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr;
    max-height: 90vh;
    justify-content: stretch;
    .sidebar {
      overflow-y: auto;
    }
  }
}

#embedding-video-player {
  background-color: #000;
  z-index: 4; // fix for .btn z-index: 2
  > div {
    height: 100%;
    width: 100%;

    > div {
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.modal--embedding {
  @media screen and (orientation: portrait) {
    @include media-breakpoint-down(sm) {
      .modal-content {
        padding-left: 0;
        padding-right: 0;
      }
      .sidebar {
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }
}
