.dashboard-wrapper {
    height: auto;
}

.dashboard-half {
    width: 50%;
    float: left;
}

.dashboard-half.left {
    padding-right: 10px;
}

.dashboard-half.right {
    padding-left: 10px;
}

.dashboard-half .substitutes {
    display: inline-block;
    padding: 4px;
}

.dashboard-half.left .substitutes {
    padding-right: 40px;
}

.dashboard-half.right .substitutes {
    padding-left: 40px;
}

.dashboard-half .substitutes .name-wrapper {
    display: inline-block;
}

.dashboard-half.left .substitutes .name-wrapper {
    display: inline-block;
    margin-right: 10px;
}

.dashboard-half.right .substitutes .name-wrapper {
    display: inline-block;
    margin-left: 10px;
}

.dashboard-half .substitutes .dot-wrapper {
    display: inline-block;
}

.dashboard-half .substitutes .dot-wrapper .dot {
    float: left;
    width: 10px;
    height: 10px;
    background: #E6E6E6;
    margin: 0 5px;
    border-radius: 50%;
}

.dashboard-half .substitutes .dot-wrapper .dot.active {
    background: #424242;
}

.observation-circle {
    width: 20px;
    height: 20px;
    margin: 3px 0 0 10px;
    font-size: 12px;
    padding: 4px;
    border-radius: 50%;
    line-height: 1;
}

.observation-circle.success {
    /*background-color: #7572b1;*/
    background-color: #5650a3;
    color: #fff;

}

.observation-circle.active {
    font-weight: 600;
    text-decoration: underline;
}

.observation-circle.success.active {
    background-color: #5650a3;
}

.dashboard-time-frame {
  margin: 60px 24px 12px;
  padding: 12px 34px;
}
