@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';
@import './layout.scss';

$spacing-v: 35px;
$spacing-v-sm: 20px;

.app-header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: $spacing-v 0;
  padding: 16px 0 16px;
  background-color: $colors-branding-primary; // TODO primary brand color

  &-content {
    @include layout-container;
    display: flex;
    justify-content: stretch;
    align-items: center;
    position: relative;
    // Set consistent height because nav-primary is portalled in later..
    min-height: 40px;

    #app-header-center > .nav-primary {
      display: none;
    }
  }

  a,
  span {
    color: white;
  }

  &-branding {
    margin-right: 24px;

    &-logo {
      height: 40px;
    }
  }
  @include media-breakpoint-up(xxl) {
    .user-nav-role,
    .user-nav-profile {
      display: flex;
      align-items: center;
      align-content: center;
    }
    .user-nav-role .user-nav-label,
    .user-nav-profile .user-nav-label {
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      max-width: 18ch;
      white-space: nowrap;
      line-height: 1.1;
    }
    .user-nav-profile .user-nav-label {
      max-width: 14ch;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    padding: $spacing-v-sm 0;

    &-branding {
      margin: 0;
    }
    &-content {
      // Set consistent height because nav-primary is portalled in later..
      min-height: 30px;
    }
  }
}
