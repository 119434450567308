@import 'lib/styles/variables.scss';
@import 'lib/styles/layout.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: -1px;

  @media screen and (max-width: 475px) {

    button {
      margin-bottom: $spacing-sm;
    }
  }
}
