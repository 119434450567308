.video-player {
  margin-bottom: 8px;
}
.video-section {
  /*margin-top: 70px;*/
}

.video-player .video-scores {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.video-player .video-scores-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 2;
}

.video-player .video-scores-inner .video-scores-wrapper {
}

.video-scores-wrapper .video-score-club {
  background: #565656;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 44px;
  color: #fff;
}

.video-scores-wrapper .video-score-club.left {
  -webkit-clip-path: polygon(0 0, 92% 0, 100% 100%, 8% 100%);
  clip-path: polygon(0 0, 92% 0, 100% 100%, 8% 100%);
  margin-right: 20px;
  border-bottom-left-radius: 42px 88px;
}

.video-scores-wrapper .video-score-club.right {
  -webkit-clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
  clip-path: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
  margin-left: 20px;
  border-bottom-right-radius: 42px 88px;
}

.video-scores-wrapper .video-score-club .possession-circle {
  width: 10px;
  height: 10px;
  background: #28e89e;
  position: absolute;
  border-radius: 50%;
}

.video-scores-wrapper .video-score-club.left .possession-circle {
  left: 34px;
  top: 20px;
}

.video-scores-wrapper .video-score-club.right .possession-circle {
  right: 34px;
  top: 20px;
}

.video-score-club .team-name {
  margin: 0 10px;
}

.video-score-club .team-score {
  margin: 0 10px;
}

.video-scores-wrapper .video-score-time {
  -webkit-clip-path: polygon(0 0, 100% 0, 92% 100%, 8% 100%);
  clip-path: polygon(0 0, 100% 0, 92% 100%, 8% 100%);
  background: #424242;
  font-size: 16px;
  font-weight: 300;
  padding: 12px 0px;
  color: #fff;
  width: 100px;
  position: absolute;
  left: calc(50% - 50px);
  z-index: 3;
  border-radius: 7px;
}

.video-score-club .team-thumb {
  border-radius: 50%;
  margin: 0 10px;
  height: 24px;
  width: 24px;
}

.input-row {
  height: 68px;
  overflow: hidden;
  margin: 0 -8px;
  width: calc(100% + 16px);
}

.input-row.width-1 .input-col {
  width: calc(100% - 16px);
}

.input-row.width-2 .input-col {
  width: calc(50% - 16px);
}

.input-row.width-3 .input-col {
  width: calc(33.33% - 16px);
}

.input-row.width-4 .input-col {
  width: calc(25% - 16px);
}

.input-row.width-5 .input-col {
  width: calc(20% - 16px);
}

.input-row.width-6 .input-col {
  width: calc(16.66% - 16px);
}

.input-row.width-7 .input-col {
  width: calc(14.28% - 16px);
}

.input-col {
  float: left;
  margin: 8px;
  padding: 14px;
  border-radius: 7px;
}

.input-col.light {
  background: #f2f2f2;
}

.input-col.dark {
  background: #424242;
  color: #fff;
}

.input-col.transparent {
  background: none;
}

.input-col .input-tag {
  position: absolute;
  top: 3px;
  left: 6px;
  font-size: 12px;
}

.input-row .input-observations {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 36px;
  margin-top: 16px;
  margin-bottom: 32px;
}

.input-row.width-1 .input-col.input-observations {
  width: calc(100% - 16px - 50px - 8px);
}

/*.input-col.input-observations::after {*/
/*width: 50%;*/
/*display: block;*/
/*position: absolute;*/
/*top: 0;*/
/*left: 0;*/
/*content: "";*/
/*height: 100%;*/
/*background: linear-gradient(to right, #fff 0%, transparent 100%);*/
/*}*/

.input-undo-icon {
  width: 50px;
  height: 50px;
  margin: 8px 8px 8px 0px;
  background: red;
  float: left;
  background: url('../img/icons/undone.svg');
  background-size: cover;
}

.input-undo-icon.disabled {
  opacity: 0.3;
}

.input-observations .input-observation {
  flex: 0 0 auto;
  padding: 7px 21px;
  color: #424242;
}

.input-observation .input-observation-time {
  float: left;
  padding-right: 7px;
}

.input-observation .input-observation-name {
  float: left;
}
.input-observation.selected .input-observation-name {
  font-weight: 500;
}

.input-observation::after {
  display: block;
  content: '';
  width: 14px;
  height: 14px;
  position: absolute;
  background: url('../img/icons/arrow_right.svg');
  right: -7px;
  top: 14px;
}

.jwplayer:focus {
  outline: none !important;
}

.button-wrapper {
  display: flex;
  flex: 0 1 auto;
}

.video-wrapper {
  background-color: #000;
}

.selected-observation-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
}

.selected-observation-wrapper {
  z-index: 11;
  position: absolute;
  width: 320px;
  height: auto;
  left: calc(50% - 160px);
  bottom: 8px;
}
.selected-observation-inner {
  background: #f2f2f2;
  border-radius: 7px;
  /*padding: 50px 8px;*/
  /*min-height: 210px;*/
  padding: 8px 20px;
}
.selected-observation-inner .button {
    padding: 6px 12px;
}
.selected-observation-icon {
  position: absolute;
  width: 50px;
  height: 50px;
  padding: 15px;
}
.selected-observation-icon.play {
  left: -50px;
  top: calc(50% - 25px);
  background: #f2f2f2;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.selected-observation-icon.play::after {
  display: block;
  content: url('../img/icons/play.svg');
  width: 20px;
  height: 20px;
}
.selected-observation-icon.delete {
  right: -50px;
  top: calc(50% - 25px);
  background: #ff3232;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.selected-observation-icon.delete::after {
  display: block;
  content: url('../img/icons/delete_white.svg');
  width: 20px;
  height: 20px;
}
.selected-observation-time {
  padding-right: 4px;
  float: left;
  white-space: nowrap;
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #828282;
  font-size: 14px;
  padding-top: 2px;
}
.selected-observation-name {
  padding-left: 4px;
  float: left;
  white-space: nowrap;
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;
  font-size: 16px;
}

/* FIXES FOR JWPLAYER 100% height and 100% width */
#video {
  height: 100%;
}

#video > div {
  height: 100%;
  width: 100%;
}

#video > div > div.jwplayer {
  height: 100% !important;
  width: 100% !important;
}
/****************************************************/
