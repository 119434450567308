@import '../../lib/styles/variables';
@import '~bootstrap/scss/mixins';

.training-schedule-details {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  padding-right: 57px; // dont flow over actions

  .overview-item-attribute {
    position: relative;
    left: 0;
    margin-right: 1em;
  }
}

.training-schedule-details__team {
  margin-left: auto;
  white-space: nowrap;
  line-height: 1.1; // Fix missing tails of caracters below baseline..
  cursor: pointer;
  @include media-breakpoint-down(xs) {
    display: none;
  }
}
.training-schedule-details__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.1; // Fix missing tails of caracters below baseline..
}

.overview-schedule {
  width: 100%;
  .overview-item-details {
    margin: 0;
    max-width: 100%;
    overflow: hidden;
  }
  .overview-item-date .tooltip__wrapper {
    position: relative;
    margin-left: -8px;
    margin-right: 2px;
    cursor: pointer;
  }
}
