@import 'lib/styles/variables.scss';

.react-joyride__tooltip {
  button {
    outline: none;
  }

  button[data-action="close"] {
    /* not working properly.. lets hide it */
    display: none;
  }

  button[data-action="skip"] {
    font-size: 16px !important;
  }

  button[data-action="back"] {
    color: $font-color-default !important;
  }

  button[data-action="primary"] {
    background-color: var(--primary-color) !important;
  }
}