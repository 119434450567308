.observation-form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    column-gap: 20px;

    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
}
.jsonschema-form {
  fieldset {
    legend:first-child {
      display: none;
    }
  }
  .form-w-100 {
    grid-column: 1 / -1;
  }

  @for $i from 1 through 10 {
    .form-order-#{$i} {
      order: #{$i};
    }
  }
}

.jsonschema-form-actions {
  @media screen and (min-width: 1024px) {
    padding-left: calc(50% + 10px);
  }
}
