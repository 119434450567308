@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';

.user-nav {
  position: relative;
  display: flex;
  justify-content: flex-end;
  color: white;
  white-space: nowrap;
  cursor: pointer;

  &::after {
    content: '';
    width: 18px;
    height: 30px;
    background: url('../../img/icons/chevron_down.svg') 50% 50% no-repeat;
    margin-left: $spacing-md;

    @include media-breakpoint-down(lg) {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='33' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='3' y1='12' x2='21' y2='12'%3E%3C/line%3E%3Cline x1='3' y1='6' x2='21' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='18' x2='21' y2='18'%3E%3C/line%3E%3C/svg%3E");
      background-size: 100% auto;
    }
  }

  &-profile {
    margin-right: $spacing-lg;
  }
  &-divider {
    margin-left: $spacing-lg;
  }
  &-logo {
    margin-right: $spacing-md;
  }

  //@media screen and (max-width: 1400px) {
  @include media-breakpoint-down(xl) {
    &-label {
      display: none;
    }
    &-divider {
      display: none;
    }
    &-profile,
    &-logo {
      margin-right: 0;
      margin-left: -1 * $spacing-sm; // overlap logos
    }
  }

  @include media-breakpoint-down(sm) {
    &-profile,
    &-logo {
      margin-right: 0;
      margin-left: -1 * $spacing-sm; // overlap logos
    }

    &-label,
    &-divider {
      display: none;
    }
  }
}

// .nav-primary moves between the resource group menu and layout header on
// breakpoint xl
@include media-breakpoint-up(lg) {
  .resource-group-menu .nav-primary {
    display: none;
  }

  #app-header-center > .nav-primary {
    display: flex !important;
  }
}
