@import '~bootstrap/scss/mixins';
@import '../styles/variables';

.create-custom-tag-view,
.manage-custom-tags-view {
  min-width: 80vw;

  @include media-breakpoint-up(md) {
    min-width: 600px;
  }
}
