@import 'lib/styles/variables.scss';
@import 'lib/styles/animations.scss';


%after-anim{
  content:'';
  position: absolute;
  left: 0;
  top:calc(100% + 2px);
  width: 0px;
  height:2px;
  transition: width 0.2s ease;
  background-color: $colors-branding-secondary;
}
%after-anim-active{
  width:100%;
}


.sv_q{
  padding: 0.5rem 0 0.75rem 0;
}

div.pop-inner.no-padding {
  padding: 0px!important;
}

.pop-inner{
  overflow-y: auto;
  max-height: calc(100vh - 120px);
}

.popup-wrapper button.close{
  position: absolute;
  right:30px;
  top:30px;
}

.sv_header{
  background-color: rgba(0,0,0,0.1);
  padding:30px 30px 15px 30px;
  border-bottom: solid 2px $colors-branding-primary;
  background-color: #f9f5f2;
  *{
    color: $black;
  }
}
.sv_row{
  padding: 15px 30px;
  border-top: solid 1px #efefef;
  background-color: white;
  &:nth-child(2n){
    background-color: #f9f5f2;
  }
}

.sv_nav{
  padding:12px 30px;
  background-color: $gray-700;
}

// Change focus color to branding color.
.sv_main.sv_default_css input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]){
  &[type="text"],&[type="email"]{
    background-color: white;
    border: solid 1px $gray-700;
  }
  &:focus{
    border-color: $colors-branding-secondary;
  }
  &:focus-within,&:focus-visible{
    outline-color: $colors-branding-secondary;
  }
}
// Padding on textarea elements
.sv_main.sv_default_css textarea{
  padding: 4px 6px;
  width:100%;
  resize: none;
  border-radius: 4px;
}

.sv_qstn .sv_q_select_column{
  width:100%;
  @media screen and (max-width: 600px){
    max-width: 100% !important;
  }
}
.sv_q_title{
  margin-bottom: 1em;
}


.sv_q_radiogroup_control_item,.sv_q_checkbox_control_item{
  margin-right: 0.5rem;
  &+span{
    position: relative;
    &:after{
      @extend %after-anim;
    }
  }
  &:checked + span{
    //border-bottom: solid 1px $colors-branding-secondary;
    &:after{
      @extend %after-anim-active;
    }
  }
}
/* Make all labels clickable. */
.sv_main.sv_default_css .sv_container label{
  cursor: pointer;
}

/* Rating items */
.sv_q_rating_item{
  margin:2px;
  .sv_q_rating_item_text{
    vertical-align: top;
    padding:0.2em 0.4em;
    border:solid 2px $colors-branding-secondary;
    border-radius: 3px;
    display: block;
  }
  &.active .sv_q_rating_item_text{
    background-color: $colors-branding-secondary;
  }
}

.sv_q_rating_min_text,
.sv_q_rating_max_text
{
  font-size: 12px;
  margin:auto;
}
.sv_q_rating fieldset{
  display: flex;
  justify-content: space-evenly;
}

@media screen and (max-width: 600px){
  .sv_q_rating_min_text{
   display: none;
  }
  .sv_q_rating_max_text{
    display: none;
  }
}

/* Error styling */
.sv_q_erbox{
  border-left: solid 2px $danger;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
  color: $danger;
}
.sv_row{
  position: relative;
}
[aria-invalid=true]{ // Add visual indicator on form error.
  &:after{
    content:'';
    position: absolute;
    left: 0;
    top:0;
    height:100%;
    width:1px;
    display: block;
    border-left: solid 4px $danger;
  }
}




/*Button*/
.sv_main.sv_default_css input[type="button"], .sv_default_css button{
  min-width: 120px;
  padding:0.5em 1em;
}

/*Progress bar*/

.sv_progress{
  display: block;
  //padding:12px 30px;
  margin:0!important;

  .sv_progress_bar{
    height:0px;
    position: relative;
    &:before{
      background-color: $colors-branding-secondary;
      content:'';
      position: absolute;
      top:0;
      left: 0;
      width:100%;
      height:18px;
    }
    display: block;
    &+span{
      position: relative;
      display: block;
      font-size: 12px;
      height:18px;
      padding-right: 30px;
      text-align: right;
      color: $gray-700;
    }
  }
}

/* Completed page*/
.sv_completed_page{
  padding:30px;
}

/* btn */

.sv_main.sv_default_css input[type="button"], .sv_default_css button{
  background-color: $colors-branding-secondary;
}