@import '../../../lib/styles/variables';

$provisioned_color: #6be1ec;
$started_color: #00d380;

.overview-item--learningGroup {
  &.learningGroup--new {
    .user-setting-thumb {
    }
  }
  &.learningGroup--started {
    .user-setting-thumb {
      background: $started_color;
    }
  }
  &.learningGroup--provisioned {
    .user-setting-thumb {
      background: $provisioned_color;
    }
  }

  .actions button + button {
    margin-left: 16px;
  }
  box-shadow: $overview-item-shadow;
}
.learninggroup-detail-trainees {
  .overview-item {
    box-shadow: $overview-item-shadow;
  }
}

.user-setting-item .user-setting-icons .user-setting-icon.view {
  background: $success;
}
.user-setting-item .user-setting-icons .user-setting-icon.view::before {
  content: '';
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  background: url('../../../img/icons/arrow_right.svg');
  filter: invert(100%);
  right: 16px;
  top: 16px;
}

.learninggroup-detail-course {
  margin-bottom: 54px;
  padding: 1.25em;
  border-radius: 7px;
  display: block;
  background-color: #f3f3f3;
  max-width: 650px;
  box-shadow: $overview-item-shadow;
}

.learninggroup-detail {
  .actions {
    display: flex;
    flex-wrap: wrap;
  }
}

.learninggroup-detail-trainees {
  .learninggroup-detail-trainees__info {
    display: flex;
    padding-bottom: 12px;
    align-items: center;
    align-content: center;

    button {
      margin-right: 32px;
    }
  }
}

.learningGroup__teacher {
  display: flex;
  align-items: center;
  align-content: center;
  img {
    width: 30px;
    height: 30px;
    border-radius: 200px;
    margin-right: 12px;
  }
  & + & {
    margin-top: 6px;
  }
  margin-bottom: 6px;
  padding: 9px;
  background-color: #e9e9e9;
  border-radius: 7px;
  &.invited {
    background-color: #f3e9aa;
  }
  > i,
  > span[data-tip] {
    margin-left: auto;
  }
  span[data-tip] {
    cursor: pointer;
  }
  .teacher-name {
    font-weight: bold;
    line-height: 1;
  }
  .teacher-mail {
    font-size: 85%;
    opacity: 0.75;
  }
}
