@import './variables';

.i {
  display: inline-block;
  width: $icon-size;
  height: $icon-size;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  // TODO: move these magic numbers into variables
  .label > & {
    height: 13px;
    width: 13px;
    min-width: 13px;
    margin: 0 0 0 10px;
  }

  // TODO: make icon placement consistent to fix this weird implementation
  .label-i-lft > & {
    margin: 0 10px 0 0;
  }

  .btn > & {
    // correct vertical alignment of custom SVG icons
    margin-top: 1px;
    margin-bottom: -1px;

    &:first-child {
      margin-right: $spacing-sm;
    }
  }
}

$icons: (
        aspectratio: aspectratio,
        add: 'plus',
        dots: 'dots',
        dots-white: 'dots_white',
        delete: 'delete_white',
        upload: 'upload',
        cast: 'cast',
        stats: 'statistics',
        observe: 'label',
        lineup: 'soccer_field',
        play: 'play',
        playlist: 'playlist',
        pause: 'pause',
        flip: 'flip',
        export: 'download',
        processing: 'process',
        settings: 'settings',
        check: 'check',
        check-grey: 'check-grey',
        guide-sign: 'guide_sign',
        language: 'language',
        chevron-down: 'chevron_down',
        chevron-up: 'chevron_down',
        chevron-left: 'chevron_down',
        chevron-right: 'chevron_down',
        corner-left-up: 'corner-left-up',
        cross: 'cross',
        cross-red: 'cross_red',
        grid: 'grid',
        record: 'record',
        liverecord: 'liverecord',
        stop: 'stop',
        uploading: 'uploading',
        error: 'error',
        edit: 'pencil',
        undo: 'undone',
        'time-out': 'time_out',
        more: 'more',
        clock: 'clock',
        pending: 'pending',
        user: 'user',
        undo2: 'undo2',
        warning: 'warning',
        notification: 'notification',
        exchange: 'exchange',
        'knowledge-base': 'knowledge_base',
        send: 'send',
        messages: messages,
        'refresh': 'refresh',
        calendar-small: calendar-small,
        link: link,
        zoomin: zoom_in,
        zoomout: zoom_out,
        reset: reset,
        aclLock: player_permission,
        playlistnext: playlistnext,
        playlistprev: playlistprev,
        presentation: presentation,
        submitted: submitted,
        jersey: jersey,
        email: email_sent,
        mail: mail,
        push: phone,
        inapp: notification,
        folder: folder,
        autocam: autocam_icon,
        autocam-light: autocam_icon_white,
        livestream-watch: autocam_view_white,
        livestream-observe: autocam_observe_white,
);

.i-logo {
  /* @benvds where should this be? */
  width: $logo-size;
  height: $logo-size;
  border-radius: 50%;
}

.i-team {
  @extend .i;
  border-radius: 50%;
}

.i-undo {
  transform: scale(3); /* old svg used as icon */
}

.i-chevron-up {
  transform: rotate(180deg);
}

.i-chevron-left {
  transform: rotate(90deg);
  background-position: center 2px;
}

.i-chevron-right {
  transform: rotate(270deg);
}

.i-sm {
  width: 16px;
  height: 16px;
}

.i-xs {
  width: 12px;
  height: 12px;
}

.i-dark {
  filter: brightness(0);
}

.i-light {
  filter: brightness(0) invert(1);
}

.i-flipped {
  transform: scaleY(-1);
}

.i-no-filter {
  filter: none;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.i-rotating {
  animation: spin 4s linear infinite;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.i-flash {
  animation: flash 2s linear infinite;
}

@each $key, $value in $icons {
  .i-#{$key} {
    @extend .i;
    @if $value == uploading {
      background-image: url('../../img/icons/uploading.gif');
      filter: none;
    } @else {
      background-image: url('../../img/icons/#{$value}.svg');
    }
  }
}
