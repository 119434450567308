@import '../../styles/variables';
@import '../../styles/layout';

.view-filters {
  //padding: 2rem 0 1rem 0;
  width: 100%;
  //background-color: #5e5e5e;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  //margin-top: -36px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.searchbox {
  //width: 100%;
  //padding: 0 5% 0.5rem 5%;

  @include media-breakpoint-down(sm) {
    margin-bottom: 12px;
    width: 100%;
  }

  .searchbox-wrapper {
    margin: 0 auto;
    position: relative;
    display: flex;
    max-width: 650px;
    border: solid 1px $font-color-default;
    border-radius: $border-radius;
  }
  input {
    padding: 4px 34px 4px 8px;
    width: 100%;

    background-color: white;
  }
  button {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    background: transparent;
    display: flex;
    height: 100%;
    width: 40px;
    align-items: center;
    align-content: center;
    pointer-events: none;

    svg {
      transition: fill 0.2s ease-in;
    }
    &:hover,
    &:focus {
      svg {
        fill: var(--primary-color);
      }
    }
  }
}

.page-header-cta {
  flex-shrink: 0;
}

.buttongroup {
  display: flex;
  //justify-content: center;
  overflow: auto;
  max-width: 100%;

  &::-webkit-scrollbar {
    width: 0.1rem;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $gray-800;
    border-radius: 9999px;
  }

  .buttongroup__scrollwrapper {
    display: flex;
    width: auto;
    cursor: grab;
  }
  button {
    margin: 0 6px;
    border: solid 1px $font-color-default;
    &:first-child {
      margin-left: 0;
    }
    background-color: white;
    border-radius: $border-radius;
    padding: 4px 10px;
    font-size: 14px;
    white-space: nowrap;
    padding: 0.3125rem 0.75rem;

    &:hover,
    &:focus {
      background-color: $gray-300;
    }
    &.active {
      background-color: $font-color-default;
      color: white;
    }
  }
  .checkBox {
    margin: 0 6px;
    border-radius: $border-radius;
    border: solid 1px white;
    padding: 4px 10px;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-content: center;
    align-items: center;
    //background-color: $font-color-default;
    cursor: pointer;
    border: Solid 1px $font-color-default;
    &:before {
      content: '';
      width: 1em;
      height: 1em;
      margin-right: 0.3em;
      border: solid 1px $font-color-default;
      border-radius: 4px;
      display: inline-block;
    }
  }
  .checkBox.active:before {
    background-color: $colors-branding-secondary;
    border: solid 1px $font-color-default;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 80%;
    background-position: center center;
  }
}

:not(.page-header-content) .viewfilter-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2em;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.page-header-content > .viewfilter-wrapper {
  display: contents;
}
