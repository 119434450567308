.user-settings-header {
    padding: 50px 0;
    background: #F2F2F2;
    color: #000;
}

.user-settings-header h1, .user-settings-header h2 {
    color: #000;
}

.light-span {
    padding: 0 20px;
    color: #828282;
}

.change-icon {
    width: 24px;
    height: 24px;
    background: url('../img/icons/change.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.user-gender-row {
    padding: 20px 0 40px 0;
}

.user-gender-row::before {
    content: url('../img/icons/male.svg');
    width: 20px;
    height: 20px;
    display: block;
    position: relative;
    float: left;
    padding-right: 10px;
}

.user-setting-item {
    height: 57px;
    width: 100%;
    background: #F2F2F2;
    border-radius: 7px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding-left: 82px;
    position: relative;
    margin: 16px 0;
}

.user-setting-thumb {
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 57px;
    width: 57px;
    background: #D8D8D8;
}

.user-setting-thumb-inner {
    border-radius: 50%;
    position: relative;
    left: 0px;
    top: 0px;
    height: 57px;
    width: 57px;
    background: url('../img/fdbd.png');
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center center;
}

.user-setting-item .flex-content {
    padding-left: 82px;
    padding-right: 57px;
}

.user-setting-item.invited .flex-content {
    padding-left: 82px;
    padding-right: 114px;
}

.user-setting-item .user-setting-icons {
    width: 57px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
}

.user-setting-item.invited .user-setting-icons {
    width: 114px;
}

.user-setting-item .user-setting-icons .user-setting-icon {
    position: relative;
    float: left;
    width: 57px;
    height: 57px;
}

.user-setting-item .user-setting-icons .user-setting-icon.delete {
    background: #FF3232;
}

.user-setting-item .user-setting-icons .user-setting-icon.delete::before {
    content: "";
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    background: url('../img/icons/delete_white.svg');
    right: 16px;
    top: 16px;
}

.user-setting-item .user-setting-icons .user-setting-icon.check {
    background: #0FCF85;
}

.user-setting-item .user-setting-icons .user-setting-icon.check::before {
    content: "";
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    background: url('../img/icons/check_white.svg');
    right: 16px;
    top: 16px;
}

.user-setting-item .user-setting-icons .user-setting-icon.cross {
    background: #CF0F0F;
}

.user-setting-item .user-setting-icons .user-setting-icon.cross::before {
    content: "";
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    background: url('../img/icons/cross_white.svg');
    right: 16px;
    top: 16px;
}

/*
.user-setting-item::before {
	content: "";
	display: block;
	width: 57px;
	height: 57px;
	position: absolute;
	right: 0;
	top: 0;
	background: #FF3232;
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
}

.user-setting-item::after {
	content: "";
	display: block;
	position: absolute;
	width: 25px;
	height: 25px;
	background: url('../img/icons/delete_white.svg');
	right: 16px;
	top: 16px;
}
*/

.user-setting-action {
    padding-right: 25px;
    position: relative;
}

.user-setting-action.with-stripe::after {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 14px;
    background: #000;
    right: 12px;
    top: 6px;
}

.avatar {
    width: 100%;
    padding-bottom: 100%;
    background: #D8D8D8;
    border-radius: 50%;
    margin-top: 20px;
}

.avatar.empty::after {
    content: url('../img/icons/plus.svg');
    display: block;
    position: absolute;
    width: 40%;
    height: 40%;
    top: 30%;
    left: 30%;
}