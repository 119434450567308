@import '../styles/variables';

.video-card {
  cursor: pointer;

  .video-card__top {
    position: relative;
    overflow: hidden;
    will-change: transform;
    backface-visibility: hidden;

    // IOS border radius fix: https://stackoverflow.com/questions/14383632/webkit-border-radius-and-overflow-bug-when-using-any-animation-transition/16681137
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    border-radius: $border-radius;

    .paused-overlay-wrapper {
      display: flex;
    }
    .pausedoverlay {
      display: block;
      width: 100%;
      padding-bottom: 56.25%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.05) 30%,
        rgba(0, 0, 0, 0.6) 85%
      );
    }

    .video-card-info {
      z-index: 1;
      position: absolute;
      width: 100%;
      vertical-align: bottom;
      bottom: 0;
      padding: $spacing-lg;
      transition: all 0.3s ease-in-out;
      color: white;
      text-shadow: 0px 0px 3px $font-color-default;
      // Improve legibility
      h3 {
        font-size: 16px;
        margin: 0;
      }

      .duration {
        position: relative;
        right: 0;
        font-size: 11px;

        opacity: 0.75;
        letter-spacing: 0.4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &:hover .video-card-info {
      transform: translateY(100px);
    }
  }

  .video-card-player {
    border: 0;
    overflow: hidden;
  }
}
