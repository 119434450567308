.collapse-icon {
  display: flex;
  align-items: center;

  &.collapsed {
    .i-chevron-down {
      transform: rotate(270deg);
    }
  }
}
