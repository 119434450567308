@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';

$date-width: 55px;

.match-overview-item {
  .overview-item {
    &-attribute {
      position: absolute;
      left: $date-width + $spacing-default;

      & + .overview-item-attribute {
        // 2 next to eachother.
        left: $date-width + $spacing-default + 44px;
      }

      display: none;
      @include media-breakpoint-up(sm) {
        display: block;
      }

      .sharing-icon {
        &.in-use {
          filter: contrast(160%);
          //filter: sepia(100%);
          border-radius: 8px;
        }
      }
      .scheduling-icon {
        width: 36px;
      }
    }

    &-team {
      display: flex;
      flex: 6 1 auto;
      width: 35%;
      height: 100%;
      padding: 0 0.5rem;
      align-items: center;

      &-home {
        justify-content: flex-end;
      }
      &-away {
        flex-direction: row;

        .i-team {
          order: -1;
        }
      }
    }

    &-score {
      flex: 0 0 auto;
      min-width: 4rem;
      font-weight: bold;
      text-align: center;
      white-space: nowrap;
    }

    &-actions {
      position: absolute;
      right: 0;
    }
  }

  .i-team {
    margin: 0 0.5rem;
  }
}
.overview-item--islocked {
  .overview-item {
    background-color: #f6f6f6;
  }
  .overview-item-date {
    background-color: #afafae;
  }
}

.match-archive {
  padding-bottom: 24px;
  padding-inline: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-end;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
  }
}

.match-archive__text {
  h3 {
    margin: 0;
    font-size: 18px;
    opacity: 0.8;
  }
  p {
    margin: 0;
  }
}
.match-archive__usage {
  span {
    font-size: 12px;
    display: block;
  }
  font-size: 12px;
  //max-width: 145px;
  margin-right: auto;
  margin-left: 30px;
}
.match-archive__cta {
  @media screen and (max-width: 767px) {
    margin-top: 12px;
  }
}

.overview-item .tooltip--styled {
  border-radius: 7px;
  background-color: $gray-700;
  max-width: 210px;
}
