@import '../../../../node_modules/bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';
@import 'lib/styles/animations.scss';

$headings: 'h1,h2,h3,h4,h5,h6';
$dialog-color: #f2f2f2;
$dialog-color-dark: #0f0f0f;

.popup-open {
  // Kill the scroll on the body
  overflow: hidden;

  .popup-wrapper {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.popup-container {
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  z-index: 9;

  &.is-hidden {
    pointer-events: none;
  }
}
#video-player-wrapper .popup-container,
.video-player-wrapper .popup-container {
  z-index: 100; // show over JW controls.
}

.popup-overlay {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  z-index: -1;

  background-color: rgba(90, 90, 90, 0.4);

  .is-shown & {
    animation: fadeIn 0.3s forwards;
  }
  .is-hidden & {
    pointer-events: none;
    animation: fadeOut 0.3s forwards;
  }
}

.popup-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;

  width: calc(100% - #{2 * $spacing-lg});
  max-width: 600px;

  background-color: $dialog-color;

  justify-content: flex-start;
  align-items: flex-start;

  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
  //https://shadows.brumm.af/

  overflow: hidden;
  border-radius: $border-radius;

  .spacer {
    width: 100%;
    padding-top: 50%;
  }

  .pop-inner {
    z-index: -1;
    display: block;
    width: 100%;
    padding: $grid-gutter-width;

    max-height: calc(100vh - 60px);
    // todo: Add --innerVH variable.

    @include media-breakpoint-up(sm) {
      padding-left: $grid-gutter-width * 2;
      padding-bottom: $grid-gutter-width * 2;
    }
    p,
    #{$headings} {
      color: #343548;
    }
    .dark & {
      background-color: black;
      color: white;
      p,
      #{$headings} {
        color: white;
        text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.9);
      }
    }
  }

  .is-shown & {
    animation: popIn 0.2s forwards;
  }
  .is-hidden & {
    pointer-events: none;
    animation: popOut 0.33s forwards;
  }
}

.close {
  align-self: flex-end; // Sort of floatright.
  &:hover {
    opacity: 0.5; // Need better hover ideas for icons.
  }
  position: relative;

  right: $grid-gutter-width;
  top: $grid-gutter-width;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  position: sticky;
}

.popup-content {
  position: relative;
  display: block;
  max-width: 360px;
}

.popup-visual {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: percentage(9/16); // image-ratio
  width: 100%;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  &:before {
    content: '';
    background: linear-gradient(
      0deg,
      $dialog-color,
      #{transparentize($dialog-color, 0.9)} 60%
    );
    .dark & {
      background: linear-gradient(0deg, #000, rgba(0, 0, 0, 0.05) 40%);
    }
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.preload {
  display: none;
}
