.marketing-wiggle {
  animation: wiggle 0.5s ease 2s 2;
}

// https://cssfx.dev/
@keyframes wiggle {
  0%,
  100% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
