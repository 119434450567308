@import '~bootstrap/scss/mixins/breakpoints';
@import 'lib/styles/bootstrap_variables.scss';
@import 'lib/styles/variables.scss';

.observation-input-live {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &.no-sport {
    height: auto;

    .invoer-buttons {
      padding-top: $spacing-xl;
    }
  }

  &.period-state-ended {
    .labels {
      opacity: 0.5;
    }
  }

  .modal-dialog {
    width: 100vw;
  }
}

@include media-breakpoint-up(xl) {
  .observation-input-live {
    width: 75vw;
    margin: auto;
  }
}

