@import '~bootstrap/scss/mixins/breakpoints';
@import 'lib/styles/variables.scss';

.observation-input-live {
  .labels:nth-child(2),
  .labels:nth-child(3),
  .labels-connected {
    @include media-breakpoint-up(lg) {
      margin-top: 50px;
      .label {
        font-size: 24px !important;
      }
    }
    @include media-breakpoint-down(xs) {
      .label {
        font-size: 16px !important;
        font-weight: 300 !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
    }

    margin-left: 15px;
    .label {
      width: calc(100% / 3 - 12px);
      font-size: 18px;
      font-weight: 600;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-top: 10px;
    }
  }

  .labels-connected {
    margin-top: 50px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.larger-button {
  .label {
    padding: 16px 22px;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.modal .label.btn-primary {
  // Overrride for .label-light in modal for hockey.live
  background-color: $colors-branding-secondary;
  padding: 0.5rem 2rem;
  margin: auto;
  color: white;
}
