@import './variables';

.rc-slider {
  $slider-highlight-color: $bg-dark;

  &-mark-text {
    bottom: 24px;
    white-space: nowrap;
    font-size: $font-size-base;
  }
  &-track {
    height: 6px;
    margin-top: -1px;
    background-color: $slider-highlight-color;
  }
  &-handle {
    width: 16px;
    height: 16px;
    border-color: $slider-highlight-color;
    margin-top: -6px;
    margin-left: -8px;

    &:hover {
      border-color: $slider-highlight-color;
    }
    &:focus {
      border-color: $slider-highlight-color;
      box-shadow: 0 0 5px $slider-highlight-color;
    }
    &:active {
      border-color: $slider-highlight-color;
      box-shadow: 0 0 5px $slider-highlight-color;
    }

    &-click-focused:focus {
      border-color: $slider-highlight-color;
    }
  }
  &-dot {
    height: 12px;
    width: 12px;
    bottom: -4px;
    margin-left: -6px;

    &-active {
      border-color: $slider-highlight-color;
    }
  }
}

.form-control-datepicker {
  padding: 6px 12px;
  background-color: white;
  border: 1px solid #ccc !important; // force border over existing attribute selectors
}
