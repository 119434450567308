@import "../../../lib/styles/variables";
@import '~bootstrap/scss/mixins';

$indicator-warning-color: #ff9f69;

.course-sidebar {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  flex-shrink: 0;
  width: 18rem;
}


.assignment-view {
  display: flex;
  max-width: 1024px;
  margin: auto;

  .assignment-view__assignment {
    background-color: white;
    flex-shrink: 0;
    padding: 2rem;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    margin-right: 2.5rem;
    flex-grow: 1;
    width: auto;
    flex-basis: 75%;

  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    .assignment-view__assignment {
      flex-basis: 100%;
      padding: 1.5rem;
      margin: 0 -2.5%;
    }
    .assignment-view__list {
      width: 100%;
      margin-top: 2rem;
      flex-basis: 100%;
    }
    .course-sidebar {
      width: 100%;
    }
  }

}


.course-sidebar__phase {
  border-radius: $border-radius;
  background-color: white;
  padding: 1rem;

  box-shadow: $box-shadow;

  & + & {
    margin-top: 1em;
  }

  border: solid 2px transparent;

  &.is-active {
    //background-color: #ecfffb;
    border-left: solid 2px #2a80b9;
  }

  &.is-completed {
    background-color: #f1feef;
  }
}

.course-sidebar__phase-title {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0.25rem 0;

  .toggle {
    margin-left: auto;
  }

  .percentage {
    //margin-right: 0.5rem;
    opacity: 0.8;
    width: 2.5rem;
    //text-align: center;
  }
}

.course-sidebar__practice {
  display: flex;
  justify-content: start;
  align-items: center;
  align-content: center;
  line-height: 1.1;
  position: relative;

  .left{
    position: relative;
  }
  label {

    transition: all 0.2s ease;
    font-width: bold;
    display: block;
    margin: 0;
    line-height: 1;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .small {
    opacity: 0.7;
    font-size: 0.75rem;
  }

  & + & {
    margin-top: 0.75rem;
  }

  &.is-active label {
    color: #5265CD;
    font-weight: bold;
  }

}

.indicator-icon {

  margin-right: 0.5rem;
  border-radius: $border-radius;
  border: solid 1px $black;
  padding: 0.2rem;
  cursor: pointer;

  i {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    border-color: rgba(47, 129, 222, 0.56);
  }

  .status--done &,
  .status--accepted & {
    background-color: $green;
    border-color: $green;
    color: $green;
  }

  .status--changes_requested & {
    background-color: lighten($indicator-warning-color, 10%);
    border-color: lighten($indicator-warning-color, 10%);
    color: $indicator-warning-color;
  }

  .status--rejected & {
    background-color: lighten($red, 10%);
    border-color: lighten($red, 10%);
    color: $red;
  }

  .status--submitted & {
    color: $blue;
  }

  &.show-notification {
    position: relative;

    &:after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      top: -4px;
      right: -4px;
      background-color: currentcolor;
      border-radius: 100px;
      border: solid 1px white;
    }
  }
}

.course-sidebar__practice .indicator-icon{
  width:1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  line-height: 0;
  opacity: 0.7;
  .learning-lines-detail-phase-notification{
    top:4px;
    right: 4px;
  }

  span[class^='i']{
    width: 0.75rem;
    height: 0.75rem;
    display: block;
  }
}

.assignment-view {
  .course-task {
    & + .course-task {
      margin-top: 4rem;
    }
  }
}

.assignmentdetail-actions {
  padding-top: 2rem;
}

.course-overview-link {
  font-size: 0.75rem;
  cursor: pointer;
  opacity: 0.8;
  display: block;

  &:hover {
    text-decoration: underline;
  }

  margin-bottom: 2rem;
}


.course-task__component {


  @include media-breakpoint-up(md) {
    padding: 1rem 0 1rem 1rem;
    border-left: solid 2px $gray-300;
  }


  &.state--submitted {
    border-color: $blue;
  }

  &.state--rejected {
    border-color: $danger;
  }

  .state--changes_requested {
    border-color: $indicator-warning-color;
  }

  &.state--empty {
    border-color: $gray-300;
  }

  &.state--accepted {
    border-color: $success;
  }
}

.course-task__order {
  padding-bottom: 0.25rem;
  opacity: 0.8;
  font-size: 0.7rem;
}

.learningLineTask, .learninggroup-trainees {
  .badge-pill {
    padding: 0.25rem;
    font-size: 0.7625rem;
    border: solid 1px $font-color-default;
    opacity: 0.8;
    border-radius: $border-radius;
    display: inline-block;
    margin: .25rem;
    background-color: transparent;

    &.active {
      background-color: #caefcf;
    }
  }
}


.learningline-swap-layout {
  .learning-line {
    animation: fadeIn 0.3s forwards;
  }

  .assignment-view {
    //pointer-events: none;
    animation: fadeOut 0.3s forwards;
  }

  &.is-opened {
    .learning-line {
      pointer-events: none;
      animation: fadeOut 0.3s forwards;
      display: none;
    }

    .assignment-view {
      animation: fadeIn 0.3s forwards;
    }
  }
}

.assignment-view .assignment-view__assignment > *,
{
  animation: fadeIn 0.5s forwards;
}