@import '~bootstrap/scss/mixins/breakpoints';
@import '../styles/variables';

.filter-options {
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: right;
  }

  .carousel-item .btn {
    justify-content: center;
  }
}
