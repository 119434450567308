.notification-store {
  position: relative;
  margin-left: 24px;

  .warning + img {
    width: 22px;
    padding-bottom: 4px;
    cursor: pointer;
  }
  &.hasMessage {
    span.warning {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      right: -10px;
      top: 10px;
      background-color: var(--primary-color);
      border-radius: 40px;

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M12 19V6M5 12l7-7 7 7'/%3E%3C/svg%3E");
      background-size: 75%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
