@import 'lib/styles/variables.scss';

.training-templates-overview {
  .training-template {
    &:hover {
      background-color: lighten($info, 40%) !important;
      cursor: pointer;
    }
  }
}

.training-template-exercises {
  width: 100%;

  td {
    padding: $spacing-xs $spacing-sm;
    vertical-align: top;
  }
}
