@import 'lib/styles/variables.scss';
@import 'lib/styles/layout.scss';

$lazure: #71e7b9;
$lpurple: #cd8ed8;

.helparticles {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  //margin: 0 -36px;
  //background-color: $gray-200;
  align-items: center;
}
.pagedescription {
  margin-bottom: 3rem;

  ol,
  ul {
    margin-bottom: 0.5rem;
    padding-left: 1.2em;
    li::marker {
      opacity: 0.5;
      font-weight: 900;
      font-size: 0.75em;
      margin-right: 0.2em;

      color: $colors-branding-secondary;
    }
  }
  ol li {
    padding-left: 0.25em;
  }

  p {
    margin-bottom: 0.5em;
  }
  .intro {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.7;
    opacity: 0.9;
    //background-color: $ttvViolet;
  }
  span.primary {
    color: $colors-branding-primary;
  }
  span.secondary {
    color: $colors-branding-secondary;
  }

  ul.checks-large {
    padding: 0;
    b,
    strong {
      display: block;
      margin-bottom: 0.25em;
    }
    li {
      background-color: #d9f5f0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100px' width='100px' fill='%2328e89e' data-name='Layer 1' viewBox='0 0 100 100' x='0px' y='0px'%3E%3Ctitle%3EMedia Player%3C/title%3E%3Cpath d='M46.16,8.14a42,42,0,1,0,45.7,45.7A42.12,42.12,0,0,0,46.16,8.14ZM72.59,40.53,47.7,65.16a4,4,0,0,1-5.58,0L27.45,51.15A4,4,0,0,1,33,45.37L44.84,56.73,67,34.84a4,4,0,0,1,5.62,5.69Z'/%3E%3C/svg%3E");
      background-position: 8px;
      background-repeat: no-repeat;
      background-size: auto 1.8rem;
      border-radius: 7px;
      margin-bottom: 12px;
      margin-right: 12px;
      padding: 0.5rem 0.8rem 0.5rem 2.7rem;
      display: inline-block;
    }
  }

  ul.checks {
    list-style-type: none;
    padding: 0;
    b,
    strong {
      display: block;
      margin-bottom: 0.25em;
    }
    li {
      background-image: url("data:image/svg+xml,%3Csvg xmlns:dc='http://purl.org/dc/elements/1.1/' xmlns:cc='http://creativecommons.org/ns%23' xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23' xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' xmlns:sodipodi='http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd' xmlns:inkscape='http://www.inkscape.org/namespaces/inkscape' height='100px' width='100px' fill='%2328e89e' version='1.1' x='0px' y='0px' viewBox='0 0 100 100'%3E%3Cg transform='translate(0,-952.36218)'%3E%3Cpath style='text-indent:0;text-transform:none;direction:ltr;block-progression:tb;baseline-shift:baseline;color:%23000000;enable-background:accumulate;' d='m 72.852772,980.33489 a 6.0006,6.0006 0 0 0 -4.125,1.8125 c -9.49468,9.5152 -18.16337,18.84531 -27.28125,28.15621 l -10.71875,-8.5937 a 6.002929,6.002929 0 1 0 -7.5,9.375 l 15,12 a 6.0006,6.0006 0 0 0 8,-0.4688 c 10.79003,-10.8132 20.45671,-21.4338 31,-32.00001 a 6.0006,6.0006 0 0 0 -4.375,-10.2812 z' fill='%2328e89e' fill-opacity='1' marker='none' visibility='visible' display='inline' overflow='visible'/%3E%3C/g%3E%3C/svg%3E");
      background-position: 0 4px;
      background-repeat: no-repeat;
      background-size: auto 28px;
      padding-left: 2.2rem;
    }
  }
}
ol {
  list-style-type: auto;
}

.page-header-knowledge-base {
  a:hover {
    text-decoration: none;
    span:not(.badge) {
      text-decoration: underline;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-left: auto;
  }
  a.hidden-xs {
    @include media-breakpoint-down(xs) {
      display: none !important;
    }
  }
}
.page-header-knowledge-base + a {
  margin-left: 0.5rem;
}

.helparticle--teaser {
  text-decoration: none;
  color: inherit;
  background-color: white;
  border-bottom: solid 1px $gray-300;
  //box-shadow: $overview-item-shadow;

  margin-bottom: 0.5rem;
  padding: 0.5rem 0.5rem;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  width: 100%;
  //max-width: 450px;

  &:hover {
    text-decoration: none;
    h3 {
      color: $colors-branding-secondary;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 1em;
    margin-bottom: 0.25rem;
    color: #4b4a4a;
  }
  p {
    line-height: 1.2;
    font-size: 0.875em;
    margin: 0;
  }
  .helparticle__label {
    font-size: 11px;
    line-height: 0;
    text-shadow: 0 1px 0.1px rgba(0, 0, 0, 0.2);
    padding: 2px 8px;
    border-radius: 7px;
    //background-color: $gray-600;
    color: white;
    white-space: nowrap;
  }
}

.helparticle__label.label--tutorial_video {
  background-color: $lazure;
}
.helparticle__label.label--how_to {
  background-color: $lpurple;
}

.helparticles-actions {
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}
.helpmodal--wrapper {
  display: flex;
  flex-direction: column;
  .modal-header {
    margin: 0;
  }
  justify-content: flex-start;
}
