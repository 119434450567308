@import 'lib/styles/variables.scss';

.radio {
  width: 12px;
  height: 12px;
  margin-right: $spacing-sm;
}


button:disabled, button[disabled] {
  color: $gray-500;
  background-color: $gray-300!important;
  .radio{
    opacity: 0.5;
  }
}
