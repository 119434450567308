.efficiency-label {
  &.success-high {
    .background-rect-filler {
      fill: #0fcf84;
    }
  }
  &.success-mid {
    .background-rect-filler {
      fill: #87e7c2;
    }
  }
  &.failure-high {
    .background-rect-filler {
      fill: #db4b4b;
    }
  }
  &.failure-mid {
    .background-rect-filler {
      fill: #f4c4c4;
    }
  }
  &.warning {
    .background-rect-filler {
      fill: #dfbb60;
    }
  }
}
