@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';

$table-action-height: 50px;
.ka-cell{
  height:1px; // Force height 100% to work inside tablecell
}
.ka {
  max-height: calc(100vh - 200px);
  &-table-wrapper {
    padding-bottom: 200px;

  }
  &-input {
    background-color: white;
    padding: 3px 8px;
    font-size: 14px;
  }

  .edit-string .ka-cell-text{
    display: block;
    height: 100%;
    padding: 7px;
    outline: dashed 2px transparent;
    transition: outline-color 0.2s ease-in-out;
    border-radius: 7px;
    position: relative;

    &:hover:after{ // Editable indicator icon
      content: '';
      width:30px;
      height:30px;
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      left:100%;
      opacity: 0;
      border-radius:  0 7px 7px 0;
      background-color: transparentize(#ffa12e,0.5);
      background-size: 14px;
      transition: opacity 0.2s ease-in-out;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='33' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34'%3E%3C/path%3E%3Cpolygon points='18 2 22 6 12 16 8 16 8 12 18 2'%3E%3C/polygon%3E%3C/svg%3E");


    }
  }
  .edit-string:hover{ // Editable indicator highlight
    .ka-cell-text{
      &:hover{
        outline-color: transparentize(#ffa12e,0.5);
        cursor: pointer;
      }
      &:after{
        opacity: 1;
      }
    }
  }
}

.ka-thead-fixed{
  z-index: 1;
}

.delete {
  display: block;
  position: relative;
  background-color: #ccc;
  width: 30px;
  cursor: pointer;
  height: 30px;
  border-radius: 100px;

  &:hover {
    background-color: $bg-dark;
  }
}

.delete::before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    left: 5px;
    top: 5px;
    height: 20px;
    background: url('../../../img/icons/delete_white.svg');    
}

.add-actions {
  transition: width .5s;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.9) 17%, rgba(255,255,255,.9) 100%);  
  .buttons {
    width: 180px;    
    display: block;
    text-align: center;    
  }

  // Don't show column for existng rows
  .existing-row & {
    display: none;
  }
}

th.add-actions 
 {
  background: transparent;
}

.delete-action {
  padding-left: 0;
}

.actions {
  &:after{ // Clearfix
    content: '';
    clear: both;
    display: table;
  }
  .spinner {
    margin: 15px 0 0 0;
  }
}

.dataStatusText {
  font-style: italic;
  color: $bg-dark;
  line-height: $table-action-height;
  font-size: .9rem;
}

.new-row td{ // Add extra padding to row when adding new.
  padding-top: 2rem;
  padding-bottom: 2rem;
}