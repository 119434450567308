@import './variables';

$badge-size: 22px;
$badge-font-size: 14px;

.badge {
  display: inline-flex;
  width: $badge-size;
  height: $badge-size;
  justify-content: center;
  align-items: center;
  border: 1px solid $bg-normal;
  border-radius: $border-radius-default;
  background-color: $bg-normal;
  font-weight: normal;
  font-size: $badge-font-size;
  line-height: $badge-size - 2px;
  color: $font-color-contrasting;
}

.unread-badge {
  position: absolute;
  width: 30px;
  height: 30px;
  top: -75%;
  right: -75%;
  line-height: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  color: white;
  background-color: red;
  transform: scale(0.75);
}