@import '~bootstrap/scss/mixins/breakpoints';
@import 'lib/styles/variables';

.match-video-actions {
  margin-top: $spacing-md;
  margin-bottom: $spacing-md;

  .filter-options {
    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }
}

