@import 'lib/styles/variables';

.handball-keepers {
  position: absolute;
  top: 80px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  left: 20px;
  right: 20px;

  &.play-direction-rtl {
    flex-direction: row-reverse;
  }
}

.handball-keeper-button {
  background-color: $bg-light;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $font-color-default;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }
}

// handball

.keeper-selection {
  display: grid;
  gap: 15px;
  grid-auto-rows: 1fr;
  max-height: 75vh;
  overflow-y: scroll;

  .keeper {
    display: grid;
    padding: 0.5rem;
    grid-gap: 1rem;
    grid-template-columns: 2rem;
    grid-auto-flow: column;
    border-radius: 10px;
    border: 1px solid #555;

    .player-number {
      text-align: center;
    }
    .player-fullname {
      text-align: left;
    }
  }
}
