@import '../styles/variables';
@import '../styles/bootstrap/bootstrap-grid';

@keyframes flash {
  0% {
    background: lightblue;
  }
  100% {
    background: default;
  }
}

.chatbox__wrapper {
  display: flex;
  border: solid 1px $gray-400;
  background-color: $gray-100;
  position: relative;
  border-radius: $border-radius;

  padding-bottom: 61px;
  max-height: 550px;
  min-height: 450px;

  background-image: url("data:image/svg+xml,%3Csvg id='10015.io' viewBox='0 0 52 52' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpattern id='pattern' x='0' y='0' width='39' height='39' patternUnits='userSpaceOnUse' patternTransform='translate(13, 13) rotate(0) skewX(0)'%3E%3Csvg width='26' height='26' viewBox='0 0 100 100'%3E%3Cg fill='%23eeeeff' opacity='1'%3E%3Cpath d='M100 50L50 0L0 50L50 100L100 50ZM50 33.4325L66.5673 50L50 66.5673L33.4327 50L50 33.4325Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E%3C/pattern%3E%3C/defs%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='%23f4f4f4'%3E%3C/rect%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='url(%23pattern)'%3E%3C/rect%3E%3C/svg%3E");
  background-size: 12px;
}
.chatbox__conversations {
  overflow-y: scroll;
  padding: 2rem;
  width: 100%;
  @include media-breakpoint-down(sm) {
    padding: 6px;
  }
}

.conversation {
  padding-bottom: 14px;
  display: flex;
  &.own {
    flex-direction: row-reverse;
  }

  .chatbox--small &:not(.own) {
    padding-right: 20px;
  }
  .chatbox--small &.own {
    padding-left: 20px;
  }
}

.conversation__user {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 100%;
  outline: solid 1px $gray-200;
  margin-right: 8px;

  img {
    width: 100%;
    border-radius: 100px;
  }
  .own & {
    margin-left: 8px;
    margin-right: 0;
  }
  .chatbox--small & {
    display: none;
  }
  box-shadow: 0px 0.1px 0.5px rgba(0, 0, 0, 0.02),
    0.1px 0.1px 1.1px rgba(0, 0, 0, 0.028),
    0.1px 0.3px 2.1px rgba(0, 0, 0, 0.035),
    0.2px 0.4px 3.8px rgba(0, 0, 0, 0.042),
    0.4px 0.8px 7.1px rgba(0, 0, 0, 0.05), 1px 2px 17px rgba(0, 0, 0, 0.07);
}

.conversation__message {
  border: solid 1px $gray-200;
  padding: 6px 12px;
  max-width: 80%;
  white-space: pre-line;
  word-break: break-word;
  border-radius: 0 $border-radius $border-radius $border-radius;
  background-color: white;

  min-width: 175px;

  line-height: 1.3;
  & + & {
    margin-top: 6px;
    border-radius: $border-radius;
  }
  .chatbox--small & {
    max-width: 100%;
  }
  box-shadow: 0px 0.1px 0.5px rgba(0, 0, 0, 0.02),
    0.1px 0.1px 1.1px rgba(0, 0, 0, 0.028),
    0.1px 0.3px 2.1px rgba(0, 0, 0, 0.035),
    0.2px 0.4px 3.8px rgba(0, 0, 0, 0.042),
    0.4px 0.8px 7.1px rgba(0, 0, 0, 0.05), 1px 2px 17px rgba(0, 0, 0, 0.07);

  .own & {
    background-color: #e3fff1;
  }
  &.new {
    animation: flash 1s ease-out;
  }
}
.conversation__messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 300;
  width: 100%;
  .own & {
    align-items: flex-end;
  }

  label {
    display: block;
    font-size: 11px;
    margin: 0;
    margin-bottom: 3px;
  }
}

.chatbox__input {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 12px;
  border-top: solid 1px $gray-200;
  background-color: $gray-100;
  border-radius: 0 0 $border-radius $border-radius;
  display: flex;
  align-items: flex-end;
  > div {
    width: 100%;
  }

  textarea {
    resize: none;
    overflow-y: auto;
    height: 36px;
    width: 100%;
    border-radius: $border-radius;
  }
  button {
    border: 0;
    background-color: transparent;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.75;
    transition: all 0.3s ease;
    &:focus {
      outline: 0;
    }
    &.disabled {
      opacity: 0.5;
    }
    &:not(.disabled):hover {
      opacity: 1;
    }
  }
}

.chatbox__wrapper .empty {
  width: 100%;
  color: $gray-700;
}

.conversation__meta {
  display: flex;
  align-content: center;
  align-items: center;
  .own & {
    justify-content: flex-end;
  }
}

.seenBy {
  width: 14px;
  //height: 100%;
  display: block;
  position: relative;
  svg {
    position: absolute;
    //fill: $gray-500;
    top: -6px;
    right: -6px;
    //transform: translateY(-50%);
  }
  &.all svg {
    stroke: $blue;
  }
  .seenBy__counter {
    font-size: 9px;
    color: $gray-600;
  }
  .seen__list {
    //display: none;
    opacity: 0;
    position: absolute;
    bottom: 30px;
    right: 0px;
    pointer-events: none;

    transition: opacity 0.5s ease-out;
    display: block;
    position: absolute;
    bottom: 0;
    background-color: white;
    border-radius: $border-radius;
    padding: 6px 12px;
    width: auto;

    box-shadow: 0px 0.1px 0.5px rgba(0, 0, 0, 0.02),
      0.1px 0.1px 1.1px rgba(0, 0, 0, 0.028),
      0.1px 0.3px 2.1px rgba(0, 0, 0, 0.035),
      0.2px 0.4px 3.8px rgba(0, 0, 0, 0.042),
      0.4px 0.8px 7.1px rgba(0, 0, 0, 0.05), 1px 2px 17px rgba(0, 0, 0, 0.07);

    ul {
      padding: 0;
      display: block;
      margin: 0;
      white-space: nowrap;
      font-size: 11px;
      li:before {
      }
    }
  }

  &:hover .seen__list {
    opacity: 1;
  }
}

.button--mention {
  //padding: 12px;
  font-size: 18px;
  color: $gray-600;
  font-weight: bold;
}

.conversation__timestamp {
  font-size: 0.75rem;
  padding-top: 5px;
  line-height: 1;
  color: $gray-500;
  text-align: right;
  display: inline-block;
}

.conversation__mention {
  font-weight: 400;
  font-style: italic;
}

.conversation__mention--own {
}
