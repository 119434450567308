@import 'lib/styles/variables.scss';

.license-status {
  &-active {

  }
  &-almost-inactive {
    background-color: orange;
  }
  &-inactive {
    background-color: red;
    font-weight: bold;
  }
  &-none {
    background-color: red;
  }
}

.tenant-overview {
  .tenant {
    &:hover {
      background-color: lighten($info, 40%) !important;
      cursor: pointer;
    }
  }
}
