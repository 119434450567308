/**
*** SIMPLE GRID
*** (C) CAS VAN GRUNSVEN/CREATIVE CONNECT 2017
**/

/* UNIVERSAL */

html {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  height: auto;
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
  color: #343548;
  line-height: 1.5;
}

.height-100vh {
  height: 100vh;
}

div,
input {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* TYPOGRAPHY */

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-heavy {
  font-weight: 700;
}

/* POSITIONING */

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.text-justify {
  text-align: justify;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.inline-block {
  display: inline-block;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-top-50 {
  margin-top: 50px;
}

/* ==== GRID SYSTEM ==== */

.flex-content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  z-index: 1;
  top: 0;
  left: 0;
}

.break-after::after {
  display: block;
  content: '';
  clear: both;
}

.hidden-sm,
.hidden-md,
.hidden-xs,
.hidden-lg {
  display: block;
}

.show-sm,
.show-md,
.show-xs,
.show-lg {
  display: none;
}

/* EXTRA STYLES */
.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.relative {
  position: relative;
}

input {
  border: none;
}

input:focus {
  outline: none;
}

.overflow-y {
  overflow-y: scroll;
  height: 100%;
}

.overflow-x {
  overflow-x: scroll;
  width: 100%;
}

.flex-wrapper {
  display: flex;
  flex: 1 0 auto;
}

.flex-container {
  display: flex;
  flex-flow: column wrap;
  height: 100vh;
  width: 100%;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
  .hidden-lg {
    display: none;
  }
  .show-lg {
    display: block;
  }
  .flex-content.noflex-lg {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    -ms-flex-wrap: none;
    flex-wrap: none;
    -ms-flex-align: none;
    align-items: none;
    -ms-flex-pack: none;
    justify-content: none;
    z-index: 1;
  }
}

@media screen and (max-width: 950px) {
  .hidden-md {
    display: none;
  }
  .show-md {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .hidden-sm {
    display: none;
  }
  .show-sm {
    display: block;
  }
}

@media screen and (max-width: 570px) {
  .hidden-xs {
    display: none;
  }
  .show-xs {
    display: block;
  }
}


#webpack-dev-server-client-overlay {
  display: none
}