@import '~bootstrap/scss/mixins/breakpoints';
@import 'lib/styles/variables.scss';
@import 'lib/styles/typography.scss';
@import 'lib/styles/label.scss';

.learning-line {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &-filter-form {
    // Prevents scrolling / jumping when Select component opens
    min-height: 450px;
  }
}

.learning-phase {
  width: calc(25% - #{$spacing-md});
  margin-right: $spacing-md;

  @include media-breakpoint-down(md) {
    margin-right: 0;
    width: unset;
  }

  &:last-child {
    margin-right: 0;
  }

  &-complete,
  &-disabled {
    .learning-indicators-group {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &-disabled {
    .progress {
      background-color: $gray-300;
    }
  }

  .learning-indicators-group {
    background-color: $bg-light;

    span.small {
      font-size: 9px;
      position: absolute;
      top: $card-spacer-x;
      right: $card-spacer-x;
      color: $gray-500;
      line-height: 1;
    }
  }

  &-progress {
    position: relative;

    &-title {
      position: absolute;
      width: 100%;
      color: $font-color-contrasting;
      line-height: $progress-height;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 $card-spacer-x;
    }
  }
}

.learning-indicator {
  margin-bottom: $spacing-md;

  &:last-child {
    margin-bottom: 0;
  }

  &.btn-group {
    flex-direction: column;
    width: 100%;
    .i-add {
      opacity: 0.6;
    }
    &:hover .i-add {
      opacity: 1;
    }
  }

  &-header {
    $bg-color: #363636;

    display: flex;
    padding: $label-spacing-sm $label-spacing-md;
    background-color: $bg-color;
    color: $font-color-contrasting;
    border-style: solid;
    border-color: $bg-color;
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;
    border-width: 1px 1px 0px 1px;
    font-size: $label-font-size-sm;

    &-title,
    &-icon {
      align-items: center;
    }
    &-title {
      flex-grow: 1;
    }
    &-icon i {
      width: $label-font-size-sm;
      height: $label-font-size-sm;
    }

    & + .label {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &-fragment-groups {
    display: flex;
    align-items: center;
  }
  &-fragment-group {
    $border-size: 4px;

    flex: 1 0 24px;
    padding: $spacing-sm $spacing-md calc(#{$spacing-sm} - 2px);
    border: 0;
    text-align: center;
    background-color: white;
    border: 1px solid $gray-500;
    border-left-width: 1px;
    border-right-width: 0;
    border-bottom: 0 solid white;
    margin-bottom: $border-size;

    &:first-child {
      border-bottom-left-radius: $border-radius-default;
    }
    &:last-child {
      border-bottom-right-radius: $border-radius-default;
      border-right-width: 1px;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      position: relative;
      filter: brightness(95%);
    }

    &.rating-grey,
    &.rating-green,
    &.rating-orange,
    &.rating-red {
      background-color: white;
    }

    &.rating-grey {
      // border-bottom-color: $gray-500;
      box-shadow: 0px $border-size 0 0 $gray-500;
      // background-color: lighten($gray-500, 25%);
      background-image: linear-gradient(#fff, lighten($gray-500, 25%));
    }
    &.rating-green {
      // border-bottom-color: $teal;
      box-shadow: 0px $border-size 0 0 $teal;
      // background-color: lighten($green, 50%);
      background-image: linear-gradient(#fff, lighten($green, 50%));
    }
    &.rating-orange {
      // border-bottom-color: $orange;
      box-shadow: 0px $border-size 0 0 $orange;
      // background-color: lighten($orange, 50%);
      background-image: linear-gradient(#fff, lighten($orange, 50%));
    }
    &.rating-red {
      // border-bottom-color: $red;
      box-shadow: 0px $border-size 0 0 $red;
      // background-color: lighten($red, 50%);
      background-image: linear-gradient(#fff, lighten($red, 50%));
    }
  }
  &.unmarked {
    margin-top: 40px;
    > .label {
      background-color: $gray-600;
      color: white;
    }
  }

  &-actions {
    display: flex;
    width: 100%;

    > .btn,
    > .filtered {
      border-top: 0;
      font-weight: normal;
      border-radius: 0;

      &:first-child {
        border-bottom-left-radius: $border-radius-default;
      }
      &:last-child {
        border-bottom-right-radius: $border-radius-default;
      }
    }

    > .btn {
      flex-grow: 5;
    }
    > .filtered {
      display: flex;
      flex: 1 0 24px;
      justify-content: center;
      align-items: center;
      background-color: $secondary;
    }
  }

  .label {
    display: flex;
    margin-right: 0;
    user-select: none;
    font-size: $font-size-sm;
    // background-color: transparent;
    &.is-loading {
      pointer-events: none;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.learning-line-title {
  display: flex;
  margin: $spacing-xl 0;
  padding: $spacing-md $spacing-lg;
  background-color: $bg-dark;
  color: $font-color-contrasting;
  border-radius: $border-radius-default;
  align-items: center;

  > h2 {
    flex-grow: 1;
    margin: 0;
    font-size: $font-size-lg;
  }
}

.learning-lines-detail {
  color: $font-color-light;
  font-size: 1rem;
  width: 100%;

  > div {
    padding-bottom: 30px; //2*grid
  }

  &-phases {
    margin-top: $spacing-lg;
  }

  &-phase {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-md;

    &-title {
      flex: 0 0 65%;
      font-size: 0.875rem;
      @extend .text-overflow;
    }

    &-progress {
      width: 100%;
      margin-left: 12px;
    }
  }

  .progress {
    height: $spacing-lg;
    background-color: $gray-300;
    margin-bottom: 0;

    .progress-bar {
      border-radius: $border-radius-default;
    }
  }

  .card {
    background-color: white;
    box-shadow: 0px 6.3px 1.8px -8px rgba(0, 0, 0, 0.02),
      0px 11px 4.3px -8px rgba(0, 0, 0, 0.028),
      0px 14.3px 8.1px -8px rgba(0, 0, 0, 0.035),
      0px 16.6px 14.5px -8px rgba(0, 0, 0, 0.042),
      0px 19.4px 27.2px -8px rgba(0, 0, 0, 0.05),
      0px 32px 65px -8px rgba(0, 0, 0, 0.07);
    height: 100%;
    position: relative;
    margin-bottom: 0;
    border: 0;
    > .card-body {
    }
    > .card-body--title {
      padding-bottom: 0;
      flex-grow: 0;
    }
  }
  h5 {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
    & + a {
      margin-top: 1rem;
    }
  }
  .phase-medal {
    position: absolute;
    right: calc(1.125rem + 12px);
    top: 1.125rem;
  }
  .card-body--title:has(.phase-medal) {
    padding-right: calc(1.125rem + 45px);
  }

  p {
    font-size: inherit;
  }
  a.btn {
    margin-bottom: 1em;
  }
}

.show-development-plan-video-fragment {
  max-width: 800px;

  .dpvf-header,
  .dpvf-video {
    margin-bottom: $spacing-xl;
  }
}

.pagination {
  display: inline-flex;
  align-items: center;
  line-height: 100%;

  &-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0 $spacing-md;
    border-radius: 34px;
    background-color: $secondary;
    color: $font-color-contrasting;

    &.active {
      font-weight: $font-weight-bold;
    }
  }
}

.pagination {
  .pagination-item {
    &:not(.active) {
      opacity: 0.5;
      font-weight: $font-weight-base;
    }
  }
}
.learningline-messages-unread {
  position: relative;
  .counter {
    position: absolute;
    top: 0px;
    right: 8px;
    padding: 2px 4px;
    background-color: red;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 9px;
    line-height: 1.3;
    font-weight: bold;
  }
}

.page-title--group .right,
.page-title--group .left {
  padding: 6px 22px;
  border-radius: $border-radius;
  background-color: $font-color-default;
  margin-top: 12px;
}
.page-title--group .learningline__filters {
  padding: 6px 22px;
  border-radius: $border-radius;
  background-color: $font-color-default;
}

.fixed-bottom-right {
  position: fixed;
  right: 5%;
  bottom: 20px;
  z-index: 2;
}

.learning-indicator {
  &:not(:last-child) {
    border-bottom: solid 1px #dcdcdc;
  }
}

$indicator-warning-color: #ff9f69;

$version-padding-x: 12px;
$version-padding-y: 6px;

.learningline-status-indicator {
  padding: 12px 12px 12px 18px;
  border-radius: $border-radius;
  align-items: center;
  align-content: center;
  margin-bottom: 6px;

  .learning-indicator__top {
    display: flex;
    padding-bottom: 6px;
  }

  position: relative;
  color: $font-color-default;

  &:before {
    content: '';
    width: 6px;
    height: 100%;
    border-radius: $border-radius;
    position: absolute;
    left: -6px;
    top: 0;
    background-color: transparent;
    transition: background-color 0.3s ease-in;
  }

  &.status--done:before,
  &.status--accepted:before {
    background-color: lighten($green, 40%);
  }
  &.status--warning:before,
  &.status--changes_requested:before {
    background-color: lighten($indicator-warning-color, 10%);
  }
  &.status--submitted:before {
    background-color: lighten($blue, 40%);
  }
  &.status--rejected:before {
    background-color: lighten($red, 40%);
  }

  .learning-indicator__children {
    padding-right: 22px;

    font-weight: 500;
    line-height: 1.5;

    .small {
      display: block;
      font-size: 11px;
      color: #ccc;
    }
  }

  .icon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin: 0;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    &.clickable {
      background-color: white;
      border: solid 2px white;
      border-radius: 12px;
      border: solid 1px $font-color-default;
      &:hover {
        cursor: pointer;
      }
      &:hover {
        border-color: rgba(47, 129, 222, 0.56);
      }
    }

    transition: border 0.2s ease-in;
    > * {
      line-height: 1;
    }
    i,
    span[class^='i'] {
      width: 16px;
      height: 16px;
    }
  }
  &.status--done .icon.clickable,
  &.status--accepted .icon.clickable {
    background-color: $green;
    border-color: $green;
    color: $green;
  }
  &.status--changes_requested .icon.clickable {
    background-color: lighten($indicator-warning-color, 10%);
    border-color: lighten($indicator-warning-color, 10%);
    color: $indicator-warning-color;
  }
  &.status--rejected .icon.clickable {
    background-color: lighten($red, 10%);
    border-color: lighten($red, 10%);
    color: $red;
  }
  &.status--submitted .icon.clickable {
    color: $blue;
  }
  &.show-notification .icon.clickable {
    position: relative;
    &:after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      top: -4px;
      right: -4px;
      background-color: currentcolor;
      border-radius: 100px;
      border: solid 1px white;
    }
  }
}

.learningline-status-indicator__buttongroup {
  display: flex;
  position: relative;

  padding-top: 6px;

  $border-size: 1px;
  .learningline-status-indicator__button {
    border-radius: $border-radius;
    padding: 12px 24px;
    border: 0;

    font-size: 0.875rem;
    line-height: 1;

    @include media-breakpoint-down(sm) {
      min-width: 25%;
    }

    border: solid 2px transparent;

    &:hover {
      border-color: rgba(47, 129, 222, 0.56);
    }

    &.rating-grey {
      background-color: #c5dbf7;
      &:hover {
        background-color: lighten(#c5dbf7, 10%);
      }
    }
    &.rating-green {
      background-color: lighten($green, 40%);

      &:hover {
        background-color: lighten($green, 50%);
      }
    }
    &.rating-orange {
      background-color: lighten($orange, 40%);
      &:hover {
        background-color: lighten($orange, 50%);
      }
    }
    &.rating-red {
      background-color: lighten($red, 40%);
      &:hover {
        background-color: lighten($red, 50%);
      }
    }
  }
}
.learningline-status-indicator__button {
  & + & {
    margin-left: 6px;
  }
}
.learning-indicator__examplebutton {
  font-size: 12px;
  margin-top: 6px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  line-height: 1;
  align-items: center !important;
  align-content: center !important;
  i {
    margin: 0 6px 0 0;
  }
  &:hover,
  &:hover span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.learning-line-card-wrapper {
  min-width: 290px;
  max-width: 520px;
  padding: 0 15px;
  //width: 100%;
  //@include media-breakpoint-up(md) {
  //  width: 50%;
  //}
  //@include media-breakpoint-up(lg) {
  //  width: 33%;
  //}
  //@include media-breakpoint-up(xl) {
  //  width: 25%;
  //}
  width: 100%;
}

.has-tabs .leaningLineTask {
  padding-top: 36px;
}

// Task submissions
.version-sportingevent {
  border-radius: $border-radius;
  background-color: $gray-100;
  border: solid 1px $gray-300;

  .version-row {
    display: flex;
    align-items: center;
    align-content: center;
  }
  .clickable:hover {
    background-color: $gray-300;
  }

  & + & {
    margin-top: 12px;
  }
  .version__state {
    margin-left: auto;
  }
  .version-row__meta {
    transition: background-color 0.2s ease-in;
    width: 100%;
    padding: $version-padding-y $version-padding-x;
    flex-wrap: wrap;
    & > div:not(:last-child) {
      margin-right: $version-padding-x;
    }
    @include media-breakpoint-down(sm) {
      .date {
        display: none;
      }
    }
  }
}

.version__document {
  border-radius: $border-radius;
  background-color: $gray-100;
  border: solid 1px $gray-300;
  padding: $version-padding-y $version-padding-x;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .version-feedback {
    margin-top: $version-padding-y * 2;
  }
  & + & {
    margin-top: $version-padding-y;
  }

  .version__state {
    margin-left: auto;
    display: inline-block;
  }

  &.submitted {
    border-bottom: 0;
    border-radius: $border-radius $border-radius 0 0;
  }

  .version-file-row {
    display: flex;
    line-height: 1.1;
    width: 100%;
    align-items: center;
    align-content: center;
    .version-file {
      width: auto;
      overflow: hidden;
    }
    .version__state {
      flex-grow: 0;
      flex-shrink: 0;
    }
    .btn {
      width: 100%;
      overflow: hidden;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      i {
        flex-shrink: 0;
      }
      span {
        width: auto;
      }
    }
  }
  .version-comment-row {
    border-top: solid 1px $gray-300;
  }
}

.version__comment {
  display: flex;
  width: 100%;
  margin-top: 6px;

  .user-icon {
    margin-right: 0.5em;
    border: solid 1px $gray-300;
  }
  blockquote {
    //font-style: italic;
    line-height: 1.1;
    margin: 0;
    padding: 6px 12px;
    background-color: $gray-200;
    border-radius: 0 $border-radius $border-radius $border-radius;
  }
  a.link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    color: $font-color-default;
  }
}
.version-comment-row {
  background-color: $gray-100;
  padding: $version-padding-y $version-padding-x;
  width: 100%;
  &:not(:last-child) {
    border-bottom: solid 1px $gray-300;
    margin-bottom: 12px;
  }
}

.version__comment.comment--own {
  text-align: left;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  .user-icon {
    margin-left: 0.5em;
    margin-right: 0;
    order: 1;
  }
  blockquote {
    text-align: right;
    background-color: #cfefdd;
    border-radius: $border-radius 0 $border-radius $border-radius;
  }
}

.version__comment.comment--own + .version__comment.comment--own,
.version__comment:not(.comment--own) + .version__comment:not(.comment--own) {
  // Hide icon in second message of same sender.
  .user-icon {
    opacity: 0;
  }
}

.version__state {
  padding: 4px 8px;
  color: white;
  background-color: black;
  border-radius: $border-radius;
  font-size: 12px;
}

.submitted .version__state {
  background-color: $blue;
}
.rejected .version__state {
  background-color: $danger;
}
.retracted .version__state {
  background-color: $yellow;
}
.accepted .version__state {
  background-color: $success;
}
.changes_requested .version__state {
  background-color: $indicator-warning-color;
}

.btn-mild-warning {
  background-color: $indicator-warning-color;
  color: white;
  &:hover {
    color: white;
  }
}

.archivedVersions {
  padding-top: 32px;
}
.version-actions {
  padding: 12px 24px;
  background-color: $gray-800;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 $border-radius $border-radius;
  background-color: $gray-100;
  border: solid 1px $gray-300;
  .btn + .btn {
    margin-left: 1em;
  }
}
.version-feedback {
  width: 100%;
  margin-bottom: 1em;
}

.version-video,
.version__video {
  // Reset player overlapping modal..
  position: relative;
  z-index: 0;
}
.version-video__titlebar {
  display: flex;
  padding: $version-padding-y $version-padding-x;
  background-color: $gray-100;
  border: solid 1px $gray-300;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  border-radius: $border-radius $border-radius 0 0;
}

.is-busy {
  pointer-events: none;
  opacity: 0.75;
  position: relative;
  .loading-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.processing-video {
  background-color: black;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  align-content: center;
  color: white;
  aspect-ratio: 16/9;
  p {
    margin-top: 25px;
    max-width: 25ch;
    text-align: center;
  }
}

.readingtask {
  display: block;
  svg {
    margin-right: 0.5em;
    stroke: $gray-400;
  }
  span {
    color: $gray-400;
    font-size: 13px;
  }
  &.readingtask--isread {
    svg {
      stroke: $success;
    }
    span {
    }
  }
}

.development-update .card .progress,
.development-update .card .progress .progress-bar {
  border-radius: 0;
}
.development-update .card .progress .progress-bar {
  border-radius: 0 $border-radius $border-radius 0;
  &[aria-valuenow='100'] {
    border-radius: 0;
  }
}

.learning-lines-detail-phase-notification,
.learning-line-card-notification {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: transparent;
  cursor: pointer;

  &.notification--submitted {
    background-color: $blue;
  }
  &.notification--rejected {
    background-color: $danger;
  }
  &.notification--changes_requested {
    background-color: $indicator-warning-color;
  }
}
.learning-line-card-notification {
  position: absolute;
  top: 1.25em;
  right: 1.25em;
  display: block;
  span[data-tip] {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.learning-lines-detail-phase-notification {
  position: absolute;
  margin-left: 12px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.learning-lines-detail-phase-title:has(.learning-lines-detail-phase-notification) {
  position: relative;
  padding-right: 16px;
}

.statustable {
  .__react_component_tooltip.show {
    opacity: 1;
  }

  th,
  td {
    padding: 1rem 2rem;
    line-height: 1.3;
  }
  border-radius: $border-radius;
  box-shadow: $overview-item-shadow;
  thead {
    th {
      background-color: $gray-200;
      font-weight: normal;
      color: $gray-800;
      vertical-align: bottom;
      &:first-child {
        border-top-left-radius: $border-radius;
      }
      &:last-child {
        border-top-right-radius: $border-radius;
      }
    }
    th + th {
      border-left: solid 1px $gray-400;
    }
  }
  tbody {
    td {
      padding: 0.75rem 2rem;
    }
    td + td {
      border-left: solid 1px $gray-400;
    }
    tr:nth-child(even) {
      td {
        background-color: #f6f6f6;
      }
    }
  }

  .trainee-details {
    white-space: nowrap;
  }
  .trainee-row.is-done:nth-child(even) td{
    background-color: #e9ffe7;
  }
  .trainee-row.is-done:nth-child(odd) td{
    background-color: #ddf1da;
  }

  .traineebutton {
    font-weight: bold;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .statust-indicators {
    display: flex;
  }

  .status-indicator + .status-indicator {
    margin-left: 0.5rem;
  }

  .status-indicator {
    cursor: pointer;

    .status:before {
      content: '';
      display: block;
      width: 1.25em;
      height: 1.25em;
      border-radius: 100px;
    }

    &:hover .status:before {
      transition: all 0.2s ease;
      opacity: 0.75;
    }

    .status--submitted:before {
      background-color: $blue;
    }
    .status--rejected:before {
      background-color: $danger;
    }
    .status--changes_requested:before {
      background-color: $indicator-warning-color;
    }
    .status--empty:before,
    .status--retracted:before {
      background-color: white;
      border: solid 1px $gray-500;
    }
    .status--accepted:before {
      background-color: $success;
    }
  }
}

.task-list {
  padding-left: 0;
  margin-top: 1rem;
  li {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 0.25rem 0;
    .status-indicator {
      font-size: 12px;
      flex-shrink: 0;
      margin-right: 0.5rem;
    }
  }
}

.text--submitted {
  color: $blue;
}
.text--rejected {
  color: $danger;
}
.text--changes_requested {
  color: $indicator-warning-color;
}
.text--empty {
  color: $gray-900;
}
.text--accepted {
  color: $success;
}

.tooltip-shadow {
  box-shadow: $overview-item-shadow;
}
