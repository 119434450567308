.react-datepicker-wrapper, .react-datepicker-wrapper .react-datepicker__input-container,
    .react-datepicker-wrapper input {
    width: 100%;
}

ul.react-datepicker__time-list {
    padding-left: 0px;
}


