.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.popup-timer {
  display: flex;
  align-items: center;
  align-content: center;
  max-width: 240px;
  padding: 0 20px 0 60px;
  position: relative;
  top: -60px;
  @media screen and (max-width: 767px) {
    top: -30px;
  }
  //font-size: 11px;
  line-height: 1.1;
  span {
    font-size: 11px;
    line-height: 1.1;
  }
  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
}

//@keyframes progress {
//  0% {
//    stroke-dasharray: 0 100;
//  }
//}

.circular-chart .circle {
  stroke: var(--primary-color);
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 300ms;
  animation-fill-mode: both;
}

.premium-banner-video {
  padding: 40px 60px 0 60px;
  @media screen and (max-width: 767px) {
    padding: 30px 10px 0 10px;
  }
}
