@import '../../lib/styles/variables';
@import '../../lib/styles/layout';

.highlight-view {
  h1 {
    @include media-breakpoint-down(sm) {
      padding: 0 $spacing-md;
    }
  }

  &-actions {
    padding: $spacing-md 0;
    justify-content: flex-end;
    display: flex;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      .btn {
        margin: 6px auto;
      }
    }
  }
}

.highlight-view .actions .btn + .btn {
  margin-left: 12px;
}

.videoTime {
  text-align: center;
  padding-bottom: 12px;
  font-size: 14px;
  opacity: 0.75;
}
.is-dragging .videoTime {
  opacity: 0;
}

.highlight-content {
  &-block {
    display: flex;
    flex-wrap: wrap;
    padding: $spacing-md $spacing-md $spacing-sm;
    border-top: 1px solid $bg-normal;
    line-height: 100%;

    &:first-child {
      border-top: none;
    }

    &-name,
    &-duration,
    &-actions,
    &-clip {
      display: flex;
      align-items: center;
      min-height: 28px;
    }

    &-actions > button {
      margin-left: $spacing-sm;
    }

    &-name {
      flex: 1;
    }

    &-clips {
      width: 100%;
    }

    &-clip {
      padding: $spacing-sm 0 $spacing-sm $spacing-sm;
      border-top: 1px solid $bg-normal;
      color: $font-color-light;

      &:first-child {
        border-top: none;
      }

      &-playing {
        display: none;
        flex: 0;
      }

      &.active &-playing {
        display: block;
        margin-left: -24px;
      }

      &-name {
        flex: 1;
      }

      &-actions {
        padding-left: $spacing-sm;

        svg {
          stroke: $font-color-light;
        }
      }
    }
  }

  .sidebar-footer {
    padding: $spacing-md;
    color: $font-color-light;

    .totals {
      color: $font-color-default;
    }
  }
}

.clickable {
  cursor: pointer;
}
.select-stream {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  > div {
    position: relative;
    width: 33%;
    //padding: 6px;
    max-width: 200px;
    cursor: pointer;
    border: solid 2px transparent;

    background-color: $gray-600;
    img {
      transition: all 0.2s ease;
      max-width: 100%;
      width: auto;
      opacity: 0.7;
      object-fit: cover;
    }
    &.selected {
      border: solid 2px #57ffa5;
      img {
        opacity: 1;
      }
    }
    &:hover:not(.selected) {
      img {
        opacity: 0.8;
      }
    }

    span {
      position: absolute;
      left: 12px;
      bottom: 12px;
      color: transparentize(white, 0.2);
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.75);
      text-transform: capitalize;
      font-weight: bold;
    }
    &.selected span {
      color: white;
    }
  }
}
$overview-item-shadow: 0px 0.2px 3.1px rgb(0 0 0 / 7%),
  0px 0.5px 7px rgb(0 0 0 / 6%), 0px 0.8px 12.2px rgb(0 0 0 / 6%),
  0px 1.3px 19.4px rgb(0 0 0 / 5%), 0px 1.9px 29.9px rgb(0 0 0 / 4%),
  0px 3px 46.7px rgb(0 0 0 / 4%), 0px 5px 77.6px rgb(0 0 0 / 3%),
  0px 10px 155px rgb(0 0 0 / 2%);
.highlight-view {
  .sidebar {
    flex: 0 0 550px;
    max-width: unset;
  }
}
.highlight-content-block-clips {
  padding-top: 12px;
}
.clip.draggableclip {
  border-radius: 7px;
  background-color: #ebebeb;
  line-height: 1;
  display: flex;
  transition: all 0.2s ease-out;
  overflow-y: hidden;

  box-shadow: $overview-item-shadow;

  .clip_m {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 24px;
  }

  h5 {
    font-weight: normal;
    font-size: 12px;
    color: #a6a6a6;
    margin: 0;
  }
  h4 {
    font-weight: normal;
    font-size: 16px;
    color: #343a40;
    margin: 4px 0 12px;
  }
  .small {
    font-size: 75%;
    vertical-align: baseline;
    font-weight: bold;
    margin-left: 4px;
  }
  .clip__overflow{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    //font-size: 12px;
  }

  .clip_duration {
    color: #a6a6a6;
    font-size: 11px;
    display: flex;
    align-items: center;
    align-content: center;
    svg {
      margin-right: 8px;
    }
  }
  .clip_angle {
    margin-left: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    text-transform: capitalize;
  }
  .clip_effect {
    margin-left: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    text-transform: capitalize;
  }
  .clip_l {
    width: 25%;
    flex-shrink: 0;
  }
  .clip_thumb {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    position: relative;
    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &.active .clip_thumb:after,
  .clip_thumb:hover:after {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    display: block;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 22px solid white;
    cursor: pointer;
  }

  .clip_r {
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }

  .clip_button {
    width: 54px;
    height: 100%;
    background-color: transparent;
    border: 0;
    outling: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.2s ease;
    padding: 6px 0;
    border-left: solid 1px #ccc;
    &:hover {
      background-color: #e7e7e7;
    }
    & + .clip_button {
      border-top: solid 1px #ccc;
    }
  }
  .clip_button-edit {
    //background-color: $colors-branding-secondary;
  }
  .clip_button-delete {
    //background-color: #828282;
  }
  .clip_button-copy {
    //background-color: $colors-branding-primary;
  }
}

.is-dragging .clip.draggableclip {
  transform: scale(1.1);
}

.clip-selection__clip .clip.draggableclip {
  flex-grow: 1;
  .clip_thumb:after {
    display: none !important;
  }
}

.template-card > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: $border-radius;
  font-size: 18px;
  color: white;
  text-align: center;
  background-size: cover;
  text-shadow: 0px 0px 3px black;
  outline: solid 2px transparent;
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.7) 60%
    );
    z-index: -1;
  }
  padding: 5% 5%;
  p {
    font-size: 13px;
    line-height: 1.1;
  }
  .checkbox__wrapper {
    position: absolute;
    left: 0.25em;
    top: 0.25em;
    pointer-events: none;
    .input-checkbox {
      padding: 0;
    }
    .input-checkbox input[type='checkbox'] + label:before {
      width: 16px;
      height: 16px;
      border-radius: 3px;
    }
  }
}
.template-card.disabled > div {
  opacity: 0.5;
}

.template-card.selected > div {
  outline: solid 2px $success;
}

// Responsive fixes.
.highlight-view .main.video-player-container {
  @include media-breakpoint-down(md) {
    width: 100%;
    margin: 0;
  }
}

.highlight-view.layout-with-sidebar {
  padding: 0;
}

.emptyclips {
  padding: 24px 12px;
  border: dashed 2px $gray-700;
  text-align: center;
  cursor: pointer;
  border-radius: $border-radius;
}

.noTagsWarning {
  background-color: #e5e5e5;
  padding: 12px;
  border-radius: 7px;
  max-width: 250px;
  .btn {
    margin-top: 6px;
  }
}

.select-content-block {
  outline: solid 2px transparent;
  border-radius: $border-radius;
  background-color: white;
  box-shadow: $overview-item-shadow;
  overflow: hidden;
  cursor: pointer;
  background-color: #393939;
  h6 {
    padding: 6px;
    margin: 0;
    color: white;
    text-align: center;
  }
  .thumb {
    aspect-ratio: 16/9;
  }
  &.is-selected {
    outline-color: #00d380;
  }
}

.colorpicker {
  width: 100%;
  max-width: 290px;
  display: inline-block;

  .colorpicker__selected {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    height: auto;
    text-align: center;
    width: 100%;
    border-radius: $border-radius;
    font-size: 12px;
    padding: 10px;
    transition: all 0.2s ease;
  }
  .colorpicker__option {
    cursor: pointer;
    width: 36px;
    height: 36px;
  }
}
.render__table {
  margin-bottom: 1rem;
  background-color: $gray-100;
  padding: 7px 0;
  border-radius: $border-radius;
  width: 100%;
  tr:nth-child(even) td {
    background-color: $gray-200;
  }
  thead {
    background-color: $gray-400;
  }
  th,
  td {
    padding: 6px 12px;
  }
}

.aync-image__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}


.match-upload-preview{
  max-width: 750px;
  counter-reset: clips;

  .clip.draggableclip{
    width:100%;
    .clip_m{
      flex-shrink: 1;
      flex-grow: 1;
      width: 50%;
    }
    .clip_l{
      aspect-ratio: 16/9;
      position: relative;
      .clip_thumb[style*='.svg']{
        background-size: auto 80%;
        background-repeat: no-repeat;
      }
      .clip_thumb:hover:after{
        display: none;
      }
      &:after{
        position: absolute;
        left:3px;
        top:3px;
        color: white;
        text-shadow: 0 0 0 2px rgba(0,0,0,1);
        counter-increment: clips;
        content: counter(clips);
        font-size: 12px;
        width:18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        border-radius: 200px;
        line-height: 1;
        background-color: $colors-branding-primary;
      }
    }
    &.is-error{
      .clip_l:after{
        content: 'X';
        background-color: red;
      }
    }
    .clip__overflow{
      text-overflow: ellipsis;
      overflow-x: hidden;
      word-break: break-all;
    }
    .clip_duration{
      flex-wrap: wrap;
    }
  }
}