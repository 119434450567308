@import '~bootstrap/scss/mixins';
@import '../../../lib/styles/variables';

.app-page {
  .page-header-inset {
    margin-bottom: 0;
  }

  padding-bottom: 50px;

  .app-iframe {
    width: 100%;
    flex-grow: 1;
  }
}

.choose-app {
  //background-color: $bg-dark;
  margin: auto;

  .row {
    width: 90%;
    //justify-content: center;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    margin: auto;
    > div {
      margin-top: 10px;
    }

    &.app-count-2 {
      justify-content: center;
    }
  }
  .page-header h1 {
    text-align: center;
    color: $colors-branding-primary;
  }

  .thumbnail {
    background-color: $colors-branding-primary;
    padding: $spacing-md;
    border-radius: $border-radius-default;
  }
  a {
    img {
      width: 100%;
      max-height: 26px;
    }
  }
}
