@import './variables';

.nav-link {
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.locked {
    pointer-events: none;
    opacity: 0.7;
  }
  &.active {
    font-weight: $font-weight-bold;
  }
}
