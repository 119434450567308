@import 'lib/styles/variables.scss';
@import 'lib/styles/layout.scss';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// $video-zoom: 25%;

// NOTE: it would be nice to extend the existing card component but the current
// design differs too much for now.

.playlist-groups {
  max-width: map-get($container-max-widths, xxl);
  width: 100%;
  margin: 0 auto;
}

.playlist-group {
  margin-bottom: $spacing-lg;
}

.playlist-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: $spacing-lg;

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(xs) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.playlist-card {
  position: relative;
  padding-bottom: (9/16 * 100%); // Change height to fixed aspect ratio. (H/W)
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-default;
  color: $font-color-contrasting;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  word-break: break-all;
  cursor: pointer;
  outline: solid 1px #efefef; // Add 1px outline for more visual clarity

  @include media-breakpoint-down(sm) {
    height: 100px;
  }

  &.stack {
    // Show playlist card as stacked item.
    outline-color: transparent;
    height: calc(100% - 6px);
    padding-bottom: calc(#{(9/16 * 100%)} - 6px);
    .stack-small,
    .stack-large {
      width: 96%;
      position: absolute;
      top: calc(100% - 6px);
      left: 2%;
      height: 12px;
      border-radius: $border-radius;
      background-color: #696969;
      border: solid 1px darken(#696969, 10%);
      display: block;
      z-index: -2;
    }
  }
  &.stack--large {
    height: calc(100% - 9px);
    padding-bottom: calc(#{(9/16 * 100%)} - 9px);

    .stack-small {
      top: calc(100% - 8px);
    }
    .stack-large {
      width: 92%;
      top: calc(100% - 3px);
      height: 10px;
      left: 4%;
      background-color: darken(#696969, 10%);
    }
  }

  &-overlay,
  &-background {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    border-radius: $border-radius-default; // safari
    top: unset; // Bind objects to bottom, releasing top offset.
  }
  &-background {
    height: 100%;
    z-index: -1;
    background-color: $colors-branding-primary;
    background-size: cover;
    background-position: center;

    video {
      // NOTE: removed zoom, breaks on safari
      // margin-left: -1 * $video-zoom;
      // margin-top: -1 * $video-zoom;
      // width: 100% + 2 * $video-zoom;
      // height: 100% + 2 * $video-zoom;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius-default; // safari

      // Add smooth transition from background to playing video.
      animation: fadeIn;
      animation-duration: 1.2s;
      animation-fill-mode: forwards;
    }
  }
  &-overlay {
    padding: $spacing-lg;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: flex-end;

    height: auto;
    bottom: 0;

    transition: all 0.5s ease;
    pointer-events: none;

    &:before {
      // Set gradient / overlay on before element in overlay to have bevior not depend on overlay content.
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      opacity: 1;
      transition: opacity 0.2s ease;
      border-radius: 0 0 $border-radius-default $border-radius-default; // only bottom right
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0.6) 60%
      );
      z-index: 0;
    }

    .playlist-card-title,
    .playlist-card-subtitle {
      width: 100%;
      margin: 0;
      display: block;
      position: relative;
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.75); // Improve legibility

      padding-right: 50px; // Prevents title going over button.
    }
    .playlist-card-title {
      margin-bottom: 0.5em;
    }
    &.is-playing {
      &:before {
        opacity: 0.25; // make overlay less intense while video is playing
      }
    }
  }

  &-title {
    font-size: 1rem;
  }

  &-subtitle {
    font-size: 0.8rem;

    i {
      margin-right: $spacing-sm;
    }
  }
}

#video-player {
  background-color: #000;
  z-index: 4; // fix for .btn z-index: 2
  @include media-breakpoint-down(md) {
    min-width: unset !important;
    width: unset !important;
    height: 56.67vw !important;
    max-height: calc(100vh - 36px) !important;
  }
  > div:not(.drag__wrapper) {
    height: 100%;
    width: 100%;

    > div {
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.new-option {
  background-color: orange;
}

.playlist-item-actions-secondary {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;

  display: flex;
  justify-content: flex-end;

  z-index: 1;

  margin-bottom: $spacing-lg;
  .btn {
    pointer-events: auto;
    padding-right: $spacing-lg;
    border-radius: 40px 0 0 40px; // Fixes to right button. borders left.
    background-color: $primary;
  }
  .btn:hover,
  .btn:focus {
    opacity: 0.9; // btn hover state
  }
  .btn > i {
    margin: 0;
  }
  .dropdown-menu {
    pointer-events: all;
  }
}

.playlist-card.demo {
  .playlist-card-background {
    height: calc(100% - 12px);
    top: 12px;
  }
  .demo {
    position: absolute;
    z-index: 9;
    background-color: hsla(204, 88%, 60%, 0.95);
    color: white;
    margin: 0;
    padding: 0.25rem 0.5rem;
    position: absolute;
    height: auto;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    border-radius: $border-radius-default;
    color: white;
    text-transform: uppercase;
    text-align: center;
    @include media-breakpoint-down(lg) {
      font-size: 11px;
    }
  }
}

.page--playlist {
  .sidebar {
    @include media-breakpoint-up(lg) {
      max-width: 550px;
      flex: 0 0 550px;
    }
  }
}


.playlist__error{
  animation: fadeOut;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  animation-delay: 2s;
  pointer-events: none;
  position: fixed;
  z-index: 9;
  bottom: 1rem;
  right:5%;
}