@import '~bootstrap/scss/mixins';
// @import './bootstrap_variables';
@import './variables';

$container-max-widths: map-merge(
  (
    xs: 540px,
  ),
  $container-max-widths
);

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.75);

  &-dialog {
    display: none;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 10;
    margin: 0 3%;
    background-color: $bg-light;
    border-radius: 7px;

    @each $size, $maxWidth in $grid-breakpoints {
      @include media-breakpoint-up($size) {
        max-width: map-get($container-max-widths, #{$size}) - (2 * $spacing-md);
      }
    }

    .modal.full-screen & {
      max-width: none !important;
      width: 100%;
      min-height: 100%;
      margin: 0;
      border-radius: 0;
    }

    // full-screen for >= sm
    @include media-breakpoint-down(sm) {
      max-width: none !important;
      width: 100%;
      min-height: 100%;
      margin: 0;
      border-radius: 0;
    }
  }

  &-content {
    padding: $spacing-xl;
    border-radius: $border-radius-default;

    max-height: var(--innerVH, 100vh);

    overflow-y: auto;

    input {
      background-color: #fff;
    }
  }

  &.has-tabs &-content {
    padding-top: 2 * $spacing-xl;
  }

  &-intro {
    margin: (-1 * $spacing-xl) (-1 * $spacing-xl) $spacing-xl;

    > * {
      width: 100%;
    }
  }

  &-header {
    margin-bottom: $spacing-xl;
  }

  &-body {
    margin-bottom: $spacing-xl;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-footer {
    margin-top: $spacing-xl;
  }

  &-title {
    font-size: 1.5rem;
  }

  &-close {
    z-index: 10;
    position: absolute;
    top: 10px;
    right: 10px;
    background: url('../../img/icons/cross.svg');
    width: 18px;
    height: 18px;

    &-contrasting {
      top: $spacing-xl;
      right: $spacing-xl;
      width: $spacing-lg;
      height: $spacing-lg;
      filter: brightness(0) invert(1);
      // background: url('../../img/icons/cross-fat.svg'); // TODO: Martijn
    }
  }
}

.modal-open .modal-dialog {
  display: block;
}

.modal.sm {
  .modal-dialog {
    max-width: 450px;
    margin: auto;
  }
}

.modal.md {
  .modal-dialog {
    max-width: 700px;
    margin: auto;
  }
}

html.modal-open {
  overflow: hidden;
}

.modal-input {
  margin-top: 20px;
}

/* Swal */

.swal-modal {
  width: auto;
  background-color: $bg-light;
  border-radius: $border-radius;
}

.swal-button--cancel:not([disabled]) {
  background-color: $gray-600;
  color: white;

  &:hover {
    background-color: $gray-700;
  }
}

.swal-title {
  font-size: 22px;
  padding-bottom: 0;
}

.swal-text,
.swal-title {
  text-align: left;
}

.stepnavigation {
  width: 100%;
  display: flex;
  padding-right: 38px;
  justify-content: stretch;
  background-color: $gray-300;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  border-radius: $border-radius $border-radius 0 0;
  z-index: 3;
}

.tabselect {
  white-space: nowrap;
  padding: 10px 12px 6px 12px;
  border-right: solid 1px $gray-400;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  border-top-left-radius: 0px;
  flex-basis: 100%;
  border-bottom: solid 2px transparent;

  &:hover {
    background-color: $gray-500;
  }

  &:first-child {
    padding-left: $spacing-xl;
    border-left: 0;
  }

  &.active {
    background-color: $bg-light;
  }

  &:not(.active) {
    border-bottom: solid 2px $gray-400;
  }

  cursor: pointer;
  transition: background-color 0.2s ease-in;
}

.stepactions {
  margin-top: $spacing-xl;
}

.modal-info-header {
  background-color: $colors-branding-primary;
  color: white;
  display: block;
  padding: $spacing-xl $spacing-xl 18px;
  margin: ($spacing-xl * -1) ($spacing-xl * -1) 36px;

  h2,
  p {
    margin: 0;
  }
}

.modal-info-header + .modal-close.clickable {
  filter: invert(100%);
}

@include media-breakpoint-down(sm) {
  .modal-content {
    border-radius: 0;
  }
}
