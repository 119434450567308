@import '../styles/variables';

.fileuploader__fileselect {
  .dropzone__wrapper {
    // Handles file input click event awell.

    cursor: pointer;

    .dropzone {
      padding: 40px;
      border: dashed 2px $gray-600;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-radius: $border-radius;
      background-color: #fff;

      transition: background-color 0.3s ease;
      > div {
        width: 100%;
        text-align: center;
      }

      > * {
        // prevent drag events setting classes on children of dropzone
        pointer-events: none;
      }

      &--dragover {
        background-color: transparentize($green, 0.95);
      }
      + .btn {
        margin-top: 12px;
      }
      .muted {
        display: block;
        font-size: 80%;
        opacity: 0.8;
      }
    }

    &:hover {
      .dropzone {
        background-color: transparentize($blue, 0.95);
      }
    }
  }
}

.fileuploader__files {
  .file {
    background-color: $gray-100;
    border-bottom: solid 2px $gray-300;
    padding: 12px;
    border-radius: $border-radius;
    margin-bottom: 12px;
    display: flex;
    align-content: center;
    align-items: center;
    button {
      margin-left: auto;
    }

    .rejectedMessage {
      color: $danger;
      margin-right: 0.5em;
    }
    .rejected-files {
      margin-top: 12px;
    }
  }
}
