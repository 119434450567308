.user-settings-header {
  padding: 50px 0;
  background: #f2f2f2;
  color: #000;
  h1,
  h2 {
    color: #000;
  }
}

.light-span {
  padding: 0 20px;
  color: #828282;
}

.change-icon {
  width: 24px;
  height: 24px;
  background: url('../../../img/icons/change.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.user-gender-row {
  padding: 20px 0 40px 0;
  &::before {
    content: url('../../../img/icons/male.svg');
    width: 20px;
    height: 20px;
    display: block;
    position: relative;
    float: left;
    padding-right: 10px;
  }
}

.user-setting-item {
  height: 57px;
  width: 100%;
  background: #f2f2f2;
  border-radius: 7px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 82px;
  position: relative;
  margin: 16px 0;
}

.user-setting-thumb {
  border-radius: 50%;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 57px;
  width: 57px;
  background: #d8d8d8;
}

.user-setting-thumb-inner {
  border-radius: 50%;
  position: relative;
  left: 0px;
  top: 0px;
  height: 57px;
  width: 57px;
  background: url('../../../img/icons/club_placeholder.svg');
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
}

.user-setting-item {
  .flex-content {
    padding-left: 82px;
    padding-right: 57px;
  }
  &.invited .flex-content {
    padding-left: 82px;
    padding-right: 114px;
  }
  .user-setting-icons {
    width: 57px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
  }
  &.invited .user-setting-icons {
    width: 114px;
  }
  .user-setting-icons .user-setting-icon {
    position: relative;
    float: left;
    width: 57px;
    height: 57px;
    &.delete {
      background: #ff3232;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 25px;
        height: 25px;
        background: url('../../../img/icons/delete_white.svg');
        right: 16px;
        top: 16px;
      }
    }
    &.leave {
      background: #ff3232;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 25px;
        height: 25px;
        background: url('../../../img/icons/leave_white.svg');
        background-repeat: no-repeat;
        right: 16px;
        top: 16px;
      }
    }
    &.check {
      background: #0fcf85;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 25px;
        height: 25px;
        background: url('../../../img/icons/check_white.svg');
        right: 16px;
        top: 16px;
      }
    }
    &.cross {
      background: #cf0f0f;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 25px;
        height: 25px;
        background: url('../../../img/icons/cross_white.svg');
        right: 16px;
        top: 16px;
      }
    }
  }
}

/*
.user-setting-item::before {
	content: "";
	display: block;
	width: 57px;
	height: 57px;
	position: absolute;
	right: 0;
	top: 0;
	background: #FF3232;
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
}

.user-setting-item::after {
	content: "";
	display: block;
	position: absolute;
	width: 25px;
	height: 25px;
	background: url('../img/icons/delete_white.svg');
	right: 16px;
	top: 16px;
}
*/

.user-setting-action {
  padding-right: 25px;
  position: relative;
  &.with-stripe::after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 14px;
    background: #000;
    right: 12px;
    top: 6px;
  }
}

.avatar {
  width: 100%;
  padding-bottom: 100%;
  background: #d8d8d8;
  border-radius: 50%;
  margin-top: 20px;
  &.empty::after {
    content: url('../../../img/icons/plus.svg');
    display: block;
    position: absolute;
    width: 40%;
    height: 40%;
    top: 30%;
    left: 30%;
  }
}

.setup-notifications {
  padding-top: 12px;
  margin-top: 12px;
  border-top: solid 1px $gray-400;
  transition: opacity 0.3s ease;

  .isLoading {
    pointer-events: none;
    opacity: 0.75;
  }

  .setup-notifications__container {
    width: 100%;
    border-radius: $border-radius;
    border: solid 1px $gray-300;
  }

  .setup-notifications__row {
    //width: 100%;
    display: flex;
    background-color: white;
    flex-wrap: wrap;
    > * {
      border-bottom: solid 1px $gray-300;
    }
    &:nth-child(even) {
      background-color: $gray-100;
    }
  }
  .setup-notifications__label {
    font-weight: bold;
    margin: 0;
    line-height: 1;
  }
  .setup-notifications__setting {
    padding: 6px 12px;
    border-left: solid 1px $gray-200;
    vertical-align: middle;
    &:first-child {
      flex-grow: 1;
    }

    p {
      font-size: 0.875rem;
      color: $gray-600;
      margin: 0;
    }
  }
  .tooltip__wrapper {
    display: block;
    line-height: 1;
  }

  .input-checkbox input[type='checkbox'] + label i {
    filter: brightness(0);
    width: 24px;
    height: 24px;
  }
  .input-checkbox input[type='checkbox'] + label:before {
    margin-right: 3px;
  }
  .input-checkbox input[type='checkbox'] + label:before {
    background-color: white;
    border: solid 1px $gray-500;
  }
}

.form-actions {
  margin-top: 12px;
}
