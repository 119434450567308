

.scoreboard{
    width:auto;
    font-size: 1.4em;
    color: white;
    position: absolute;
    left:1em;
    top:1em;
    /*opacity: 0.75;*/
}

.scoreboard--hockey{

}

.scoreboard-row{
    display: flex;
}

.scoreboard-block {
    background-color: #444444;
    /*border-radius: 7px;*/
    display: flex;
    justify-content: center;
    align-content: center;
    font-weight: 900;
    padding: 0.2em 0.5em;
}
.scoreboard-period {
    margin-right: 0.1%;
    background-color: #98989b;
}
.scoreboard-time {
    /*margin-right: 1.1%;*/
    background-color: #98989b;
}
.scoreboard-club{
    /*margin: 0 0.5em;*/
    background-color: #222;
}

.scoreboard-score{
    padding:0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.scoreboard-score .score{
    width: 3em;
    padding: 0.5% 1%;
    text-align: center;
}
.score-divider{
    background-color: #646452;
    height:100%;
    width: 0.2em;
    margin: 0 0.2em;
}

.scoreboard-nav{
    left:1em;
    top:0;
    height:100%;
    width: auto;
    justify-content: center;
    align-items: center;
    align-content: center;

}
.scoreboard-nav__button {
    margin-right: 1em;
    border: solid 1px transparent;
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s ease;
}
.scoreboard-nav__button:hover{
    opacity: 1;
}
.scoreboard-nav__button .i-play{
    width: 12px;
}
.scoreboard-nav__button + .scoreboard-nav__button{
}
.scoreboard-nav__button.is-active{
    opacity: 1;
}

.video-scoreboard{
    position: relative;
}
.invoer-title .scoreboard-nav{
    z-index: 2; /* Fix for reporting dashboard.*/
}