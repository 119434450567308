@import '~bootstrap/scss/mixins/breakpoints';
@import 'lib/styles/variables.scss';
@import 'lib/styles/typography.scss';

$player-card-width: 20vh;
$player-card-height: 20vh;
$img-base: '../../../img';

.player-menu ul li {
  color: #000;
}

.player-menu {
  height: 140px;
}

/*
.player-carousel {
	height: 14.28vw;
	max-height: 14.28vw;
	overflow-x: scroll;
	overflow-y: hidden;
    white-space: nowrap;
	direction: ltr;
}
*/

/*
.player-square {
	height: 14.28vw;
	width: 14.28vw;
	background: red;
	float: left;
	display: inline-block;
	border-radius: 7px;
	border: 2px dashed #5650A3;
}
*/

.scrollmenu,
.player-carousel {
  height: calc(#{$player-card-height} + 10px);
  height: 175px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 12px;
}

.player-square {
  /*display: inline-block;*/
  /*float: left;*/
  flex: 0 0 auto;
  //height: $player-card-height;
  //width: $player-card-width;
  aspect-ratio: 1/1;
  border: 2px dashed #979797;
  border-radius: 7px;
  background-color: #f2f2f2;
}
.player-carousel .player-square {
  margin-right: 12px;
}

.player-square-inner {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 12px;
  overflow: hidden;
  border-radius: 7px;
  white-space: normal;
}

.player-square-inner .player-thumb {
  background: grey;
  border-radius: 50%;
  width: 10vh;
  max-width: 44px;
  height: 10vh;
  max-height: 44px;
  margin: 10px calc(50% - 22px) 10px calc(50% - 22px);
  background-size: cover !important;
}

@include media-breakpoint-down(md) {
  .player-square-inner .player-thumb {
    display: none;
  }
}

.player-square-inner .player-name {
  float: none;
  width: 100%;
  display: block;
  position: relative;
  @include media-breakpoint-down(sm) {
    font-size: 11px;
  }
}

.drag-icon,
.number-icon,
.delete-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  font-size: 24px;
}

.delete-button {
  top: 2px;
  left: 2px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  //   background-color: $bg-danger;
  background-color: lighten($bg-danger, 20%);
  border-radius: $border-radius-default;

  &:hover {
    background-color: $bg-danger;
  }
}

.number-icon {
  top: 40px;
  left: 8px;
  font-weight: 700;
  font-size: 24px;
  color: #28e89e;
  line-height: 1;
}

.drag-icon {
  width: 22px;
  height: 22px;
  right: 8px;
  background: url('#{$img-base}/icons/drag.svg');
}

.formation-field {
  height: calc(100% - 14.28vw);
  background: #003600;
  background: linear-gradient(0deg, #343434, #1f4839);
  //background: linear-gradient(90deg, #755555, #6ca28f);
  border-radius: $border-radius;
  @include media-breakpoint-down(sm) {
    // make drop field slightly larger on mobile
    margin-left: calc(-5vw + 6px);
    margin-right: calc(-5vw + 6px);
  }
}

.formation-field-half {
  width: 50%;
  float: left;
  position: relative;
  height: 100%;
}

.formation-field-half.field--attack {
  border-left: 10px solid #00d380;
  padding-right: 6px;
}

.formation-field-half.field--defence {
  border-right: 10px solid #de0003;
  padding-left: 6px;
  border-left: solid 1px rgba(255, 255, 255, 0.5);
}

.formation-field-half .flex-content {
  position: unset;
}

.formation-swap {
  display: flex;
  justify-content: center;

  font-size: 11px;
  padding: 20px 0 12px 0;

  button {
    border: 0;
    cursor: pointer;
    color: white;
    outline: 0;
    background-color: $colors-branding-secondary;
  }
}

.formation-select {
  display: flex;
  justify-content: center;
  .btn-group-select {
    .btn.active {
      background-color: #1f4839;
    }
    .btn {
      border-bottom: 0;
      &:first-child {
        border-bottom-left-radius: 0;
      }
      &:last-child {
        border-bottom-right-radius: 0;
      }
    }
  }
}

.player-squares {
  width: calc(#{$player-card-width} + #{$player-card-width} + 12px);
  margin: auto;
}

.player-squares .player-square {
  float: left;
  //margin-top: 6px;
  //margin-left: 6px;
  width: 100%;
  margin-bottom: 15px;
}

.player-carousel .player-square {
  margin-top: 2px;
  margin-left: 0;
}

.player-square.empty {
  background: none;
  color: #fff;
  opacity: 0.75;
}

.player-square.empty .player-thumb {
  background: url('#{$img-base}/icons/drag_player_white.svg');
  border-radius: 0;
}

.player-square.empty ~ .player-square.empty > .player-square-inner > * {
  display: none;
}

/* Add player */

.gender-select {
  padding-left: 30px;
}

.gender-select.male::before {
  content: url('#{$img-base}/icons/male.svg');
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
}

.gender-select.female::before {
  content: url('#{$img-base}/icons/female.svg');
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
}

.invoer-margin-wrapper {
  margin-left: 34px;
}

.avatar-wrapper {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-top: 20px;
  background: #d8d8d8;
}

.avatar-inner {
  width: 100%;
  height: 100%;
  background: url('#{$img-base}/icons/plus.svg');
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
}

.icon.user-icon {
  content: url('#{$img-base}/icons/user.svg');
}

.icon.back-icon {
  content: url('#{$img-base}/icons/jersey.svg');
}

.icon.email-icon {
  content: url('#{$img-base}/icons/mail.svg');
}

.icon {
  width: 24px;
  height: 24px;
  float: left;
  margin: 8px 8px 8px 0;
}

.player-squares-text {
  padding: 18px 0;
  color: #fff;
  margin: 0 5px;
  width: calc(100% - 10px);
}

.copy-formation .player-thumb {
  background: url('#{$img-base}/icons/copy_white.svg');
}
