
.efficiency-label {
  &.negative .background-rect {
    fill: #e93e49;
  }
  &.neutral .background-rect {
    fill: #ffd08c;
  }
  &.positive .background-rect {
    fill: #00d280;
  }
  &.freq-none {
    opacity: 0.4;
    .background-rect,
    .background-rect-filler {
      opacity: 0;
    }
  }
  &.freq-low .background-rect {
    opacity: 0.2;
  }
  &.freq-mid .background-rect {
    opacity: 0.6;
  }
  &.freq-high .background-rect {
    opacity: 1;
  }
}

.dashboard-table-row {
  &.disabled {
    .player-label {
      fill: #ccc !important;
    }
    .efficiency-label {
      .background-rect {
        fill: #ccc !important;
      }
      opacity: 0.7 !important;
    }
  }
}

.bar-chart-item {
  &.disabled {
    fill: #ccc !important;
  }
}

.dashboard-wrapper {
  svg {
    text {
      font-family: 'Avenir-Heavy', 'Avenir', 'Lato', sans-serif;
      font-weight: 600;
      fill: #222;
    }

    &.light-color {
      text[fill="#FFFFFF"] {
        fill: #ffffff;
      }
    }

    text[fill="#FFFFFF"] tspan {
      fill: #ffffff;
    }
  }
  text-align: center;
}
