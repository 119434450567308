@import 'lib/styles/variables.scss';
@import 'lib/styles/layout.scss';

$page-title-size: 14px;
$page-title-line-height: $page-title-size * 1.5;
$page-padding-top: $spacing-xl;

.page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: $page-padding-top 0 ($spacing-xl * 2);

  &-header {
    .breadcrumb {
      display: inline-block;
      flex-grow: 1;
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: $font-size-sm;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .breadcrumb-item {
      display: inline;
      font-weight: bold;

      &-link {
        font-weight: normal;
        text-decoration: none;

        &:hover {
          color: $colors-branding-secondary;
        }

        &::after {
          content: '>';
          margin: 0 $spacing-md;
          color: $font-color-contrasting;
        }
      }
    }

    &-with-bg {
      padding-bottom: $spacing-xl;
      background-color: $bg-light;
    }

    &-tour,
    &-knowledge-base {
      font-size: $page-title-size;

      @include media-breakpoint-down(xs) {
        font-size: 11px;
      }

      line-height: $page-title-line-height;
      margin-left: $spacing-md - $spacing-sm;

      > a {
        &::after {
          margin-left: $spacing-md;
          content: '|';
        }
      }
      &:last-child {
        > a {
          &::after {
            content: '';
            margin: 0;
          }
        }
      }

      a {
        display: flex;
        align-items: center;

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      span {
        padding: 0 $spacing-sm;
      }
    }
    &-tour {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &-inset {
      display: flex;
      align-items: center;
      @include layout-container;
      margin-top: (-1 * $page-padding-top);
      margin-bottom: $page-padding-top;
      padding-top: 8px;
      padding-bottom: 8px;

      a {
        color: $font-color-contrasting;
      }

      &-wrapper {
        background-color: $bg-dark;
        color: $font-color-contrasting;

        .view-overlay & {
          display: none;
        }
      }
    }

    &-content {
      display: flex;
      align-items: center;
      //margin: $page-padding-top 0;
      @include layout-container;
      margin-bottom: 1em;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &-cta {
      margin-right: $spacing-lg;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 0 0 $spacing-md 0;

        & > .btn {
          justify-content: center;
          width: 100%;
        }

      }
    }
  }

  &-title,
  &-subtitle-text,
  &-subtitle-icon {
    margin-right: $spacing-xl;
  }

  &-title {
    font-size: $page-title-size;
    font-weight: bold;
  }

  &-subtitle {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      order: -1;
      margin: 0 0 $spacing-md 0;
    }

    &-text {
      color: $font-color-default;
      font-size: $h6-font-size;
      font-weight: bold;
      white-space: nowrap;
    }

    &-icon {
      border-radius: 50%;
      height: 34px;
      width: 34px;
      background: url('../../img/icons/profile-picture-placeholder.svg');
      background-size: cover;
    }
  }

  &-actions {
    flex-grow: 1;
    text-align: right;
    > span {
      padding-right: $spacing-md;
    }
    > span:last-child {
      padding-right: 0;
    }

    > .btn-link:last-child {
      padding-right: 0; // align with container
    }

    @include media-breakpoint-down(sm) {
      width: 100%;

      .btn {
        width: 100%;
      }
    }
  }

  &-body {
    @include layout-container;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }

  .modal & {
    width: 100vw;
  }

  .view-overlay & {
    padding: 0;
  }

  .page-title--group {
    white-space: normal;
    h2,
    h3 {
      margin: 0;
    }
    h2 {
      font-size: (30/16 * 1rem);
      font-weight: 900;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
    h3 {
      font-weight: 400;
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
    padding-bottom: 2rem;
  }
}

.page--development {
  background-color: #f3f3f3;

  .page-title--group {
    color: #7e8285;
  }
}


.page-header--sticky{
  position: sticky;
  top: -2px;
  z-index: 100;
  background-color: white;

  &.is-pinned{
    background-color: $gray-100;
    box-shadow: $overview-item-shadow;
  }
}