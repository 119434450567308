.imagepicker {
  display: block;
}
.imagepicker__container {
  display: flex;
  flex-wrap: wrap;
  container-type: inline-size;
}

$base-image-size: 250px;

.imagepicker__image {
  width: $base-image-size;

  //@include media-breakpoint-down(sm) {
  //  width: 125px;
  //}

  padding: 0.25rem;
  position: relative;

  cursor: pointer;

  img {
    //padding: 1rem;
    object-fit: contain;
    height: 100%;
    max-width: 100%;
    width: 100%;
  }
  span.imagepicker__caption {
    font-size: 13px;
    color: white;
    position: absolute;
    left: 0.75rem;
    bottom: 0.75rem;
    max-width: calc(100% - 5em);
    overflow: hidden;
    text-overflow: ellipsis;
    //text-shadow: 0 0 4px black;
    background-color: #8d8d8d;
    padding: 0.125rem;
    white-space: nowrap;
  }

  img {
    transition: all 0.2s ease;
    border: solid 4px #ced4da;
    border-radius: 7px;
  }

  &:after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0.75em;
    bottom: 0.75em;
    border-radius: 1000px;
    background-color: #35da00;
    transition: all 0.2s ease;
    opacity: 0;
    background-size: 75%;
    background-position: center center;
    background-repeat: no-repeat;
    transform: scale(0.1);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  }

  &:hover:not(.is-selected) {
    img {
      border: solid 4px darken(#ced4da, 10%);
    }
    &:after {
      transform: scale(0.5);
      opacity: 0.5;
    }
  }

  &.is-selected {
    img {
      border: solid 4px #00d380;
    }
    &:after {
      transform: scale(1);
      opacity: 1;
    }
  }
}

@container (width < #{$base-image-size*3}) {
  .imagepicker__image {
    width: $base-image-size * 0.75;
  }
}

@container (width < #{$base-image-size*2}) {
  .imagepicker__image {
    width: $base-image-size * 0.5;
  }
}
