@import './variables';

.input-checkbox {
  padding: 6px 0px;
  label {
    display: flex;
    align-items: center;
    align-content: center;
    text-transform: none;
    font-weight: normal;
    padding: 0;
    color: $gray-600;

    a {
      margin-left: 4px;
      font-weight: 600;
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;

    & + label {
      cursor: pointer;
      &:before {
        flex-shrink: 0;
        content: '';
        margin-right: 1em;
        display: inline-flex;
        width: 1.5em;
        height: 1.5em;
        border: solid 1px $gray-400;
        border-radius: $border-radius;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' viewBox='0 0 24 24' fill='none' stroke='%2328e89e' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
        background-color: white;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 1.6em 0px;
        transition: background-position 0.1s ease-in;
      }
      &:hover:before {
        border-color: $primary;
      }
    }
    &:checked + label {
      &:before {
        border-color: $primary;
        background-position: 0px 0px;
      }
    }
  }
  input[disabled] {
    & + label {
      opacity: 0.6;
    }
  }
  input[type='radio'] {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;

    & + label {
      cursor: pointer;
      &:before {
        flex-shrink: 0;
        content: '';
        margin-right: 1em;
        display: inline-flex;
        width: 1.5em;
        height: 1.5em;
        border: solid 1px $gray-400;
        border-radius: 900px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%2328E89E'/%3E%3C/svg%3E%0A");
        background-color: white;
        background-size: 0%;
        background-repeat: no-repeat;
        background-position: center center;
        transition: background-size 0.1s ease-in;
      }
      &:hover:before {
        border-color: $primary;
      }
    }
    &:checked + label {
      &:before {
        border-color: $primary;
        background-size: 1em;
      }
    }
  }
}
