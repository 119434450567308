@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';
@import 'lib/styles/typography.scss';

.collapse-dropdown {
  margin-bottom: $spacing-lg;
  border-radius: $border-radius-default;
  box-shadow: 0px 2px $border-radius-default 0px rgba(0, 0, 0, 0.75);

  ul {
    margin: 0;
    padding: 0;
  }

  .active {
    font-weight: bold;
  }

  &-selected,
  &-item {
    display: flex;
    align-items: center;
    padding: $spacing-lg;
    cursor: pointer;
  }
  &-selected {
    font-weight: bold;
    padding: $spacing-lg;

    &-label {
      flex-grow: 1;
    }

    &-icon {
      width: 18px;
      height: 18px;
      background: url('../../../../img/icons/chevron_up_down.svg') 50% no-repeat;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    margin-right: $spacing-lg;
  }
  &-label {
    @extend .text-overflow;

    &-small {
      font-weight: normal;
      font-size: 12px;
    }
  }
}
