@import '~bootstrap/scss/mixins/breakpoints';
@import 'lib/styles/variables.scss';

.observation__training-live{
  padding: 4rem 0;
  background-color: $gray-400;
    .playercard{
      position: relative;
      border-radius: $border-radius;
      padding:2rem;
      overflow: hidden;
      background-color: white;

      height: 100%;
      //aspect-ratio: 1/1;

      border: solid 2px transparent;

      transition: all 0.2s ease;

      display: flex;
      //flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;

      cursor: pointer;

      h2{
        margin: 0;
        font-size: 1.2rem;
        text-align: center;
      }
      h3{
        margin: 0;
        font-size: 1rem;
        text-align: center;
      }
    }
  .playercard__backdrop{
    pointer-events: none;
    position: absolute;
    width:100%;
    height: 100%;
    left:1rem;
    top:1rem;
    font-size: 35px;
    line-height: 0;
    text-align: left;
    color: rgba(0,0,0,0.1)
  }


}
.training-rating {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  cursor: pointer;

  border-bottom: solid 1px $gray-400;
  border-radius: $border-radius;
  padding: 1em 0.5em;
  background-color: white;

  &:before{
    content:'';
    display: block;
    width:2rem;
    height: 2rem;
    border-radius: 2000px;
    margin-right: 1rem;
    background-color: $gray-600;
    //border: solid 2px $gray-600;
  }
  &.rating--positive:before{
    background-color: $green;
  }
  &.rating--negative:before{
    background-color: $red;
  }
  &.rating--neutral:before{
    background-color: $orange;
  }


  &+&{
    margin-top: 1rem;
  }

}

.training-players-list {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: background-color 0.2s;

  &.dragging-over {
    background-color: #e3f2fd; /* Highlight drop zone when dragging */
    border-color: #90caf9;
  }
}

/* Base styles for each player item */
.training-players-list .player-item {
  transition: box-shadow 0.2s, transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: grab;

  &:before{
    content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg fill='%23222222' width='15px' height='15px' viewBox='0 0 256 256' id='Flat' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M108,60.0001a16,16,0,1,1-16-16A16.00016,16.00016,0,0,1,108,60.0001Zm56,16a16,16,0,1,0-16-16A16.00016,16.00016,0,0,0,164,76.0001Zm-72,36a16,16,0,1,0,16,16A15.99985,15.99985,0,0,0,92,112.0001Zm72,0a16,16,0,1,0,16,16A15.99985,15.99985,0,0,0,164,112.0001Zm-72,68a16,16,0,1,0,16,16A15.99985,15.99985,0,0,0,92,180.0001Zm72,0a16,16,0,1,0,16,16A15.99985,15.99985,0,0,0,164,180.0001Z'/%3E%3C/svg%3E");
    display: inline-block;
    margin-right: 1rem;
  }

  &:hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }

  &.dragging {
    background-color: #bbdefb; /* Highlight the dragged item */
    border-color: #42a5f5;
    transform: scale(1.03); /* Slightly enlarge the item */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    cursor: grabbing;
  }
}

/* Ensure no spacing for the placeholder */
training-players-list .player-item:last-child {
  margin-bottom: 0;
}


.training-actions{
  display: flex;
  width:100%;
  justify-content: flex-end;
  padding: 1rem 0;
}