@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';
@import 'lib/styles/layout.scss';
@import '../../../lib/styles/video_page';

.video-observations {
  padding: $spacing-lg;
  background-color: $bg-lighter;
}

.observe-container {
  @include vh100-media-up;
}

.live-upload-state {
  padding: $spacing-lg;
  border-radius: $border-radius;
  margin-bottom: 1rem;
  animation: fadeInUp 1s forwards;
  animation-direction: normal;
  background-color: #f2f2f2;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
