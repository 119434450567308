.locked-content-tip {
  position: relative;

  .lock-wrapper {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 1;

    padding: 5px;
    display: flex;
    justify-content: center;
    align-content: center;

    cursor: pointer;

    .lock {
      max-width: 100%;
    }
  }

  &.small {
    .lock-wrapper {
      right: 0px;
      top: 0px;
      width: 14px;
      height: 14px;
      padding: 0px;
    }
  }
  &.large {
    .lock-wrapper {
      width: 22px;
      height: 22px;
      background-color: var(--secondary-color);
      border-radius: 50px;
      box-shadow: 0.5px 0.7px 5.8px rgba(0, 0, 0, 0.129),
        1.8px 2.5px 19.4px rgba(0, 0, 0, 0.191),
        8px 11px 87px rgba(0, 0, 0, 0.32);
    }
  }
}

.lock-tooltip {
  max-width: 300px;
  .label-link {
    color: var(--primary-color);
  }
}
