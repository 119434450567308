@import '~bootstrap/scss/mixins/breakpoints';
@import 'lib/styles/variables';

.live-observation-handball-goal {
  &-container {
    display: grid;
    grid-template-columns: 50% 1fr 1fr;
    grid-template-rows: auto auto 1fr;
    gap: $spacing-sm $spacing-xl;
    grid-template-areas:
      'header header header'
      'player-selection types results'
      'position types results';

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
    }
  }

  &-title {
    grid-area: header;
  }
  &-player-selection {
    grid-area: player-selection;
  }
  &-players {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: $spacing-md;

    > .label {
      margin-right: 0;
    }
  }
  &-position {
    grid-area: position;
  }
  &-types {
    grid-area: types;
    display: flex;
    flex-direction: column;
  }
  &-results {
    grid-area: results;
    display: flex;
    flex-direction: column;
  }
  &-types,
  &-results {
    .label,
    .btn {
      margin-bottom: $spacing-md !important;
      margin-right: 0;
    }
  }
}
