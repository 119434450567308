@import 'src/lib/styles/bootstrap/mixins';
.exchangeRow {
  cursor: pointer;
  .overview-item {
    transition: background-color 0.2s ease;
  }
  &:hover .overview-item {
    background-color: #e4e4e4;
  }

  .overview-item-title {
    background-color: $gray-500;
    color: white;
    padding: 0 0.75rem 0 1rem;
    margin-left: 0;
    border-radius: 7px 0 0 7px;
    width: 60px;
    font-size: 0.875rem;
  }
  &.current--season .overview-item-title {
    background-color: $gray-700;
  }

  .info {
    margin-left: auto;
    margin-right: 0;

    font-size: 0.875rem;

    &.pending .info-text {
      color: $gray-600;
    }
    i {
      display: none;
    }
  }

  .overview-item-details {
    justify-content: flex-start;
    a {
      cursor: pointer;
    }
  }
  @include media-breakpoint-down(md) {
    .info {
      .info-text {
        display: none;
      }
      i {
        display: block;
      }
    }
    .overview-item-features {
      display: none;
    }
  }
}

.popup-wrapper {
  .overview-item {
    background-color: white;
  }
  .overview-item-details {
    justify-content: flex-end;
  }
}

.exchangeRow + .popup-container .pop-inner {
  padding-left: 30px;
  h4 {
    margin-bottom: 1rem;
  }
}
