@import 'lib/styles/variables.scss';
@import 'lib/styles/layout.scss';

$first-column-width: 40%;

.container {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.firstColumn {
  width: $first-column-width;
}

.secondColumn {
  width: calc(100% - #{$first-column-width} - #{$spacing-lg});
  margin-left: $spacing-lg;
}

.fullWidthColumn {
  width: 100%;
}

.firstColumn,
.secondColumn {
  @include media-breakpoint-down(md) {
    width: 100%;
    margin-left: 0;
  }
}
