@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';

.resourceGroupMenu {
  min-height: 30vh;

  @include media-breakpoint-up(md) {
    right: $content-margin;
    min-width: 400px;
  }
}
