@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';
@import 'lib/styles/icons';

@keyframes animateIn {
  0% {
    transform: translateY(-30%) scale(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
}

%NavItemInteraction {
  // Add animated hover effect
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 100%;
    width: 0;
    background-color: transparent;
    transition: all 0.2s ease;
    z-index: -1;
    border-radius: 2px;
  }

  &:focus,
  &:hover {
    &:after {
      width: 100%;
      left: 0;
      background-color: $colors-branding-primary;
    }
  }
}

.toggle {
  display: flex;
  align-items: center;
}

.activeIcon {
  @extend .i-grid;

  margin-right: $spacing-md;
}

.appLogo {
  // This selector also targets mainlogo in header.
  height: 24px;
  cursor: pointer;
  max-width: 26vw; // Fix for highlights logo pressing menu offscreen on mobile.
}

.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include media-breakpoint-up(md) {
    right: initial;
    max-width: 480px;
  }
  transform-origin: top left;
  animation: animateIn 0.2s forwards;
}

.menuItem {
  padding: $spacing-lg;
  cursor: pointer;
  width: 50%;
  &[class*='w-33'] {
    width: percentage(1/3);
  }
  .appLogo {
    max-height: 22px;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    width: 50%;
    padding: 6px $spacing-sm 12px $spacing-sm;

    .appLogo {
      max-height: 22px;
      width: auto;
    }
  }
  @extend %NavItemInteraction; // Add hover / focus interaction
}

.menuItemTitle {
  margin-top: $spacing-sm;
}
