@import '~bootstrap/scss/mixins/breakpoints';
@import 'lib/styles/variables';

// $score-board-height: 45px;
$score-board-height: 53px;
$timer-height: 28px;
$timer-spacing: $spacing-sm;
$actions-spacing: $spacing-sm;

// NICE TO HAVE: had to resort to using css grid for the layout, refactor the
// remaining flexbox layout to css grid as well, using grid gap removes the need
// for the unsetting of margins.

// Observation Input Handball Live View
.live-observation-view {
  position: relative;
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-rows: $score-board-height 1fr auto;
  // TODO: is this ok BEN????
  grid-template-columns: 1fr 130px;
  grid-template-areas:
    'header actions'
    'inner actions'
    'inner actions';

  @include media-breakpoint-up(md) {
    & {
      grid-template-areas:
        'header header'
        'inner inner'
        'actions actions';
    }
  }

  &-header {
    grid-area: header;
    display: flex;
    flex-direction: column;
    align-items: center;

    .score-board {
      width: 100%;
      display: grid;
      padding: $spacing-md;
      gap: $spacing-md;
      grid-template-columns: 22px 1fr 30px auto 30px 1fr 22px;
      align-items: center;
      background-color: #424242;
      color: #eaeaea;
      z-index: 1;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);

      .name-possession {
        display: flex;
        align-items: center;

        &.away {
          flex-direction: row-reverse;

          .possession {
            margin: 0 $spacing-md 0 0;
          }
        }
      }
      .name {
        flex-grow: 1;
        /* Force 1 line height: */
        height: $score-board-height - (2 * $spacing-md);
        line-height: $score-board-height - (2 * $spacing-md);
        overflow-x: hidden;
        word-break: break-all;
      }

      .away {
        .name {
          text-align: right;
        }
        &.score {
          text-align: right;
        }
      }

      .possession {
        display: none;
        width: 10px;
        height: 10px;
        margin: 0 0 0 $spacing-md;
        background-image: url(./red-dot.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        &.active {
          display: block;
        }
      }

      .play-pause {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $spacing-xs $spacing-md;
        background-color: #343434;
        border-radius: $score-board-height;
      }

      &-btn {
        border: 0;
        padding: 0;
        background-color: transparent;
        line-height: 0;
        outline: none;
        &:disabled {
          opacity: 0.4;
        }

        & > i {
          transform: scale(0.7);
          padding: 0;

          &:first-child {
            margin-right: 0;
          }
        }
      }
    }

    .timer {
      position: absolute;
      // z-index: 11;
      z-index: 1;
      top: $score-board-height; // + 7; // TODO: figure out why score board is actually 52
      display: grid;
      align-items: center;
      height: $timer-height; // BUG: on safari 13
      line-height: $timer-height - (2 * $timer-spacing);
      padding: 6px 50px 34px;
      gap: $timer-spacing;
      grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
      background: transparent url(./timer-bg.svg) top center no-repeat;
      background-size: contain;
      color: #eaeaea;
      font-size: 12px;
      text-align: center;
      // box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);

      &-btn {
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        line-height: 12px;
        font-size: 14px;
        height: 14px;
        border: 0;
        border-radius: 100px;
        background-color: #5f5f5f;
        color: white;
        cursor: pointer;
      }
    }
  }

  &-inner {
    grid-area: inner;
    position: relative;
    overflow: hidden;
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  &-actions {
    grid-area: actions;
    display: flex;
    padding: $actions-spacing;
  }

  &-actions-primary,
  &-actions-secondary {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
  &-action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  &-actions {
    width: 100%;
    flex-direction: column;
  }

  &-action {
    &.btn.btn-default.active {
      background-color: $bg-normal;
      color: $font-color-default;
    }
  }

  &-actions-primary {
    height: 70%;
    flex-direction: column;
    margin-bottom: $actions-spacing;

    .live-observation-view-action {
      margin-bottom: $actions-spacing;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &-actions-secondary {
    height: 30%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $actions-spacing;
    .btn {
      background-color: transparent;
    }
  }

  @include media-breakpoint-up(md) {
    flex-direction: column;

    &-actions {
      width: 100%;
      flex-direction: row;
    }
    &-action {
      min-height: 76px;
    }
    &-actions-primary,
    &-actions-secondary {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &-actions-primary {
      width: 60%;
      margin-bottom: 0;
      margin-right: $actions-spacing;

      .live-observation-view-action {
        margin-bottom: 0;
        margin-right: $actions-spacing;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-actions-secondary {
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }
  }
}

.live-observation-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-background {
    z-index: 0;
    margin-top: 5%;
    width: 90%;
    height: 90%;
    object-fit: contain;
    object-position: center;
  }

  .layer-0,
  .layer-1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .layer-0 {
    z-index: 1;
  }
  .layer-1 {
    z-index: 2;
  }
}

.live-observation-input-handball {
  background-color: #2a80b9;
}

.live-observation-view-container {
  position: relative;
}

