.icon-filter {
  position: relative;
  padding-right: 0px;

  // TODO: create a SVG for the filter and have a i-filter so we don't need this exception
  & .unread-badge {
    top: -50%;
    right: -100%;
  }
}
