@import 'lib/styles/variables';

.PlaceHolder {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: $spacing-lg 0;
  text-align: center;
}

.description {
  margin-top: $spacing-lg;
  /* Maximum width for paragraph readability.
     NICETOHAVE: refactor to generic paragraph component. */
  max-width: 50em;
}
