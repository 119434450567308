.loading-fullscreen {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .page-title {
    opacity: 0;
  }
  .page-header-inset-wrapper {
    background-image: linear-gradient(
      90deg,
      transparent 0,
      rgba(255, 255, 255, 0.1) 80%,
      transparent 90%
    );
    background-size: 100% 100%;
    background-position: -100vw 0;
    background-repeat: no-repeat;
    animation: backgroundMove 1.5s infinite;
  }
}

.loading-fullscreen__inner {
  display: flex;
  justify-content: center;
  transform: translateY(calc(50vh - 35px - 102px));
  > div {
    margin: 0;
    transform: translateY(-50%);
  }
}

#app:has(.app-header:not(.app-header--fake)) .app-header--fake {
  display: none;
}
.app-header--fake {
  .app-header-content {
  }
  background-image: linear-gradient(
    90deg,
    var(--secondary-color) 0,
    rgba(255, 255, 255, 0.1) 80%,
    var(--secondary-color) 90%
  );
  background-size: 100% 100%;
  background-position: -100vw 0;
  background-repeat: no-repeat;
  animation: backgroundMove 1.5s infinite;
}

@keyframes backgroundMove {
  to {
    background-position: 100vw 0;
  }
}
