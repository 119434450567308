@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables';
@import 'lib/styles/layout.scss';

.playlist {
  .filters {
    display: flex;
    flex-direction: row;
  }

  .filter {
    padding: 0.5rem 1rem;
  }

  .active {
    font-weight: bold;
  }

  &-item {
    display: flex;
    align-items: center;
    position: relative;
    height: 34px;

    & > div {
      margin-right: $spacing-sm;
    }

    &-pre {
      opacity: 0.7;
      min-width: 24px;
    }

    &-text,
    &-time {
      cursor: pointer;
    }
    &-text {
      flex-grow: 1;
    }
    &-time {
      white-space: nowrap;
    }

    &-actions {
      display: flex;
      align-items: center;

      i {
        cursor: pointer;
      }
    }
  }
}

// TODO
// .with-text-overflow {
//   display: inline-block;
//   width: 100%;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }

