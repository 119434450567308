@import '../lib/styles/variables';
@import '../lib/styles/root';
@import '../lib/styles/animations';

$light: true;

.app-header-userlogin-secondary {
  margin-left: auto;
}
.page.page--userlogin,
.page.page--login {
  .page-header {
    margin-bottom: -36px;
  }
  .page-body {
    padding: 0;
  }
  padding: 36px 0 0 0;
  background-color: $gray-200;
}

.userlogin-layout {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 0 5%;
  position: relative;

  @media screen and (max-width: 650px) {
    padding: 0 8px;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-size: cover;
  overflow: hidden;
  &:before {
    position: absolute;
    left: -5%;
    top: -5%;
    background-image: url('https://uploads-ssl.webflow.com/6130b7778ceb5801031e630a/621688fc909b673c34486f8f_Group%206.png');
    background-position: right bottom;
    background-size: auto 100%;
    background-repeat: no-repeat;

    .skillreflect & {
      background-image: url('https://web-assets.teamtv.nl/app-images/sr-login-banner.jpeg');
      background-position: right bottom;
      background-size: cover;
      background-repeat: no-repeat;
      filter: blur(6px) grayscale(25%);
    }
    content: '';
    display: block;
    width: 110%;
    height: 110%;
    opacity: 0.75;
  }
}

.userlogin__formwrapper {
  position: relative;

  background-color: $gray-900;
  @if ($light) {
    background-color: transparentize(white, 0.1);
  }

  width: 100%;
  max-width: 440px;
  border-radius: 7px;
  height: 100%;
  flex-grow: 0;
  margin: 50px 0 75px 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;

  padding: 57px 60px 30px 60px;

  @media screen and (max-width: 1200px) {
    padding: 57px 40px 12px 40px;
  }

  @media screen and (max-width: 650px) {
    padding: 32px 16px 16px 16px;
  }

  h1 {
    color: transparentize(white, 0.1);
    @if ($light) {
      color: transparentize($font-color-default, 0.1);
      text-align: left;
    }
    font-size: 28px;
    font-weight: 700;
    line-height: 1.1;
    text-transform: uppercase;
    margin-bottom: 0.25em;
    @media screen and (max-width: 1200px) {
      font-size: 28px;
    }
  }
}

.userlogin-hero {
  display: flex;
  width: 450px;
  margin: auto;
  max-width: 560px;
  flex-grow: 1;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  position: relative;
  padding-bottom: 10%;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.userlogin-form__creation {
  padding: 12px 0;
  text-align: left;

  p {
    color: transparentize(white, 0.7);
    @if ($light) {
      color: transparentize($font-color-default, 0.4);
    }
  }
}

.hero-img {
  position: relative;
  //z-index: 0;
  bottom: 0;
  max-height: 100%;
  max-width: 650px;
  width: auto;
  overflow: hidden;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  margin: 20px auto 0 auto;

  img {
    width: auto;
    max-width: 100%;
    vertical-align: bottom;
  }
}

.userlogin-form-step {
  margin: 0 auto;
  max-width: 100%;
  width: 100%;

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    background-color: white;
    border: solid 1px $gray-400;
    width: 100%;
    transition: all 0.2s ease;

    &:focus,
    &:focus-within {
      //border-left: solid 2px var(--secondary-color);
      border-color: $gray-800;
      padding-top: 20px;
    }
    &:not(:placeholder-shown) {
      padding-top: 20px;
    }
  }
  .userlogin-form-actions {
    width: 100%;
    display: flex;
    justify-content: stretch;
    padding: 12px 0 42px 0;
    border-bottom: solid 2px $primary;
    > button {
      flex-grow: 1;
      padding: 8px 0;
      justify-content: center;
    }
  }
  > p {
    padding-bottom: 12px;
    //border-bottom: solid 2px $primary;
    color: White;
    text-align: left;
    @if ($light) {
      color: transparentize($font-color-default, 0.4);
    }
  }
  .pill {
    padding: 4px 6px;
    font-size: 12px;
    background-color: $gray-400;
    border-radius: $border-radius;
    margin-right: 6px;
    //color: white;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.userlogin__bottom {
  padding-top: 20px;

  text-align: left;

  font-size: 14px;

  color: white;
  @if ($light) {
    color: transparentize($font-color-default, 0.4);
  }
  > p {
    font-size: 14px;
    padding-top: 6px;
  }
  a {
    white-space: nowrap;
    font-weight: 700;
  }
}
.userlogin-form-input {
  position: relative;
  margin: 0 auto;
  padding-bottom: 12px;
  label {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 14px;
    padding-left: 16px;
    color: white;
    @if ($light) {
      color: transparentize($font-color-default, 0.4);
    }
  }
  input[type='text'],
  input[type='email'],
  input[type='password'] {
    & + label {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 14px;
      transition: all 0.2s ease;
    }
  }
  input[type='text'],
  input[type='email'],
  input[type='password'] {
    &:focus-within,
    &:focus {
      &::placeholder {
        color: transparent;
      }
    }
  }
  input[type='text'],
  input[type='email'],
  input[type='password'] {
    &::placeholder {
      color: transparent;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    &:focus-within + label,
    &:focus + label,
    &:not(:placeholder-shown) + label {
      transform: scale(0.75) translate(4px, -17px);
      transform-origin: left;
    }
  }

  input:focus-within + label,
  input:focus + label {
    color: $gray-800;
  }

  .userloginform__select__value-container {
    padding: 7px 16px;
  }
  .userloginform__select__control {
    border-color: $gray-300;
    border-radius: 7px;
  }

  .userlogin-form-editable {
    padding: 12px 16px;
    background-color: $gray-200;
    border-radius: $border-radius;
    color: $gray-700;
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 14px;
    &:hover {
      cursor: pointer;
    }
    i {
      margin-left: auto;
      opacity: 0.7;
    }
    &:hover i {
      opacity: 1;
    }
  }
  .flag-select button {
    background-color: white;
  }
  > .form-group {
    margin: 0;
  }
}

.userlogin__warning {
  margin-top: 12px;
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  background-color: #ffe8ba;
  border-left: solid 2px #ff9902;
  border-radius: 7px;
  padding: 8px 12px;
  margin-bottom: 12px;

  label {
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
  }
  p {
    font-size: 12px;
    line-height: 1.3;
  }
  a {
    color: #2a80b9;
    font-size: 14px;
    display: block;
    text-align: right;
  }

  &.userlogin__warning--info {
    background-color: #cbddef;
    border-color: #6bb8fc;
  }
  &.userlogin__warning--success {
    background-color: #caefcf;
    border-color: #99e08e;
  }
}

.userlogin-form-step {
  &.animateOut {
    animation-name: fadeOutLeft;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }
}

.styled-checkbox {
  padding: 12px 16px;
  label {
    display: flex;
    align-items: center;
    align-content: center;
    text-transform: none;
    font-weight: normal;
    padding: 0;
    color: $gray-600;

    a {
      margin-left: 4px;
      font-weight: 600;
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;

    & + label {
      cursor: pointer;
      &:before {
        content: '';
        margin-right: 1em;
        display: inline-flex;
        flex-shrink: 0;
        width: 1.5em;
        height: 1.5em;
        border: solid 1px $gray-400;
        border-radius: 2px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='96' height='96' viewBox='0 0 24 24' fill='none' stroke='%2328e89e' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 20px 0px;
        transition: background-position 0.1s ease-in;
      }
      &:hover:before {
        border-color: $primary;
      }
    }
    &:checked + label {
      border-color: $primary;
      &:before {
        background-position: 0px 0px;
      }
    }
  }
}

.userlogin__icon {
  width: 45px;
  position: absolute;
  left: calc(-27px + 50%);
  top: -25px;
}
