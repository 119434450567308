.loader-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 205px;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
}
.loader-overlay-inner {

}

.spinner {
    margin: 100px auto;
    width: 70px;
    text-align: center;
}


.loader-overlay .spinner {
    transform: translateY(calc(50vh - 35px - 102px));
    margin: 0 auto;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: var(--primary-color);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}


@media screen and (max-width: 1024px) {
    .spinner {
        transform: translateY(calc(50vh - 35px - 35px));
    }
}