@import '~bootstrap/scss/mixins';
@import 'lib/styles/variables.scss';

.rating-overview-item,
.rating-overview-totals {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-lg;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .match-overview-item {
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  .overview-item {
    margin-bottom: 0;
  }

  .rating {
    margin-left: $spacing-xl;

    @include media-breakpoint-down(sm) {
      margin: $spacing-md 0 0;
    }
  }
}

.rating {
  min-width: 36px;

  padding: $spacing-sm;
  border-radius: $border-radius-default;
  background-color: $bg-light;
  font-size: 18px;
  color: $font-color-contrasting;
  line-height: 100%;
  text-align: center;

  &-green {
    background-color: $teal;
  }
  &-orange {
    background-color: $orange;
  }
  &-red {
    background-color: $red;
  }
  &-grey {
    background-color: $gray-500;
  }

  &.disabled {
    background-color: $bg-light;
    pointer-events: none;
  }
}

.rating-overview-totals {
  .overview-item {
    flex-grow: 1;
    background-color: transparent;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    &-title {
      margin-left: 0;
      font-weight: normal;
    }
  }
}
