@import '~bootstrap/scss/mixins/breakpoints';
@import '../styles/variables';

// TODO fix the button base element, default overrides will break other components, plugins, etc.

$item-height: 57px;
$item-icon-border-radius: $item-height / 2;
$item-banners-height: 48px;

.overview-item {
  display: flex;
  align-items: center;
  position: relative;
  height: $item-height;
  width: 100%;
  //max-width: map-get($grid-breakpoints, 'xl'); MARGIN POLICE SAYS NO
  margin: 0 auto $spacing-lg;
  background-color: $bg-light;
  border-radius: $border-radius-default;

  // TODO this should be applied everywhere
  * {
    box-sizing: border-box;
  }

  .modal & {
    background-color: $bg-lighter;
  }

  > *,
  .overview-item-actions-primary,
  .overview-item-actions-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 $spacing-default;
    line-height: 100%;
  }

  &-active,
  &-em {
    border: 1px solid $bg-dark;

    .overview-item-date {
      // The gap between the border radiuses will become visible because of the
      // dark background color. This offset hides it.
      $border-radius-offset: 2px;
      border-top-left-radius: $border-radius-default - $border-radius-offset;
      border-bottom-left-radius: $border-radius-default - $border-radius-offset;
    }
  }

  &-active {
    border-color: $bg-active;

    .overview-item-date,
    .overview-item-label {
      background-color: $bg-active;
    }
  }

  &-active > &-icon {
    height: $item-height - 2px;
    width: $item-height - 2px;
  }

  &-inactive {
    opacity: 0.7;
  }

  &-title {
    font-weight: bold;
  }
  &-details {
    flex-grow: 1;
  }
  &-detail + &-detail {
    margin-left: $spacing-default;
  }
  &-meta {
    color: $font-color-lighter;
  }
  &-features,
  &-actions {
    margin-left: auto;
  }
  &-features + &-actions {
    margin-left: $spacing-default;
  }
  &-features {
    text-align: right;
  }

  &-actions {
    margin-right: 0;

    .overview-item-actions-primary,
    .overview-item-actions-secondary {
      margin: 0;
    }
    .overview-item-actions-primary:last-child {
      > .overview-item-action:last-child {
        border-top-right-radius: $border-radius-default;
        border-bottom-right-radius: $border-radius-default;
      }
    }
    .overview-item-actions-secondary {
      margin-left: $border-radius-default;
    }
    .action-danger,
    .action-success {
      padding: 0 $spacing-default * 2; // additional spacing for background-color
      color: white;
    }
    .action-danger {
      background-color: $bg-danger;
    }
    .action-success {
      background-color: $bg-success;
    }
  }
  &-action {
    display: flex;
    align-items: center;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 $spacing-default;
    border: 0;

    &.disabled {
      opacity: 0.4;
    }

    &-label {
      display: none;
    }

    //// overview-item breakpoint for actions
    .dropdown-item &-label {
      display: block;
      margin: 0 (-1 * $spacing-md);
    }
    .dropdown-item & i {
      display: none;
    }
  }

  &-with-icon {
    border-top-left-radius: $item-icon-border-radius;
    border-bottom-left-radius: $item-icon-border-radius;
  }

  &-icon {
    margin-left: 0;
    border-radius: $item-icon-border-radius;
    height: $item-height;
    width: $item-height;
    background: url(../../img/icons/profile-picture-placeholder.svg);
    background-size: cover;
  }

  &-date {
    position: absolute; // allows for centering with label & banners
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding: 0.25rem 1rem 0.5rem;
    justify-content: center;
    border-top-left-radius: $border-radius-default;
    border-bottom-left-radius: $border-radius-default;
    background-color: $bg-dark;
    color: white;

    &-day-of-month {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.3;
    }

    &-month {
      font-size: 10px;
      line-height: 1.3;
      text-transform: uppercase;
    }
    &-year,
    &-time {
      left: 0;
      font-size: 9px;
      line-height: 1;
      font-weight: 700;
      text-transform: uppercase;
      opacity: 0.7;
    }
    &-time {
      opacity: 1;
    }
  }

  &-label {
    margin: 0;
    padding: 0.25rem 0.5rem;
    position: absolute;
    height: auto;
    left: 50%;
    transform: translate(-50%, 0);
    top: $spacing-default * -1;
    z-index: 1;
    border-radius: $border-radius-default;
    background-color: $bg-dark;
    color: white;
    text-transform: uppercase;
    text-align: center;

    .demo & {
      background-color: hsla(204, 88%, 60%, 0.95);
    }
  }

  &-has-banners {
    margin-bottom: $item-height + $spacing-default;

    .overview-item-banners {
      display: flex;
    }
    .overview-item-actions-primary {
      display: none;
    }
  }

  &-banners {
    display: none;
    position: absolute;
    left: 0;
    top: $item-height - 1px; // subtract 1 to prevent magic gap
    width: 100%;
    height: $item-banners-height;
    margin: 0;
    color: $font-color-contrasting;
  }

  &-banner {
    height: 100%;
    background-color: $colors-branding-primary;
    border-bottom-left-radius: $border-radius-default;
    border-bottom-right-radius: $border-radius-default;
    margin: 0 $spacing-default;
  }
}
//
//.dropdown-menu {
//  border-color: #828282;
//
//  .dropdown-divider {
//    border-color: #b3b3b3;
//  }
//}

.dropdown-menu {
  border-color: #828282;

  .dropdown-divider {
    border-color: #b3b3b3;
  }

  .dropdown-item {
    margin-top: -8px;
    margin-bottom: -8px;
    padding: 0.8rem 1.5rem;
    text-align: left;
    font-weight: 300;
    font-size: 16px;
    font-family: 'Lato', Helvetica, sans-serif;

    &:first-child {
      border-top-right-radius: $border-radius-default;
      border-top-left-radius: $border-radius-default;
    }

    &:last-child {
      border-bottom-right-radius: $border-radius-default;
      border-bottom-left-radius: $border-radius-default;
    }

    background-color: #f2f2f2;
    &:hover {
      background-color: #d8d8d8;
    }
    @include media-breakpoint-down(md) {
      margin-top: -7px;
      margin-bottom: -7px;
    }
  }
}

.btn-block {
  .btn-group {
    .dropdown-toggle {
      line-height: 20px;
      &::after {
        display: none;
      }
    }
  }
}

.overview-item-actions-secondary .locked-content-tip .lock-tooltip {
  white-space: normal; //override whitespace of parent.
}
