@import '~bootstrap/scss/mixins';
@import '../../../lib/styles/variables';

.team-tags {
  display: flex;
  flex-wrap: wrap;
}
.team-tag {
  width: 48%;
  margin-bottom: 1rem;
  display: flex;
  align-content: center;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &:nth-child(odd) {
    margin-right: 2%;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }
  }
  &:nth-child(even) {
    margin-left: 2%;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
  }

  > .btn-group > .btn {
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    background-color: $gray-100;
  }
}
