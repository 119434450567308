@import '~bootstrap/scss/mixins/breakpoints';
@import './bootstrap_variables.scss';
@import './variables.scss';

// TODO: remove, this is deprecated
.button {
  display: inline-flex;
  align-items: center;

  & + & {
    margin-left: $spacing-sm;
  }
}

// TODO: remove, this is deprecated
.button-link {
  // TODO: refactor buttons & remove !important here
  background-color: transparent !important;
  color: $font-color-default !important;
}

.button-hotkey {
  margin: 0 0 0 10px;
  font-weight: bold;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.btn {
  display: inline-flex;
  align-items: center;

  &-primary,
  &-default {
    border-color: transparent !important;

    &:active,
    &:focus,
    &:hover {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }

  &-secondary,
  &-secondary:hover,
  &-secondary:not(:disabled):not(.disabled):active,
  {
    border-color: $colors-branding-secondary !important;
    color: $colors-branding-secondary;
    background-color: transparent !important;
  }


  &-link {
    color: $font-color-default;

    &.active {
      font-weight: bold;
    }
  }

  &-block {
    justify-content: center;
  }

  &-cta {
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 0 0 $spacing-md 0;
      justify-content: center;
    }
  }
  &-large {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
  }
}

.btn-primary {
  color: $white;
}

.btn-default {
  @include button-variant(
    $gray-300,
    darken($gray-300, 7.5%),
    darken($gray-300, 10%),
    lighten($gray-300, 5%),
    lighten($gray-300, 10%),
    darken($gray-300, 30%)
  );
}

.btn-group-select {
  z-index: 0;

  label + & {
    margin-left: $spacing-md;
  }

  &.form-control {
    padding: 0;
    border: none;
    background-color: transparent;

    .btn {
      background-color: white;
    }
  }

  .btn {
    border-color: $gray-800;
    font-weight: normal;
    background-color: transparent;

    &.active {
      background-color: $gray-800;
      color: $font-color-contrasting;
    }
  }
}
