.area {
  fill: #ff8d02;
  opacity: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.2;
    transition: 0s;
  }

  &:active {
    opacity: 1;
  }
}

.areaSelected {
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
}

