@import '~bootstrap/scss/mixins';
@import './variables';

$aside-width: var(--aside-width, 450px);
$aside-indent: var(--aside-indent, -450px);

$aside-transition-duration: var(--aside-transition-duration, 0.5s);

#aside {
  position: fixed;
  height: 100%;
  width: $aside-width;
  max-width: 100vw;
  left: 100%;
  top: 0;

  background-color: $bg-light;
  border-left: solid 1px #d9d9d9;
  transform: translateX(0);
  z-index: 999;
  .aside__inner {
    overflow-y: auto;
    height: 100%;
    padding: 2rem;
  }
}
#aside.opened {
  display: block;
  transform: translateX($aside-indent);
  box-shadow: $overview-item-shadow;
}
#aside,
#aside.opened .aside__backdrop {
  transition: transform $aside-transition-duration ease;
}
.prevent-scroll,
.prevent-scroll #app {
  overflow: hidden;
  height: 100%;
}

#aside.opened .aside__backdrop {
  position: fixed;
  right: 0;
  transform: translateX($aside-indent);
  background-color: black;
  opacity: 0.25;
  top: 0;
  z-index: 99;
  width: 100vw;
  height: 100%;
  cursor: pointer;
}

@include media-breakpoint-down(md) {
  #aside {
    width: 90vw;
  }
  #aside.opened {
    transform: translateX(-90vw);
  }
  #aside.opened .aside__backdrop {
    right: 0;
    transform: translateX(-90vw);
  }
}

#aside .modal-close {
  top: 20px;
  right: 20px;
}
