
/* make keyframes that tell the start state and the end state of our object */
@keyframes fadeToOpacity0 { from { opacity:0.2; } to { opacity:0; } }
@keyframes fadeToOpacity20 { from { opacity:0.4; } to { opacity:0.2; } }
@keyframes fadeToOpacity40 { from { opacity:0.6; } to { opacity:0.4; } }
@keyframes fadeToOpacity60 { from { opacity:0.8; } to { opacity:0.6; } }
@keyframes fadeToOpacity80 { from { opacity:1; } to { opacity:0.8; } }

.fadeToOpacity {
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-fill-mode:forwards;
  animation-duration:0.1s;
}

.fadeToOpacity0 {
  animation:fadeToOpacity0 ease-out 1;
  animation-fill-mode:forwards;
  animation-duration:1s;
}

.fadeToOpacity20 {
  animation:fadeToOpacity20 ease-out 1;
  animation-fill-mode:forwards;
  animation-duration:1s;
}


.fadeToOpacity40 {
  animation:fadeToOpacity40 ease-out 1;
  animation-fill-mode:forwards;
  animation-duration:1s;
}


.fadeToOpacity60 {
  animation:fadeToOpacity60 ease-out 1;
  animation-fill-mode:forwards;
  animation-duration:1s;
}


.fadeToOpacity80 {
  animation:fadeToOpacity80 ease-out 1;
  animation-fill-mode:forwards;
  animation-duration:1s;
}
