@import '../../../lib/styles/variables';

.page--playlist-view {
  background-color: #f5f5f5;
}
.playlist-view {
  display: flex;
  flex-wrap: wrap;
  padding-top: 12px;
  justify-content: flex-start;
  margin: 0 -6px;
}
.playlist-view__card {
  flex-basis: percentage(1/6);

  //flex-grow: 0;
  padding: 12px 12px 12px 12px;
  position: relative;
  @media screen and (max-width: 1660px) {
    flex-basis: 20%;
  }
  @media screen and (max-width: 1440px) {
    flex-basis: 25%;
  }
  @media screen and (max-width: 1200px) {
    flex-basis: 33.333%;
  }
  @media screen and (max-width: 960px) {
    flex-basis: 50%;
  }
  @media screen and (max-width: 560px) {
    flex-basis: 100%;
  }

  cursor: pointer;
  label {
    cursor: pointer;
  }
}

.playlistitem-card {
  /* TODO: still used? */
  border-radius: 7px;
  background-color: white;
  color: $font-color-default;
  //overflow: hidden;
  padding-bottom: 0;
  //margin-bottom: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #dadada;

  cursor: pointer;
}

.playlistitem-card__image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius $border-radius 0 0;
  .loading-overlay {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
  }
}

.playlistitem-card__bottom {
  padding: 12px 8px;
  display: flex;
  position: relative;
  flex-grow: 1;
}

.card__user {
  width: 31px;
  padding-right: 6px;
  padding-top: 4px;
  flex-shrink: 0;
  img {
    width: auto;
    max-width: 100%;
    border-radius: 100px;
  }
}
.card__info {
  display: flex;
  flex-direction: column;
  label {
    font-size: 16px;
    @media screen and (max-width: 600px) {
      font-size: 13px;
    }
    line-height: 1.1;
    font-weight: 600;
    margin: 0;
  }
}
.card__name {
  color: $font-color-default;
  opacity: 0.8;
  display: block;
  font-size: 12px;
  line-height: 1;
  margin-top: 4px;
}

.card__meta {
  display: flex;
  opacity: 0.7;
  font-size: 12px;
  padding: 8px 0 0 0;
  margin-top: auto;
  > span {
    margin-right: 11px;
    i {
      margin-right: 4px;
    }
  }
}

.playlistitem-card__actions-secondary {
  position: absolute;
  right: 0;
  bottom: 3px;
  width: 100%;

  display: flex;
  justify-content: flex-end;

  //margin-bottom: $spacing-lg;
  .btn {
    pointer-events: auto;
    padding-right: $spacing-lg;
  }
  .btn:hover,
  .btn:focus {
    opacity: 0.9; // btn hover state
  }
  .btn > i {
    margin: 0;
  }
  .dropdown-menu {
    z-index: 2;
    pointer-events: all;
  }
}

.view--empty {
  padding: 6rem 0;
  margin: auto;
}

.empty-feedback {
  font-size: 18px;

  strong {
    color: $gray-600;
  }
  .search-string {
    font-size: 20px;
    font-style: italic;
  }
}

.playlistitem-card__placeholder {
  padding-bottom: 56.6%;
  background-image: linear-gradient(
    225deg,
    hsl(157deg, 81%, 53%) 0%,
    hsl(179deg, 69%, 52%) 41%,
    hsl(201deg, 57%, 50%) 66%,
    hsl(223deg, 46%, 49%) 84%,
    hsl(244deg, 34%, 48%) 100%
  );
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
  }

  .skillreflect & {
    background-image: linear-gradient(
      225deg,
      hsl(36deg, 100%, 50%) 0%,
      hsl(28deg, 98%, 53%) 41%,
      hsl(20deg, 95%, 55%) 66%,
      hsl(12deg, 93%, 57%) 84%,
      hsl(4deg, 90%, 59%) 100%
    );
  }
  filter: grayscale(50%);
}

.tutorials .playlistitem-card {
  & + .playlistitem-card {
    margin-top: 30px;
  }
}
.acl_lock {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;
  display: flex;
  width: 30px;
  height: 30px;
  background-color: #5f5f5f;
  border-radius: 100px;
  align-items: center;
  align-content: center;
  justify-content: center;
}
