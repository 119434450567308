@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap');
@import './variables';

html,
body {
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

h5 {
  color: $font-color-default;
}

p {
  font-size: 1rem;
  font-weight: 400;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ol {
  margin: $spacing-md 0;
}

$headings: 'h1,h2,h3,h4,h5,h6';

#{$headings} {
  line-height: 1.2;
  margin-bottom: 0.5em;
}

h1 {
  @include media-breakpoint-down(sm) {
    font-size: 22px;
  }
}

h2 {
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

span.primary {
  color: $colors-branding-primary;
}
span.secondary {
  color: $colors-branding-secondary;
}

// Editor styling

// Responsive embeds
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 1em;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
